import React, { useEffect, useState } from "react";
import "./Odontogram.css";
import Teeth from "./Teeth";
import { addOdontograma } from "../../../../../firebase/firebase.odontograma";
import { useSelector } from "react-redux";
import Spinner from "../../../../UI/Spinner";
import BetaSegment from "../../../../UI/BetaSegment";

function Odontogram({ data }) {
  const [odontogramData, setOdontogramData] = useState(null);
  const [actions, setActions] = useState(null);
  const [subActions, setSubActions] = useState(null);
  const [newService, setNewService] = useState(null);
  const [selectedSubAction, setSelectedSubAction] = useState(null);
  const [toothNumber, setToothNumber] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const userDB = useSelector((state) => state.user.details);

  let odontogramState = {};

  const handleToothUpdate = (id, toothState) => {
    odontogramState[id] = toothState;
  };

  useEffect(() => {
    if (data) {
      setOdontogramData(data.odontograma);
    }
  }, [data]);

  const actionHandler = (e) => {
    setSubActions(null);
    setActions(e);
  };

  const handleClick = (func) => {
    setSubActions(func);
  };

  const toothNumberHandler = (e) => {
    setToothNumber(e);
  };

  const saveOdontogram = async () => {
    setIsLoading(true);
    await addOdontograma(data.id, odontogramState);
    setIsLoading(false);
    setActions(null);
    setSubActions(null);
    setToothNumber(null);
  };

  const handleSubmitSub = (value, key, text) => {
    setSelectedSubAction(key);
    if (text) setNewService(text);
    value();
  };

  return (
    <>
      {/* <main
        className="flex flex-wrap bg-slate-800"
        style={{ justifyContent: "center" }}
      >
        <div className="mx-auto max-w-7xl py-6 sm:px-6 md:px-6  mx-2">
          <div className="w-full mt-3 bg-white rounded-lg ">
            <div className="flex"> */}
      <div className="flex justify-start">
        <BetaSegment />
      </div>
      <div
        className={`flex flex-row-reverse ${
          toothNumber ? "justify-around" : "justify-center"
        } rounded-lg p-5 h-50vh`}
      >
        {toothNumber && (
          <div className="w-4/12">
            <div>
              <div>
                <div className="flex justify-start">
                  <div className="flex flex-wrap">
                    <div className="flex py-2 px-4 overflow-scroll">
                      {toothNumber && (
                        <div className="flex items-center">
                          <h2 className="bg-gray-800 hover:bg-gray-400 text-white font-semibold mx-0.5 py-1 px-2 rounded cursor-pointer transition duration-300 inline-flex items-left">
                            {toothNumber && toothNumber}
                          </h2>
                        </div>
                      )}
                      {actions &&
                        Object.entries(actions).map(([key, value]) => (
                          <div
                            key={key}
                            onClick={() => handleClick(value)}
                            className="bg-slate-600 hover:bg-slate-800 text-white font-semibold mx-0.5 py-1 px-2 rounded cursor-pointer transition duration-300 inline-flex items-left"
                          >
                            {key}
                          </div>
                        ))}
                    </div>
                    {/* {subActions !== null && (
                      <div className="flex items-center">
                        <div className="flex max-w-full px-3 text-right">
                          <div
                            className="px-3 flex py-1.5 font-bold leading-normal text-center text-white align-middle bg-transparent rounded-lg cursor-pointer text-sm ease-in shadow-md bg-150 bg-gradient-to-tl from-zinc-800 to-zinc-700 bg-gradient-to-tl from-slate-600 to-slate-600 hover:shadow-xs active:opacity-85 tracking-tight-rem bg-x-25"
                            onClick={saveOdontogram}
                          >
                            <div className="fas fa-plus">Guardar</div>
                            {isLoading && <Spinner size="small" />}
                          </div>
                        </div>
                      </div>
                    )} */}
                  </div>
                </div>
              </div>
              <div className="flex justify-between">
                <div className="flex flex-col w-30vw h-20vh">
                  <div className="flex flex-wrap py-2 px-4 overflow-scroll">
                    {subActions &&
                      Object.entries(subActions).map(([key, value], i) => (
                        <div className="flex flex-col">
                          <div
                            key={key}
                            onClick={() => handleSubmitSub(value, key)}
                            className="h-fit m-1 min-w-max bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold mx-0.5 py-1 px-2 rounded cursor-pointer transition duration-300 inline-flex items-left"
                          >
                            {key}
                          </div>

                          {selectedSubAction === "Nuevo Servicio" &&
                            key === "Nuevo Servicio" && (
                              <div className="flex items-cente mt-3">
                                <div className="flex items-center">
                                  <input
                                    required
                                    type="text"
                                    name="NombreDeLaPraxis"
                                    id="name"
                                    autoComplete="name"
                                    onChange={(e) =>
                                      handleSubmitSub(
                                        value,
                                        key,
                                        e.target.value
                                      )
                                    }
                                    value={newService}
                                    className="block w-9/12 w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                                {subActions !== null && (
                                  <div className="flex items-center">
                                    <div className="flex max-w-full px-3 text-right">
                                      <div
                                        className="px-3 flex py-1.5 font-bold leading-normal text-center text-white align-middle bg-transparent rounded-lg cursor-pointer text-sm ease-in shadow-md bg-150 bg-gradient-to-tl from-zinc-800 to-zinc-700 bg-gradient-to-tl from-slate-600 to-slate-600 hover:shadow-xs active:opacity-85 tracking-tight-rem bg-x-25"
                                        onClick={saveOdontogram}
                                      >
                                        <div className="fas fa-plus">
                                          Guardar
                                        </div>
                                        {isLoading && <Spinner size="small" />}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <svg
          version="1.1"
          className="sm:w-45vw md:w-45vw lg:w-[55vw] sm:h-44vh h-45vh w-full"
        >
          <Teeth
            start={18}
            end={11}
            x={0}
            y={0}
            handleChange={handleToothUpdate}
            data={odontogramData}
            actionHandler={actionHandler}
            toothNumberHandler={toothNumberHandler}
            newService={{
              setter: setNewService,
              state: newService,
              selectedSubActionSetter: setSelectedSubAction,
            }}
          />
          <Teeth
            start={21}
            end={28}
            x={210}
            y={0}
            handleChange={handleToothUpdate}
            data={odontogramData}
            actionHandler={actionHandler}
            toothNumberHandler={toothNumberHandler}
            newService={{
              setter: setNewService,
              state: newService,
              selectedSubActionSetter: setSelectedSubAction,
            }}
          />

          <Teeth
            start={55}
            end={51}
            x={75}
            y={45}
            handleChange={handleToothUpdate}
            data={odontogramData}
            actionHandler={actionHandler}
            toothNumberHandler={toothNumberHandler}
            newService={{
              setter: setNewService,
              state: newService,
              selectedSubActionSetter: setSelectedSubAction,
            }}
          />
          <Teeth
            start={61}
            end={65}
            x={210}
            y={45}
            handleChange={handleToothUpdate}
            data={odontogramData}
            actionHandler={actionHandler}
            toothNumberHandler={toothNumberHandler}
            newService={{
              setter: setNewService,
              state: newService,
              selectedSubActionSetter: setSelectedSubAction,
            }}
          />

          <Teeth
            start={85}
            end={81}
            x={75}
            y={95}
            handleChange={handleToothUpdate}
            data={odontogramData}
            actionHandler={actionHandler}
            toothNumberHandler={toothNumberHandler}
            newService={{
              setter: setNewService,
              state: newService,
              selectedSubActionSetter: setSelectedSubAction,
            }}
          />
          <Teeth
            start={71}
            end={75}
            x={210}
            y={95}
            handleChange={handleToothUpdate}
            data={odontogramData}
            actionHandler={actionHandler}
            toothNumberHandler={toothNumberHandler}
            newService={{
              setter: setNewService,
              state: newService,
              selectedSubActionSetter: setSelectedSubAction,
            }}
          />

          <Teeth
            start={48}
            end={41}
            x={0}
            y={145}
            handleChange={handleToothUpdate}
            data={odontogramData}
            actionHandler={actionHandler}
            toothNumberHandler={toothNumberHandler}
            newService={{
              setter: setNewService,
              state: newService,
              selectedSubActionSetter: setSelectedSubAction,
            }}
          />
          <Teeth
            start={31}
            end={38}
            x={210}
            y={145}
            handleChange={handleToothUpdate}
            data={odontogramData}
            actionHandler={actionHandler}
            toothNumberHandler={toothNumberHandler}
            newService={{
              setter: setNewService,
              state: newService,
              selectedSubActionSetter: setSelectedSubAction,
            }}
          />
        </svg>
      </div>
    </>
  );
}

export default Odontogram;
