import React, { useState, useEffect } from "react";
import Navbar from "../navbar/Navbar";
import Dashboard from "../Dashboard/Dashboard";
import { useNavigate } from "react-router";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../../firebase/firebase.admin";
import List from "./List";
import Spinner from "../UI/Spinner";
import { setAllClients } from "../../store/clients/clients.reducer";

const Admin = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedView, setSelectedView] = useState("dashboard");
  const [userId, setUserId] = useState(null);
  let navigate = useNavigate();
  const user = useSelector((state) => state.user?.userDetails);
  const dispatch = useDispatch();

  useEffect(() => {
    let getUsers = async () => {
      if (userId) {
        let data = await getAllUsers(userId);
        if (data !== undefined) {
          dispatch(setAllClients(data));
          setData(data);
          setFilteredData(data);
          setIsLoading(false);
        }
      }
    };
    getUsers();
  }, [userId]);

  useEffect(() => {
    if (user !== null) {
      if (user.role == "admin") {
        setUserId(user.id);
      } else {
        toast.error("No tiene suficientes permisos");
        navigate("/dashboard");
      }
    }
  }, [user]);

  const dataFilter = (e) => {
    if (e) {
      setFilteredData(e);
    }
  };

  return (
    <div>
      <Navbar>
        {!isLoading ? (
          <List userData={filteredData} type="admin" />
        ) : (
          <div
            className="flex items-center justify-center"
            style={{ height: "50vh" }}
          >
            <Spinner />
          </div>
        )}
      </Navbar>
    </div>
  );
};

export default Admin;
