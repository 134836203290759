import React, { useEffect, useState } from "react";
import Navbar from "../navbar/Navbar";
import { useSelector } from "react-redux";
import FinancesTable from "./FinancesTable";
import { getAllPaymentsByPraxisId } from "../../firebase/firebase.payment";
import PaymentModal from "../UI/PaymentModal";
import FeatureFlag from "../featureFlag/FeatureFlag";

const Finances = () => {
  const [allPayments, setAllPayments] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [praxisId, setPraxisId] = useState(null);
  const localPraxis = useSelector((state) => state.user?.userDetails);
  const allPatients = useSelector((state) => state.patients.allPatients);
  const [editModal, setEditModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(undefined);
  const [defaultAllPayments, setDefaultAllPayments] = useState(false);

  useEffect(() => {
    if (praxisId && !defaultAllPayments) {
      const allPayments = async () => {
        const payments = await getAllPaymentsByPraxisId(praxisId);
        setAllPayments(payments);
        setDefaultAllPayments(payments);
        setSelectedCategory("all");
        setSelectedDate(undefined);
      };
      allPayments();
    }
  }, [praxisId, defaultAllPayments]);

  useEffect(() => {
    if (localPraxis && praxisId == null) setPraxisId(localPraxis.praxisId);
  }, [localPraxis]);

  useEffect(() => {
    if (defaultAllPayments && selectedCategory) {
      switch (selectedCategory) {
        case "pending": {
          if (selectedDate) {
            var activePayments = defaultAllPayments.filter(
              (payment) =>
                payment.hasBeenPaid === false &&
                new Date(payment?.date)?.getMonth() ===
                  new Date(selectedDate)?.getMonth()
            );
            return setAllPayments(activePayments);
          } else {
            var activePayments = defaultAllPayments.filter(
              (payment) => payment.hasBeenPaid === false
            );
            return setAllPayments(activePayments);
          }
        }

        case "all": {
          if (selectedDate) {
            var allInRange = defaultAllPayments.filter(
              (payment) =>
                new Date(payment?.date)?.getMonth() ===
                new Date(selectedDate)?.getMonth()
            );
            allInRange = allInRange.sort((a, b) => {
              if (a.date < b.date) {
                return 1;
              } else if (a.date > b.date) {
                return -1;
              } else {
                return 0;
              }
            });
            debugger;
            return setAllPayments(allInRange);
          } else {
            return setAllPayments(defaultAllPayments);
          }
        }

        case "paid": {
          if (selectedDate) {
            var activePayments = defaultAllPayments.filter(
              (payment) =>
                payment.hasBeenPaid === true &&
                new Date(payment?.date)?.getMonth() ===
                  new Date(selectedDate)?.getMonth()
            );
            return setAllPayments(activePayments);
          } else {
            var activePayments = defaultAllPayments.filter(
              (payment) => payment.hasBeenPaid === true
            );
            return setAllPayments(activePayments);
          }
        }

        case "month": {
          debugger;
          var activePayments = defaultAllPayments.filter(
            (payment) =>
              new Date(payment?.date)?.getMonth() ===
              new Date(selectedDate)?.getMonth()
          );
          return setAllPayments(activePayments);
        }
      }
    }
  }, [selectedCategory, selectedDate]);

  return (
    <div>
      <Navbar>
        <FeatureFlag feature={"premium"}>
          <div className="flex">
            <FinancesTable
              userData={allPayments}
              setOpenModal={setOpenModal}
              setSelectedCategory={setSelectedCategory}
              selectedCategory={selectedCategory}
              editModalSetter={setEditModal}
              allPaymentsTrigger={setDefaultAllPayments}
              setSelectedDate={setSelectedDate}
              selectedDate={selectedDate}
              useCase={"finances"}
            />
          </div>
          {/* {openModal && (
          <PaymentModal
            data={data}
            modalState={openModal}
            modalSetter={setOpenModal}
            allPaymentsSetter={setAllPayments}
            allPayments={allPayments}
            useCase={"add"}
          />
        )} */}

          {editModal && (
            <PaymentModal
              data={editModal}
              defaultValue={defaultAllPayments[0]}
              modalState={openModal}
              modalSetter={setEditModal}
              allPaymentsSetter={setDefaultAllPayments}
              allPayments={defaultAllPayments}
              useCase={"edit"}
              editModal={editModal}
            />
          )}
        </FeatureFlag>
      </Navbar>
    </div>
  );
};

export default Finances;
