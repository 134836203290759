import React, { useEffect, useState } from "react";
import { timeFormatter } from "../../utils/dateFormatter";
import { PaperAirplaneIcon, BoltIcon } from "@heroicons/react/24/outline";
import Pill from "../UI/Pill";
import WhatsAppModal from "../UI/WhatsAppModal";
import { useSelector } from "react-redux";
import { getDaysTillAppointment } from "../../utils/calculateAge";
import { updateWASent } from "../../firebase/firebase.praxis";

const ReminderList = ({
  today,
  tomorrow,
  praxisId,
  setToday,
  setTomorrow,
  setForceUpdate,
}) => {
  const [isToday, setIsToday] = useState(true);
  const [todays, setTodays] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const praxis = useSelector((state) => state.praxis?.praxisDetails);

  const sendReminder = (e, day) => {
    debugger;
    let praxisNameStr = praxis.praxisName.replaceAll(" ", "%20") + "%20";
    let praxisAddressStr = praxis.address.replaceAll(" ", "%20") + "%20";
    let newAppointment = new Date(e.start);
    let newAppointmentEnd = new Date(e.end);
    let apointmentTime = newAppointment.toLocaleTimeString()?.slice(0, 5);
    let apointmentEndTime = newAppointmentEnd.toLocaleTimeString()?.slice(0, 5);
    let appointmentDate = getDaysTillAppointment(newAppointment);
    let url = `https://wa.me/${e.patientTel}?text=${praxis?.WAMessage?.message}`;
    url = url.replaceAll("{nombre}", e.patientName);
    url = url.replaceAll("{consultorio}", praxis.praxisName);
    url = url.replaceAll("{comienza}", apointmentTime);
    url = url.replaceAll("{termina}", apointmentEndTime);
    url = url.replaceAll("{direccion}", praxisAddressStr);
    updateWASent(e.event_id);
    if (day == "today") {
      let todayApts = JSON.parse(JSON.stringify(today));
      todayApts.find((t) => t.event_id == e.event_id).contacted = true;
      setToday(todayApts);
    } else if (day == "tomorrow") {
      let tomorrowsApt = JSON.parse(JSON.stringify(tomorrow));
      tomorrowsApt.find((t) => t.event_id == e.event_id).contacted = true;
      setTomorrow(tomorrowsApt);
    }
    window.open(url, "_blank");
  };

  useEffect(() => {
    let sortByDate = today.sort((a, b) => {
      return new Date(a.start) - new Date(b.start);
    });
    setTodays(sortByDate);
  }, [today]);

  return (
    <>
      <div className="border-0 rounded-lg shadow-lg outline-none focus:outline-none px-2 mt-5 bg-slate-700">
        {
          <>
            <div className="flex flex-col w-full justify-center p-2 m-2">
              <div className="flex justify-between items-center mx-1">
                <div className="font-bold text-white text-xl">
                  Tu dia de hoy
                </div>
                <BoltIcon
                  className={`h-4 w-4 text-white`}
                  onClick={() => setOpenModal(!openModal)}
                />
              </div>
              <div className="flex">
                <div className="m-1 " onClick={() => setIsToday(true)}>
                  <div className="m-1 text-white">Hoy</div>
                </div>
                <div className="m-1" onClick={() => setIsToday(false)}>
                  <div className="m-1 text-white">Mañana</div>
                </div>
              </div>

              <div>
                <div className="overflow-scroll w-4/4 h-[50vh]">
                  {isToday
                    ? todays.map((e) => {
                        console.log(
                          e?.start.seconds == undefined
                            ? timeFormatter(e?.start)
                            : timeFormatter(new Date(e?.start.toDate()))
                        );
                        return (
                          <div>
                            <div className="justify-between h-10 flex items-center p-2 m-1 border-b border-solid rounded-lg bg-white">
                              <div className="text-xs w-2/12">
                                {e?.start.seconds == undefined
                                  ? timeFormatter(e?.start)
                                  : timeFormatter(new Date(e?.start.toDate()))}
                              </div>
                              <div className="text-xs w-5/12 text-ellipsis overflow-hidden">
                                {e?.patientName}
                              </div>
                              <div className="text-x flex justify-end w-5/12">
                                {!e.contacted ? (
                                  <Pill text="Sin avisar" color="red" />
                                ) : (
                                  <Pill text="Avisado" color="green" />
                                )}
                              </div>
                              <div
                                className="w-1/12"
                                onClick={() => sendReminder(e, "today")}
                              >
                                <PaperAirplaneIcon
                                  className={`h-4 w-4`}
                                  aria-hidden="true"
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })
                    : tomorrow.map((e, i) => {
                        return (
                          <div key={i}>
                            <div className="justify-between flex items-center p-2 m-1 border-b border-solid rounded-lg bg-white">
                              <div className="text-xs w-2/12">
                                {e?.start.seconds == undefined
                                  ? timeFormatter(e?.start)
                                  : timeFormatter(new Date(e?.start.toDate()))}
                              </div>
                              <div className="text-xs w-5/12 text-ellipsis overflow-hidden">
                                {e?.patientName}
                              </div>
                              <div className="text-x flex justify-end w-5/12">
                                {!e.contacted ? (
                                  <Pill text="Sin avisar" color="red" />
                                ) : (
                                  <Pill text="Avisado" color="green" />
                                )}
                              </div>
                              <div
                                className="w-1/12"
                                onClick={() => sendReminder(e, "tomorrow")}
                              >
                                <PaperAirplaneIcon
                                  className={`h-4 w-4`}
                                  aria-hidden="true"
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                </div>
              </div>
            </div>
          </>
        }
      </div>
      {openModal && (
        <WhatsAppModal
          praxisId={praxisId}
          defaultMessage={praxis?.WAMessage?.message}
          modalToggler={setOpenModal}
        />
      )}
    </>
  );
};

export default ReminderList;
