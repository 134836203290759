import React, { useReducer } from "react";
import { ClientViewContext } from "./clientView.context";

const initialState = {
  clientView: "Ficha",
  clientData: [],
  odontogramState: {
    Cavities: {
      center: 0,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    Extract: 0,
    Crown: 0,
    Filter: 0,
    Fracture: 0,
  },
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_ClientData":
      return {
        ...state,
        clientData: action.data,
      };
    case "SET_ClientView_Ficha":
      return {
        ...state,
        clientView: "Ficha",
      };
    case "SET_ClientView_Legal":
      return {
        ...state,
        clientView: "Legal",
      };

    case "SET_ImgDashboard":
      return {
        ...state,
        clientView: "Img",
      };
    case "SET_ClientView_Pagos":
      return {
        ...state,
        clientView: "Pagos",
      };
    case "SET_ClientView_History":
      return {
        ...state,
        clientView: "Historial",
      };
    case "SET_ClientView_Odontograma":
      return {
        ...state,
        clientView: "Odontograma",
      };

    // Odontogram
    case "SET_ODONTOGRAM":
      return {
        ...state,
        odontogramState: action.state,
      };
    default:
      return state;
  }
}
export const ClientViewProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <ClientViewContext.Provider value={{ state, dispatch }}>
      {children}
    </ClientViewContext.Provider>
  );
};
