import React, { useState } from "react";
import ReminderButton from "../UI/ReminderButton";
import { useNavigate } from "react-router";

const Usercard = ({ name, tel, email, role, id, user }) => {
  const [isOpen, setIsOpen] = useState(false);
  let navigate = useNavigate();
  return (
    <>
      {!isOpen ? (
        <div
          className="p-6 bg-white border border-gray-200 rounded-lg shadow z-20 border border-gray-200 rounded-lg shadow"
          onClick={() => navigate(`${id}`)}
          key={id}
        >
          <div>
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 truncate">
              {name + " " + user.lastname}
            </h5>
          </div>
          <p className="mb-3 font-normal text-gray-700">Tel: {tel}</p>
          <p className="mb-3 font-normal text-gray-700">Role: {role}</p>
          <p className="mb-3 font-normal text-gray-700">
            Pagado: {user?.praxis?.payment?.paid ? "Si" : "No"}
          </p>
          <p className="mb-3 font-normal text-gray-700">
            Licencia: {user?.praxis?.payment?.licenseType}
          </p>
          {/* <ReminderButton tel={tel} name={name} type="recordatorio" /> */}
        </div>
      ) : (
        <div className="flex w-90vw">
          <div
            className="p-6 bg-white border border-gray-200 rounded-lg shadow w-90vw h-60vh flex z-40"
            onClick={() => setIsOpen(false)}
          >
            <div>
              <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
                {name + " " + user.lastname}
              </h5>
            </div>
            <p className="mb-3 font-normal text-gray-700">Tel: {tel}</p>
            <p className="mb-3 font-normal text-gray-700">Role: {role}</p>
            <p className="mb-3 font-normal text-gray-700">
              Pagado: {user?.praxis?.payment?.paid ? "Si" : "No"}
            </p>
            {user?.praxis?.payment?.paid && (
              <p className="mb-3 font-normal text-gray-700">
                Inicio de Licensia: {user?.praxis?.payment?.membershipStart}
              </p>
            )}
            {/* <ReminderButton tel={tel} name={name} type="recordatorio" /> */}
          </div>
        </div>
      )}
    </>
  );
};

export default Usercard;
