/* This example requires Tailwind CSS v3.0+ */
import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import logo from "../../resources/logo-webstark.png";
import doctors from "../../resources/1043.jpg";
import agenda from "../../resources/agenda3.svg";
import allInOne from "../../resources/all-in-one2.svg";
import finances from "../../resources/finances2.svg";

export default function Landing() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div className="isolate bg-gray h-100vh">
      <div className="px-6 pt-6 lg:px-8">
        <div>
          <nav
            className="flex h-9 items-center justify-between"
            aria-label="Global"
          >
            <div className="flex lg:min-w-0 lg:flex-1" aria-label="Global">
              <div className="-m-1.5 p-1.5 flex">
                <svg
                  version="1.2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 826 175"
                  className="h-[7vh]"
                >
                  <style>
                    {`.s0 { fill: #253141 }
    .s1 { fill: #0ea5e9 }
    .s2 { fill: none;stroke: #0ea5e9;strokeLinecap: round;strokeLinejoin: round;strokeWidth: 1.9 } `}
                  </style>
                  <g id="Layer">
                    <g id="Layer">
                      <g id="Layer">
                        <g id="Layer">
                          <path
                            id="Layer"
                            className="s0"
                            d="m535 108.1l5.9-7c5.3 4.6 10.7 7.2 17.5 7.2 6 0 9.9-2.8 9.9-6.9v-0.2c0-4-2.3-6.1-12.6-8.5-11.8-2.9-18.5-6.4-18.5-16.6v-0.2c0-9.5 7.9-16.1 19-16.1 8.1 0 14.5 2.5 20.2 7l-5.3 7.4c-5-3.7-10-5.7-15.1-5.7-5.7 0-9 2.9-9 6.6v0.1c0 4.3 2.5 6.2 13.2 8.8 11.8 2.8 17.8 7 17.8 16.2v0.2c0 10.4-8.2 16.6-19.8 16.6-8.5 0-16.6-2.9-23.2-8.9z"
                          />
                          <path
                            id="Layer"
                            className="s0"
                            d="m602 69.7h-17.6v-9.1h45.1v9.1h-17.7v46.5h-9.8z"
                          />
                          <path
                            id="Layer"
                            className="s0"
                            d="m727.6 95.2c7.7-2.3 13.2-7.7 13.2-16.9v-0.1c0-4.9-1.7-9-4.6-12-3.6-3.5-9.1-5.6-16.1-5.6h-24.7v55.6h9.7v-46.7h14.3c7.2 0 11.5 3.3 11.5 9.2v0.2c0 5.6-4.5 9.3-11.5 9.3h-7.9l19.5 28h11.6z"
                          />
                          <path
                            id="Layer"
                            className="s0"
                            d="m637.9 116.2h-9.9l24.4-56h9l24.5 56h-10.3l-18.9-44.3z"
                          />
                          <path
                            id="Layer"
                            className="s0"
                            d="m752.9 60.7h9.7v55.6h-9.7z"
                          />
                          <path
                            id="Layer"
                            className="s0"
                            d="m801 60.6h-12l-22.6 23.9 23.7 31.7h12l-24.1-31.7z"
                          />
                        </g>
                        <g id="Layer">
                          <g id="Layer">
                            <path
                              id="Layer"
                              className="s1"
                              d="m399.1 60.5v38.5l-29.7-38.5h-11.7l2.7 3.5 16.9 21.9 23.3 30.2h8.2v-55.6z"
                            />
                            <path
                              id="Layer"
                              className="s1"
                              d="m360.4 76.2v39.9h9.6v-27.4z"
                            />
                          </g>
                          <path
                            id="Layer"
                            fillRule="evenodd"
                            className="s1"
                            d="m193.4 117.1q-6.5 0-11.8-2.3-5.3-2.3-9.1-6.2-3.8-3.9-6-9.1-2.1-5.2-2.1-11v-0.2q0-5.9 2.1-11.1 2.2-5.2 6.1-9.1 3.9-3.9 9.2-6.2 5.3-2.3 11.7-2.3 6.5 0 11.8 2.2 5.3 2.3 9.1 6.2 3.8 3.9 6 9.1 2.1 5.2 2.1 11.1v0.1q0 5.9-2.1 11.1-2.2 5.2-6.1 9.1-3.9 3.9-9.2 6.3-5.3 2.3-11.7 2.3zm0.1-9q4.1 0 7.6-1.6 3.4-1.5 5.9-4.2 2.4-2.6 3.8-6.2 1.4-3.6 1.4-7.6v-0.2q0-4-1.4-7.6-1.4-3.7-3.9-6.3-2.5-2.7-5.9-4.3-3.5-1.6-7.6-1.6-4.1 0-7.6 1.6-3.4 1.5-5.9 4.2-2.4 2.7-3.8 6.2-1.4 3.6-1.4 7.7v0.1q0 4.1 1.4 7.7 1.4 3.6 3.9 6.2 2.5 2.7 5.9 4.3 3.5 1.6 7.6 1.6z"
                          />
                          <path
                            id="Layer"
                            fillRule="evenodd"
                            className="s1"
                            d="m320.6 117.1q-6.5 0-11.8-2.3-5.3-2.3-9.1-6.2-3.8-3.9-6-9.1-2.1-5.2-2.1-11v-0.2q0-5.9 2.1-11.1 2.2-5.2 6.1-9.1 3.9-3.9 9.2-6.2 5.3-2.3 11.7-2.3 6.5 0 11.8 2.2 5.3 2.3 9.1 6.2 3.8 3.9 6 9.1 2.1 5.2 2.1 11.1v0.1q0 5.9-2.1 11.1-2.2 5.2-6.1 9.1-3.8 3.9-9.2 6.3-5.3 2.3-11.7 2.3zm0.1-9q4.2 0 7.6-1.6 3.4-1.5 5.9-4.2 2.4-2.6 3.8-6.2 1.4-3.6 1.4-7.6v-0.2q0-4-1.4-7.6-1.4-3.7-3.9-6.3-2.4-2.7-5.9-4.3-3.5-1.6-7.6-1.6-4.1 0-7.6 1.6-3.4 1.5-5.9 4.2-2.4 2.7-3.8 6.2-1.4 3.6-1.4 7.7v0.1q0 4.1 1.4 7.7 1.4 3.6 3.9 6.2 2.5 2.7 5.9 4.3 3.5 1.6 7.6 1.6z"
                          />
                          <path
                            id="Layer"
                            className="s1"
                            d="m435.6 69.6h-17.7v-9.1h45.1v9.1h-17.6v46.5h-9.8z"
                          />
                          <path
                            id="Layer"
                            fillRule="evenodd"
                            className="s1"
                            d="m498.7 117.1q-6.5 0-11.8-2.3-5.3-2.3-9.1-6.2-3.8-3.9-6-9.1-2.1-5.2-2.1-11v-0.2q0-5.9 2.1-11.1 2.2-5.2 6.1-9.1 3.9-3.9 9.2-6.2 5.3-2.3 11.7-2.3 6.5 0 11.8 2.2 5.3 2.3 9.1 6.2 3.8 3.9 6 9.1 2.1 5.2 2.1 11.1v0.1q0 5.9-2.1 11.1-2.2 5.2-6.1 9.1-3.8 3.9-9.2 6.3-5.3 2.3-11.7 2.3zm0.1-9q4.2 0 7.6-1.6 3.4-1.5 5.9-4.2 2.4-2.6 3.8-6.2 1.4-3.6 1.4-7.6v-0.2q0-4-1.4-7.6-1.4-3.7-3.9-6.3-2.4-2.7-5.9-4.3-3.5-1.6-7.6-1.6-4.1 0-7.6 1.6-3.4 1.5-5.9 4.2-2.4 2.7-3.8 6.2-1.4 3.6-1.4 7.7v0.1q0 4.1 1.4 7.7 1.4 3.6 3.9 6.2 2.5 2.7 5.9 4.3 3.5 1.6 7.6 1.6z"
                          />
                          <path
                            id="Layer"
                            className="s1"
                            d="m281.3 77.2q-2.2-5-6.1-8.8-3.9-3.7-9.4-5.8-5.4-2.1-11.9-2.1h-20.7v8.8h9.7 10.9q4.4 0 8 1.5 3.5 1.4 6 3.9 2.5 2.5 3.9 6 1.4 3.5 1.4 7.6v0.2q0 4-1.4 7.5-1.4 3.5-3.9 6-2.5 2.5-6 3.9-3.6 1.4-8 1.4h-10.9v-23.3h-9.7v32.1h20.7q6.5 0 11.9-2.2 5.5-2.1 9.4-5.8 3.9-3.8 6.1-8.8 2.1-5.1 2.1-11v-0.2q0-5.8-2.1-10.9z"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
            </div>
            <div className="flex lg:hidden">
              <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">Open main menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="hidden lg:flex lg:min-w-0 lg:flex-1 lg:justify-end text-gray-900">
              <div className="px-2">
                <Link to={`/login`}>Iniciar sesión</Link>
              </div>
            </div>
          </nav>
          <Dialog as="div" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
            <Dialog.Panel
              focus="true"
              className="fixed inset-0 z-10 overflow-y-auto bg-white px-6 py-6 lg:hidden"
            >
              <div className="flex h-9 items-center justify-between">
                <div className="flex">
                  <div className="-m-1.5 p-1.5"></div>
                </div>
                <div className="flex">
                  <button
                    type="button"
                    className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </div>
              <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-500/10">
                  <div className="py-6">
                    <Link to={`/login`}>Iniciar sesión</Link>
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Dialog>
        </div>
      </div>
      <svg
        className="wave-top mt-6"
        viewBox="0 0 1439 147"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g transform="translate(-1.000000, -14.000000)" fill-rule="nonzero">
            <g className="wave" fill="#f8fafc">
              <path d="M1440,84 C1383.555,64.3 1342.555,51.3 1317,45 C1259.5,30.824 1206.707,25.526 1169,22 C1129.711,18.326 1044.426,18.475 980,22 C954.25,23.409 922.25,26.742 884,32 C845.122,37.787 818.455,42.121 804,45 C776.833,50.41 728.136,61.77 713,65 C660.023,76.309 621.544,87.729 584,94 C517.525,105.104 484.525,106.438 429,108 C379.49,106.484 342.823,104.484 319,102 C278.571,97.783 231.737,88.736 205,84 C154.629,75.076 86.296,57.743 0,32 L0,0 L1440,0 L1440,84 Z"></path>
            </g>
            <g transform="translate(1.000000, 15.000000)" fill="#FFFFFF">
              <g transform="translate(719.500000, 68.500000) rotate(-180.000000) translate(-719.500000, -68.500000) ">
                <path
                  d="M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496"
                  opacity="0.100000001"
                ></path>
                <path
                  d="M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z"
                  opacity="0.100000001"
                ></path>
                <path
                  d="M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z"
                  opacity="0.200000003"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
      <main className="mt-[10vh] lg:mt-[3vh]">
        <body className="leading-normal tracking-normal text-black gradient">
          {/* <!--Hero--> */}
          <div className="">
            <div
              // className="
              // container px-3 mx-auto
              className="flex flex-wrap flex-col md:flex-row items-center justify-between"
            >
              {/* <!--Left Col--> */}
              <div className="flex flex-col w-full justify-center items-center text-left my-[10vh]">
                <h1 className="my-4 lg:text-5xl md:text-5xl lg:text-center md:text-center text-center p-2 w-full text-4xl text-sky-500 font-bold leading-tight lg:m-0 md:m-0">
                  Tu organización al siguiente nivel
                </h1>
                <p className="leading-normal w-full lg:text-center md:text-center text-center text-2xl mb-8 p-2 text-gray-500 lg:m-0 md:m-0">
                  Agenda Digital, ficha de pacientes y más herramientas para tu
                  consultorio
                </p>
              </div>
            </div>
          </div>
          <svg
            className="wave-top rotate-180 -mt-[5vh]"
            viewBox="0 0 1439 147"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g
                transform="translate(-1.000000, -14.000000)"
                fill-rule="nonzero"
              >
                <g className="wave" fill="#f8fafc">
                  <path d="M1440,84 C1383.555,64.3 1342.555,51.3 1317,45 C1259.5,30.824 1206.707,25.526 1169,22 C1129.711,18.326 1044.426,18.475 980,22 C954.25,23.409 922.25,26.742 884,32 C845.122,37.787 818.455,42.121 804,45 C776.833,50.41 728.136,61.77 713,65 C660.023,76.309 621.544,87.729 584,94 C517.525,105.104 484.525,106.438 429,108 C379.49,106.484 342.823,104.484 319,102 C278.571,97.783 231.737,88.736 205,84 C154.629,75.076 86.296,57.743 0,32 L0,0 L1440,0 L1440,84 Z"></path>
                </g>
                <g transform="translate(1.000000, 15.000000)" fill="#FFFFFF">
                  <g transform="translate(719.500000, 68.500000) rotate(-180.000000) translate(-719.500000, -68.500000) ">
                    <path
                      d="M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496"
                      opacity="0.100000001"
                    ></path>
                    <path
                      d="M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z"
                      opacity="0.100000001"
                    ></path>
                    <path
                      d="M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z"
                      opacity="0.200000003"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </svg>
          <svg
            className="wave-top"
            viewBox="0 0 1439 147"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g
                transform="translate(-1.000000, -14.000000)"
                fill-rule="nonzero"
              >
                <g className="wave" fill="#f8fafc">
                  <path d="M1440,84 C1383.555,64.3 1342.555,51.3 1317,45 C1259.5,30.824 1206.707,25.526 1169,22 C1129.711,18.326 1044.426,18.475 980,22 C954.25,23.409 922.25,26.742 884,32 C845.122,37.787 818.455,42.121 804,45 C776.833,50.41 728.136,61.77 713,65 C660.023,76.309 621.544,87.729 584,94 C517.525,105.104 484.525,106.438 429,108 C379.49,106.484 342.823,104.484 319,102 C278.571,97.783 231.737,88.736 205,84 C154.629,75.076 86.296,57.743 0,32 L0,0 L1440,0 L1440,84 Z"></path>
                </g>
                <g transform="translate(1.000000, 15.000000)" fill="#FFFFFF">
                  <g transform="translate(719.500000, 68.500000) rotate(-180.000000) translate(-719.500000, -68.500000) ">
                    <path
                      d="M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496"
                      opacity="0.100000001"
                    ></path>
                    <path
                      d="M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z"
                      opacity="0.100000001"
                    ></path>
                    <path
                      d="M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z"
                      opacity="0.200000003"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </svg>
          <div className="relative lg:-mt-24"></div>
          {/* ========================== Con OdontoStark */}
          <section className="bg-white">
            <div className="container max-w-5xl mx-auto m-8 mt-[30vh] lg:mt-[25vh] md:mt-20 sm:mt-20">
              <h2 className="w-full mt-10 my-2 text-4xl font-bold leading-tight text-center text-gray-500">
                Con OdontoStark podes:
              </h2>
              <div className="w-full mb-4">
                <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
              </div>
              <div className="flex flex-wrap">
                <div className="w-5/6 sm:w-1/2 p-6">
                  <h3 className="text-3xl text-gray-500 font-bold leading-none mb-3">
                    🗓️ Manejar tu agenda y agiliza los recordatorios
                  </h3>
                  <p className="text-gray-600 mb-8">
                    Organiza tu dia a dia con la agenda integrada, y manda
                    recordatorios a tus pacientes para que no se olviden de sus
                    turnos en 2 clicks.
                  </p>
                </div>
                <div className="w-full sm:w-1/2 p-6">
                  <img className="w-40vw h-[25vh]" src={agenda} />
                </div>
              </div>

              <div className="flex flex-wrap flex-col-reverse sm:flex-row">
                <div className="w-full sm:w-1/2 p-6 mt-6">
                  <img className="w-40vw h-[25vh]" src={allInOne} />
                </div>
                <div className="w-full sm:w-1/2 p-6 mt-6">
                  <div className="align-middle">
                    <h3 className="text-3xl text-gray-500 font-bold leading-none mb-3">
                      👤 Llevar todos tus pacientes en un solo lugar
                    </h3>
                    <p className="text-gray-600 mb-8">
                      Lleva el registro de tus pacientes, almacenando sus datos
                      personales, historial médico, tratamientos y más.
                      <br></br>
                      Todo, desde cualquier dispositivo.
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex flex-wrap">
                <div className="w-5/6 sm:w-1/2 p-6">
                  <h3 className="text-3xl text-gray-500 font-bold leading-none mb-3">
                    📊 Llevar tus finanzas odontologicas
                  </h3>
                  <p className="text-gray-600 mb-8">
                    Llevá un registro de tus ingresos, servicios y facturación
                    de una forma sencialla y ordenada.
                  </p>
                </div>
                <div className="w-full sm:w-1/2 p-6">
                  <img className="w-40vw h-[25vh]" src={finances} />
                </div>
              </div>
            </div>
          </section>

          <svg
            className="wave-top rotate-180 -mt-[5vh]"
            viewBox="0 0 1439 147"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g
                transform="translate(-1.000000, -14.000000)"
                fill-rule="nonzero"
              >
                <g className="wave" fill="#f8fafc">
                  <path d="M1440,84 C1383.555,64.3 1342.555,51.3 1317,45 C1259.5,30.824 1206.707,25.526 1169,22 C1129.711,18.326 1044.426,18.475 980,22 C954.25,23.409 922.25,26.742 884,32 C845.122,37.787 818.455,42.121 804,45 C776.833,50.41 728.136,61.77 713,65 C660.023,76.309 621.544,87.729 584,94 C517.525,105.104 484.525,106.438 429,108 C379.49,106.484 342.823,104.484 319,102 C278.571,97.783 231.737,88.736 205,84 C154.629,75.076 86.296,57.743 0,32 L0,0 L1440,0 L1440,84 Z"></path>
                </g>
                <g transform="translate(1.000000, 15.000000)" fill="#FFFFFF">
                  <g transform="translate(719.500000, 68.500000) rotate(-180.000000) translate(-719.500000, -68.500000) ">
                    <path
                      d="M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496"
                      opacity="0.100000001"
                    ></path>
                    <path
                      d="M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z"
                      opacity="0.100000001"
                    ></path>
                    <path
                      d="M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z"
                      opacity="0.200000003"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </svg>

          {/* ========================== Final OdontoStark */}

          {/* <section className="bg-white border-b py-8">
            <div className="container mx-auto flex flex-wrap pt-4 pb-12">
              <h2 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
                Reviews
              </h2>
              <div className="w-full mb-4">
                <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
              </div>
              <div className="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
                <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
                  <a
                    href="#"
                    className="flex flex-wrap no-underline hover:no-underline"
                  >
                    <p className="w-full text-gray-600 text-xs md:text-sm px-6">
                      xGETTING STARTED
                    </p>
                    <div className="w-full font-bold text-xl text-gray-800 px-6">
                      Lorem ipsum dolor sit amet.
                    </div>
                    <p className="text-gray-800 text-base px-6 mb-5">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Aliquam at ipsum eu nunc commodo posuere et sit amet
                      ligula.
                    </p>
                  </a>
                </div>
                <div className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow p-6">
                  <div className="flex items-center justify-start">
                    <button className="mx-auto lg:mx-0 hover:underline gradient text-black font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
                      Action
                    </button>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
                <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
                  <a
                    href="#"
                    className="flex flex-wrap no-underline hover:no-underline"
                  >
                    <p className="w-full text-gray-600 text-xs md:text-sm px-6">
                      xGETTING STARTED
                    </p>
                    <div className="w-full font-bold text-xl text-gray-800 px-6">
                      Lorem ipsum dolor sit amet.
                    </div>
                    <p className="text-gray-800 text-base px-6 mb-5">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Aliquam at ipsum eu nunc commodo posuere et sit amet
                      ligula.
                    </p>
                  </a>
                </div>
                <div className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow p-6">
                  <div className="flex items-center justify-center">
                    <button className="mx-auto lg:mx-0 hover:underline gradient text-black font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
                      Action
                    </button>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
                <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
                  <a
                    href="#"
                    className="flex flex-wrap no-underline hover:no-underline"
                  >
                    <p className="w-full text-gray-600 text-xs md:text-sm px-6">
                      xGETTING STARTED
                    </p>
                    <div className="w-full font-bold text-xl text-gray-800 px-6">
                      Lorem ipsum dolor sit amet.
                    </div>
                    <p className="text-gray-800 text-base px-6 mb-5">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Aliquam at ipsum eu nunc commodo posuere et sit amet
                      ligula.
                    </p>
                  </a>
                </div>
                <div className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow p-6">
                  <div className="flex items-center justify-end">
                    <button className="mx-auto lg:mx-0 hover:underline gradient text-black font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
                      Action
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
          <section className="bg-gray-100">
            <div className="container mx-auto px-2 pt-4 pb-12 text-gray-800">
              <h2 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
                Precio
              </h2>
              <div className="w-full mb-4">
                <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
              </div>
              <div className="flex flex-col sm:flex-row justify-center pt-12 my-12 sm:my-4">
                <div className="flex flex-col w-5/6 lg:w-1/4 mx-auto lg:mx-0 rounded-none lg:rounded-l-lg bg-white mt-4">
                  <div className="flex-1 bg-white text-gray-600 rounded-t rounded-b-none overflow-hidden shadow">
                    <div className="p-8 text-3xl font-bold text-center border-b-4">
                      Estudiante
                    </div>
                    <ul className="w-full text-center text-sm">
                      <li className="border-b py-4">Acceso completo</li>
                      <li className="border-b py-4">6 meses cashback</li>
                      <li className="border-b py-4">Programa de Referencia</li>
                    </ul>
                  </div>
                  <div className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow p-6">
                    <div className="w-full pt-6 text-3xl text-gray-600 font-bold text-center">
                      2,000,000 Gs.
                    </div>
                    <div className="flex items-center justify-center">
                      <button
                        onClick={() =>
                          window.open(
                            `https://wa.me/595994880781?text=Hola,%20me%20gustaria%20saber%20más%20sobre%20la%20membresia%20para%20estudiantes.%0aGracias`,
                            "_blank"
                          )
                        }
                        className="mx-auto lg:mx-0 hover:underline gradient text-black font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
                      >
                        Solicitar Acceso
                      </button>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col w-5/6 lg:w-1/3 mx-auto lg:mx-0 rounded-lg bg-white mt-4 sm:-mt-6 shadow-lg z-10">
                  <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
                    <div className="w-full p-8 text-3xl font-bold text-center">
                      Premium
                    </div>
                    <div className="h-1 w-full gradient my-0 py-0 rounded-t"></div>
                    <ul className="w-full text-center text-base font-bold">
                      <li className="border-b py-4">1 mes de prueba gratis</li>
                      <li className="border-b py-4">6 meses de cashback</li>
                      <li className="border-b py-4">Programa de Referencia</li>
                      <li className="border-b py-4">
                        Constante actualización de las nuevas funcionalidades
                      </li>
                      <li className="border-b py-4">
                        Atencion al Cliente prioritario
                      </li>
                    </ul>
                  </div>
                  <div className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow p-6">
                    <div className="w-full pt-6 text-4xl font-bold text-center">
                      2,800,000 Gs.
                    </div>
                    <div className="flex items-center justify-center">
                      <button
                        onClick={() =>
                          window.open(
                            `https://wa.me/595994880781?text=Hola,%20me%20gustaria%20saber%20más%20sobre%20la%20membresia%20-%20Premium%20-%0aGracias`,
                            "_blank"
                          )
                        }
                        className="mx-auto lg:mx-0 hover:underline gradient text-black font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
                      >
                        Solicitar un ascesor
                      </button>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col w-5/6 lg:w-1/4 mx-auto lg:mx-0 rounded-none lg:rounded-l-lg bg-white mt-4">
                  <div className="flex-1 bg-white text-gray-600 rounded-t rounded-b-none overflow-hidden shadow">
                    <div className="p-8 text-3xl font-bold text-center border-b-4">
                      Gratis
                    </div>
                    <ul className="w-full text-center text-sm">
                      <li className="border-b py-4">Programa de Referencia</li>
                      {/* <li className="border-b py-4">
                        Te devolvemos tu dinero si ya tenes una membresia
                      </li> */}
                      <li className="border-b py-4">
                        Funcionalidades restringidas <br /> (Ingresos y Egresos,
                        e imagenes del paciente)
                      </li>
                    </ul>
                  </div>
                  <div className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow p-6">
                    <div className="w-full pt-6 text-3xl text-gray-600 font-bold text-center">
                      🦷
                    </div>
                    <div className="flex items-center justify-center">
                      <Link
                        to={"/registrarse"}
                        className="mx-auto lg:mx-0 hover:underline gradient text-black font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
                      >
                        Registrarse
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- Change the colour #f8fafc to match the previous section colour --> */}
          <svg
            className="wave-top"
            viewBox="0 0 1439 147"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            // xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g
                transform="translate(-1.000000, -14.000000)"
                fillRule="nonzero"
              >
                <g className="wave" fill="#f8fafc">
                  <path d="M1440,84 C1383.555,64.3 1342.555,51.3 1317,45 C1259.5,30.824 1206.707,25.526 1169,22 C1129.711,18.326 1044.426,18.475 980,22 C954.25,23.409 922.25,26.742 884,32 C845.122,37.787 818.455,42.121 804,45 C776.833,50.41 728.136,61.77 713,65 C660.023,76.309 621.544,87.729 584,94 C517.525,105.104 484.525,106.438 429,108 C379.49,106.484 342.823,104.484 319,102 C278.571,97.783 231.737,88.736 205,84 C154.629,75.076 86.296,57.743 0,32 L0,0 L1440,0 L1440,84 Z"></path>
                </g>
                <g transform="translate(1.000000, 15.000000)" fill="#FFFFFF">
                  <g transform="translate(719.500000, 68.500000) rotate(-180.000000) translate(-719.500000, -68.500000) ">
                    <path
                      d="M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496"
                      opacity="0.100000001"
                    ></path>
                    <path
                      d="M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z"
                      opacity="0.100000001"
                    ></path>
                    <path
                      d="M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z"
                      opacity="0.200000003"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </svg>

          <section className="container mx-auto text-center py-6 mb-12">
            <h2 className="w-full my-2 text-5xl font-bold leading-tight text-center text-black">
              Nos encanta escucharte
            </h2>
            <div className="w-full mb-4">
              <div className="h-1 mx-auto bg-white w-1/6 opacity-25 my-0 py-0 rounded-t"></div>
            </div>
            <h3 className="my-4 text-3xl leading-tight">
              Comunicate con nosotros por whatsapp
            </h3>
            <button className="mx-auto lg:mx-0 hover:underline bg-white text-gray-800 font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
              Enviar mensajes
            </button>
          </section>
          {/* <!--Footer--> */}
          <footer className="bg-white">
            <div className="container mx-auto px-8">
              <div className="w-full flex justify-between flex-col md:flex-row py-6">
                <div className="mb-6 text-black">
                  <svg
                    version="1.2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 826 175"
                    className="h-[7vh]"
                  >
                    <style>
                      {`.s0 { fill: #253141 }
    .s1 { fill: #0ea5e9 }
    .s2 { fill: none;stroke: #0ea5e9;strokeLinecap: round;strokeLinejoin: round;strokeWidth: 1.9 } `}
                    </style>
                    <g id="Layer">
                      <g id="Layer">
                        <g id="Layer">
                          <g id="Layer">
                            <path
                              id="Layer"
                              className="s0"
                              d="m535 108.1l5.9-7c5.3 4.6 10.7 7.2 17.5 7.2 6 0 9.9-2.8 9.9-6.9v-0.2c0-4-2.3-6.1-12.6-8.5-11.8-2.9-18.5-6.4-18.5-16.6v-0.2c0-9.5 7.9-16.1 19-16.1 8.1 0 14.5 2.5 20.2 7l-5.3 7.4c-5-3.7-10-5.7-15.1-5.7-5.7 0-9 2.9-9 6.6v0.1c0 4.3 2.5 6.2 13.2 8.8 11.8 2.8 17.8 7 17.8 16.2v0.2c0 10.4-8.2 16.6-19.8 16.6-8.5 0-16.6-2.9-23.2-8.9z"
                            />
                            <path
                              id="Layer"
                              className="s0"
                              d="m602 69.7h-17.6v-9.1h45.1v9.1h-17.7v46.5h-9.8z"
                            />
                            <path
                              id="Layer"
                              className="s0"
                              d="m727.6 95.2c7.7-2.3 13.2-7.7 13.2-16.9v-0.1c0-4.9-1.7-9-4.6-12-3.6-3.5-9.1-5.6-16.1-5.6h-24.7v55.6h9.7v-46.7h14.3c7.2 0 11.5 3.3 11.5 9.2v0.2c0 5.6-4.5 9.3-11.5 9.3h-7.9l19.5 28h11.6z"
                            />
                            <path
                              id="Layer"
                              className="s0"
                              d="m637.9 116.2h-9.9l24.4-56h9l24.5 56h-10.3l-18.9-44.3z"
                            />
                            <path
                              id="Layer"
                              className="s0"
                              d="m752.9 60.7h9.7v55.6h-9.7z"
                            />
                            <path
                              id="Layer"
                              className="s0"
                              d="m801 60.6h-12l-22.6 23.9 23.7 31.7h12l-24.1-31.7z"
                            />
                          </g>
                          <g id="Layer">
                            <g id="Layer">
                              <path
                                id="Layer"
                                className="s1"
                                d="m399.1 60.5v38.5l-29.7-38.5h-11.7l2.7 3.5 16.9 21.9 23.3 30.2h8.2v-55.6z"
                              />
                              <path
                                id="Layer"
                                className="s1"
                                d="m360.4 76.2v39.9h9.6v-27.4z"
                              />
                            </g>
                            <path
                              id="Layer"
                              fillRule="evenodd"
                              className="s1"
                              d="m193.4 117.1q-6.5 0-11.8-2.3-5.3-2.3-9.1-6.2-3.8-3.9-6-9.1-2.1-5.2-2.1-11v-0.2q0-5.9 2.1-11.1 2.2-5.2 6.1-9.1 3.9-3.9 9.2-6.2 5.3-2.3 11.7-2.3 6.5 0 11.8 2.2 5.3 2.3 9.1 6.2 3.8 3.9 6 9.1 2.1 5.2 2.1 11.1v0.1q0 5.9-2.1 11.1-2.2 5.2-6.1 9.1-3.9 3.9-9.2 6.3-5.3 2.3-11.7 2.3zm0.1-9q4.1 0 7.6-1.6 3.4-1.5 5.9-4.2 2.4-2.6 3.8-6.2 1.4-3.6 1.4-7.6v-0.2q0-4-1.4-7.6-1.4-3.7-3.9-6.3-2.5-2.7-5.9-4.3-3.5-1.6-7.6-1.6-4.1 0-7.6 1.6-3.4 1.5-5.9 4.2-2.4 2.7-3.8 6.2-1.4 3.6-1.4 7.7v0.1q0 4.1 1.4 7.7 1.4 3.6 3.9 6.2 2.5 2.7 5.9 4.3 3.5 1.6 7.6 1.6z"
                            />
                            <path
                              id="Layer"
                              fillRule="evenodd"
                              className="s1"
                              d="m320.6 117.1q-6.5 0-11.8-2.3-5.3-2.3-9.1-6.2-3.8-3.9-6-9.1-2.1-5.2-2.1-11v-0.2q0-5.9 2.1-11.1 2.2-5.2 6.1-9.1 3.9-3.9 9.2-6.2 5.3-2.3 11.7-2.3 6.5 0 11.8 2.2 5.3 2.3 9.1 6.2 3.8 3.9 6 9.1 2.1 5.2 2.1 11.1v0.1q0 5.9-2.1 11.1-2.2 5.2-6.1 9.1-3.8 3.9-9.2 6.3-5.3 2.3-11.7 2.3zm0.1-9q4.2 0 7.6-1.6 3.4-1.5 5.9-4.2 2.4-2.6 3.8-6.2 1.4-3.6 1.4-7.6v-0.2q0-4-1.4-7.6-1.4-3.7-3.9-6.3-2.4-2.7-5.9-4.3-3.5-1.6-7.6-1.6-4.1 0-7.6 1.6-3.4 1.5-5.9 4.2-2.4 2.7-3.8 6.2-1.4 3.6-1.4 7.7v0.1q0 4.1 1.4 7.7 1.4 3.6 3.9 6.2 2.5 2.7 5.9 4.3 3.5 1.6 7.6 1.6z"
                            />
                            <path
                              id="Layer"
                              className="s1"
                              d="m435.6 69.6h-17.7v-9.1h45.1v9.1h-17.6v46.5h-9.8z"
                            />
                            <path
                              id="Layer"
                              fillRule="evenodd"
                              className="s1"
                              d="m498.7 117.1q-6.5 0-11.8-2.3-5.3-2.3-9.1-6.2-3.8-3.9-6-9.1-2.1-5.2-2.1-11v-0.2q0-5.9 2.1-11.1 2.2-5.2 6.1-9.1 3.9-3.9 9.2-6.2 5.3-2.3 11.7-2.3 6.5 0 11.8 2.2 5.3 2.3 9.1 6.2 3.8 3.9 6 9.1 2.1 5.2 2.1 11.1v0.1q0 5.9-2.1 11.1-2.2 5.2-6.1 9.1-3.8 3.9-9.2 6.3-5.3 2.3-11.7 2.3zm0.1-9q4.2 0 7.6-1.6 3.4-1.5 5.9-4.2 2.4-2.6 3.8-6.2 1.4-3.6 1.4-7.6v-0.2q0-4-1.4-7.6-1.4-3.7-3.9-6.3-2.4-2.7-5.9-4.3-3.5-1.6-7.6-1.6-4.1 0-7.6 1.6-3.4 1.5-5.9 4.2-2.4 2.7-3.8 6.2-1.4 3.6-1.4 7.7v0.1q0 4.1 1.4 7.7 1.4 3.6 3.9 6.2 2.5 2.7 5.9 4.3 3.5 1.6 7.6 1.6z"
                            />
                            <path
                              id="Layer"
                              className="s1"
                              d="m281.3 77.2q-2.2-5-6.1-8.8-3.9-3.7-9.4-5.8-5.4-2.1-11.9-2.1h-20.7v8.8h9.7 10.9q4.4 0 8 1.5 3.5 1.4 6 3.9 2.5 2.5 3.9 6 1.4 3.5 1.4 7.6v0.2q0 4-1.4 7.5-1.4 3.5-3.9 6-2.5 2.5-6 3.9-3.6 1.4-8 1.4h-10.9v-23.3h-9.7v32.1h20.7q6.5 0 11.9-2.2 5.5-2.1 9.4-5.8 3.9-3.8 6.1-8.8 2.1-5.1 2.1-11v-0.2q0-5.8-2.1-10.9z"
                            />
                          </g>
                        </g>
                        <g id="Layer">
                          <g id="Layer">
                            <path
                              id="Layer"
                              className="s2"
                              d="m52.4 89.8l-6-14-3.1-20.3 2.2-16.4 7.6-10.5 11.7-3.9 19.2 6 11.5 1.3 25-6.3 13.2 2.2 10.6 10 0.7 18.6-2.1 18.8-5.9 17.6-1.3 9.5-0.4 19.5-1.8 14.5-13.7 14.6-10.5-2.4 2.9-24.3-7.8-18.4-8.7-3.6-15.7 9.9-4.8 11.1-0.8 13 2 12.6-10.5 1.9-12.5-16.8-2.8-17.7z"
                            />
                            <path
                              id="Layer"
                              className="s2"
                              d="m53.1 28.6l12.7 12.8 19.2 4.7 31.8 11.7 26.1 17.5-18.1 3.6-25.9 9.7-18.9 8.5-18.5-0.3-8.6-5.6"
                            />
                            <path
                              id="Layer"
                              className="s2"
                              d="m64.8 24.7l1 16.7-10.2 18.7-9.2 15.7"
                            />
                            <path
                              id="Layer"
                              className="s2"
                              d="m45.7 37.9l19.6 2.7 18.7-9.9 1.5 17.9 5.1 15.2 9.1 24.3 5 18.4"
                            />
                            <path
                              id="Layer"
                              className="s2"
                              d="m44.5 76.8l24.3-6.1 21.8-6.9 27.9-5.7 26.6-2.8"
                            />
                            <path
                              id="Layer"
                              className="s2"
                              d="m117.4 58.9l-21.9-26.9-10.5 15.1-28.8 12.1-12.9-3.7 22-14.9"
                            />
                            <path
                              id="Layer"
                              className="s2"
                              d="m95.5 32l34.1 7.4 14.7-1.5"
                            />
                            <path
                              id="Layer"
                              className="s2"
                              d="m134 27.4l-4.4 12-9.1-13.7"
                            />
                            <path
                              id="Layer"
                              className="s2"
                              d="m121.2 25.9l-4.4 31.9 8 21.1-34.2-15.1-34.4-4.6 11.8 12 30.9 17.4 17.9-30.8 12.8-18.4"
                            />
                            <path
                              id="Layer"
                              className="s2"
                              d="m85 47.1l-16.2 23.6-16.5 19.2"
                            />
                            <path
                              id="Layer"
                              className="s2"
                              d="m68 71.2l-6.5 25.6-10.7 19.2 24 7.2-21.3 10.5 20.9 2.3-8.7 14.6"
                            />
                            <path
                              id="Layer"
                              className="s2"
                              d="m79.2 97.3l1 15.4-18.7-15.9 13.3 26.4"
                            />
                            <path
                              id="Layer"
                              className="s2"
                              d="m124.8 78.9l12.2 14-37.3-3.3 36 12.8-31.3 3.5"
                            />
                            <path
                              id="Layer"
                              className="s2"
                              d="m68.1 71.9l11.9 25.2 15.4 5.6 4.3-13.1"
                            />
                            <path
                              id="Layer"
                              className="s2"
                              d="m135.5 121.2l-23.5 3.3 21.7 11.2 1.8-14.5-30.8-14.7"
                            />
                            <path
                              id="Layer"
                              className="s2"
                              d="m112 124.5l7.4 26.1"
                            />
                          </g>
                          <g id="Layer">
                            <path
                              id="Layer"
                              className="s0"
                              d="m129.6 43.2c-2.1 0-3.7-1.7-3.7-3.8 0-2.1 1.6-3.8 3.7-3.8 2.1 0 3.7 1.7 3.7 3.8 0 2.1-1.6 3.8-3.7 3.8z"
                            />
                            <path
                              id="Layer"
                              className="s0"
                              d="m121.2 28.1c-1.3 0-2.2-1-2.2-2.2 0-1.2 0.9-2.2 2.2-2.2 1.2 0 2.2 1 2.2 2.2 0 1.2-1 2.2-2.2 2.2z"
                            />
                            <path
                              id="Layer"
                              className="s0"
                              d="m134 30.5c-1.7 0-3-1.4-3-3.1 0-1.7 1.3-3 3-3 1.7 0 3 1.3 3 3 0 1.7-1.3 3.1-3 3.1z"
                            />
                            <path
                              id="Layer"
                              className="s0"
                              d="m144.3 41.6c-2.1 0-3.7-1.7-3.7-3.7 0-2.1 1.6-3.8 3.7-3.8 2 0 3.7 1.7 3.7 3.8 0 2-1.7 3.7-3.7 3.7z"
                            />
                            <path
                              id="Layer"
                              className="s0"
                              d="m142.9 77.5c-1.2 0-2.2-1-2.2-2.2 0-1.3 1-2.3 2.2-2.3 1.3 0 2.2 1 2.2 2.3 0 1.2-0.9 2.2-2.2 2.2z"
                            />
                            <path
                              id="Layer"
                              className="s0"
                              d="m145.1 58.4c-1.6 0-3-1.4-3-3.1 0-1.7 1.4-3.1 3-3.1 1.7 0 3.1 1.4 3.1 3.1 0 1.7-1.4 3.1-3.1 3.1z"
                            />
                            <path
                              id="Layer"
                              className="s0"
                              d="m124.8 82.7c-2.1 0-3.7-1.7-3.7-3.8 0-2 1.6-3.7 3.7-3.7 2 0 3.7 1.7 3.7 3.7 0 2.1-1.7 3.8-3.7 3.8z"
                            />
                            <path
                              id="Layer"
                              className="s0"
                              d="m137.2 95.8c-2.1 0-3.7-1.7-3.7-3.8 0-2.1 1.6-3.7 3.7-3.7 2.1 0 3.7 1.6 3.7 3.7 0 2.1-1.6 3.8-3.7 3.8z"
                            />
                            <path
                              id="Layer"
                              className="s0"
                              d="m135.7 104.7c-1.2 0-2.2-1-2.2-2.3 0-1.2 1-2.2 2.2-2.2 1.2 0 2.2 1 2.2 2.2 0 1.3-1 2.3-2.2 2.3z"
                            />
                            <path
                              id="Layer"
                              className="s0"
                              d="m135.5 124.3c-1.7 0-3-1.4-3-3.1 0-1.7 1.3-3 3-3 1.7 0 3.1 1.3 3.1 3 0 1.7-1.4 3.1-3.1 3.1z"
                            />
                            <g id="Layer">
                              <path
                                id="Layer"
                                className="s0"
                                d="m116.8 62.3c-2.5 0-4.4-2-4.4-4.5 0-2.4 1.9-4.4 4.4-4.4 2.4 0 4.4 2 4.4 4.4 0 2.5-2 4.5-4.4 4.5z"
                              />
                              <path
                                id="Layer"
                                className="s0"
                                d="m90.6 67.6c-2.1 0-3.7-1.7-3.7-3.8 0-2.1 1.6-3.7 3.7-3.7 2.1 0 3.7 1.6 3.7 3.7 0 2.1-1.6 3.8-3.7 3.8z"
                              />
                              <path
                                id="Layer"
                                className="s0"
                                d="m85 51.5c-2.4 0-4.4-2-4.4-4.4 0-2.5 2-4.5 4.4-4.5 2.5 0 4.4 2 4.4 4.5 0 2.4-1.9 4.4-4.4 4.4z"
                              />
                              <path
                                id="Layer"
                                className="s0"
                                d="m95.5 35.1c-1.7 0-3-1.4-3-3.1 0-1.7 1.3-3 3-3 1.7 0 3 1.3 3 3 0 1.7-1.3 3.1-3 3.1z"
                              />
                              <path
                                id="Layer"
                                className="s0"
                                d="m84 32.9c-1.2 0-2.2-1-2.2-2.2 0-1.3 1-2.2 2.2-2.2 1.2 0 2.2 0.9 2.2 2.2 0 1.2-1 2.2-2.2 2.2z"
                              />
                              <path
                                id="Layer"
                                className="s0"
                                d="m80 100.9c-2.1 0-3.8-1.7-3.8-3.8 0-2 1.7-3.7 3.8-3.7 2 0 3.7 1.7 3.7 3.7 0 2.1-1.7 3.8-3.7 3.8z"
                              />
                              <path
                                id="Layer"
                                className="s0"
                                d="m99.5 91.7c-1.7 0-3-1.4-3-3.1 0-1.7 1.3-3.1 3-3.1 1.7 0 3.1 1.4 3.1 3.1 0 1.7-1.4 3.1-3.1 3.1z"
                              />
                              <path
                                id="Layer"
                                className="s0"
                                d="m61.5 99c-1.2 0-2.2-1-2.2-2.2 0-1.2 1-2.2 2.2-2.2 1.2 0 2.2 1 2.2 2.2 0 1.2-1 2.2-2.2 2.2z"
                              />
                              <path
                                id="Layer"
                                className="s0"
                                d="m95.4 104.9c-1.2 0-2.2-1-2.2-2.2 0-1.2 1-2.2 2.2-2.2 1.2 0 2.2 1 2.2 2.2 0 1.2-1 2.2-2.2 2.2z"
                              />
                              <path
                                id="Layer"
                                className="s0"
                                d="m107.7 106.5c0 1.7-1.3 3.1-3 3.1-1.7 0-3-1.4-3-3.1 0-1.7 1.3-3 3-3 1.7 0 3 1.3 3 3z"
                              />
                              <path
                                id="Layer"
                                className="s0"
                                d="m80.2 114.9c-1.2 0-2.2-1-2.2-2.2 0-1.2 1-2.2 2.2-2.2 1.2 0 2.2 1 2.2 2.2 0 1.2-1 2.2-2.2 2.2z"
                              />
                              <path
                                id="Layer"
                                className="s0"
                                d="m74.8 126.3c-1.7 0-3-1.4-3-3.1 0-1.7 1.3-3.1 3-3.1 1.7 0 3 1.4 3 3.1 0 1.7-1.3 3.1-3 3.1z"
                              />
                              <g id="Layer">
                                <path
                                  id="Layer"
                                  className="s0"
                                  d="m45.7 42.3c-2.4 0-4.4-2-4.4-4.4 0-2.5 2-4.5 4.4-4.5 2.4 0 4.4 2 4.4 4.5 0 2.4-2 4.4-4.4 4.4z"
                                />
                                <path
                                  id="Layer"
                                  className="s0"
                                  d="m65.3 44.3c-2.1 0-3.7-1.6-3.7-3.7 0-2.1 1.6-3.8 3.7-3.8 2 0 3.7 1.7 3.7 3.8 0 2.1-1.7 3.7-3.7 3.7z"
                                />
                                <path
                                  id="Layer"
                                  className="s0"
                                  d="m64.8 28.5c-2.1 0-3.8-1.7-3.8-3.8 0-2.1 1.7-3.8 3.8-3.8 2 0 3.7 1.7 3.7 3.8 0 2.1-1.7 3.8-3.7 3.8z"
                                />
                                <path
                                  id="Layer"
                                  className="s0"
                                  d="m53.1 30.9c-1.2 0-2.2-1-2.2-2.3 0-1.2 1-2.2 2.2-2.2 1.2 0 2.2 1 2.2 2.2 0 1.3-1 2.3-2.2 2.3z"
                                />
                                <path
                                  id="Layer"
                                  className="s0"
                                  d="m43.3 57.7c-1.2 0-2.2-1-2.2-2.2 0-1.3 1-2.3 2.2-2.3 1.2 0 2.2 1 2.2 2.3 0 1.2-1 2.2-2.2 2.2z"
                                />
                                <path
                                  id="Layer"
                                  className="s0"
                                  d="m46.4 80.2c-2.5 0-4.4-2-4.4-4.4 0-2.5 1.9-4.5 4.4-4.5 2.4 0 4.3 2 4.3 4.5 0 2.4-1.9 4.4-4.3 4.4z"
                                />
                                <path
                                  id="Layer"
                                  className="s0"
                                  d="m56.2 63c-2.1 0-3.8-1.7-3.8-3.8 0-2.1 1.7-3.8 3.8-3.8 2 0 3.7 1.7 3.7 3.8 0 2.1-1.7 3.8-3.7 3.8z"
                                />
                                <path
                                  id="Layer"
                                  className="s0"
                                  d="m68 74.2c-1.7 0-3.1-1.3-3.1-3 0-1.7 1.4-3.1 3.1-3.1 1.7 0 3 1.4 3 3.1 0 1.7-1.3 3-3 3z"
                                />
                                <path
                                  id="Layer"
                                  className="s0"
                                  d="m52.3 93c-1.7 0-3.1-1.4-3.1-3.1 0-1.7 1.4-3.1 3.1-3.1 1.6 0 3 1.4 3 3.1 0 1.7-1.4 3.1-3 3.1z"
                                />
                                <path
                                  id="Layer"
                                  className="s0"
                                  d="m50.8 120.5c-2.4 0-4.4-2-4.4-4.5 0-2.4 2-4.4 4.4-4.4 2.5 0 4.4 2 4.4 4.4 0 2.5-1.9 4.5-4.4 4.5z"
                                />
                                <path
                                  id="Layer"
                                  className="s0"
                                  d="m65.7 153.7c-1.7 0-3-1.4-3-3.1 0-1.7 1.3-3.1 3-3.1 1.7 0 3 1.4 3 3.1 0 1.7-1.3 3.1-3 3.1z"
                                />
                                <path
                                  id="Layer"
                                  className="s0"
                                  d="m76.4 151.6c-1.2 0-2.2-1-2.2-2.3 0-1.2 1-2.2 2.2-2.2 1.2 0 2.2 1 2.2 2.2 0 1.3-1 2.3-2.2 2.3z"
                                />
                                <path
                                  id="Layer"
                                  className="s0"
                                  d="m56.6 133.7c0 1.7-1.4 3.1-3.1 3.1-1.6 0-3-1.4-3-3.1 0-1.7 1.4-3.1 3-3.1 1.7 0 3.1 1.4 3.1 3.1z"
                                />
                              </g>
                              <path
                                id="Layer"
                                className="s0"
                                d="m113.7 148.1c0 2.4-1.9 4.4-4.4 4.4-2.4 0-4.4-2-4.4-4.4 0-2.5 2-4.5 4.4-4.5 2.5 0 4.4 2 4.4 4.5z"
                              />
                              <path
                                id="Layer"
                                className="s0"
                                d="m119.4 153.7c-1.7 0-3.1-1.4-3.1-3.1 0-1.7 1.4-3.1 3.1-3.1 1.7 0 3 1.4 3 3.1 0 1.7-1.3 3.1-3 3.1z"
                              />
                              <path
                                id="Layer"
                                className="s0"
                                d="m112 127.5c-1.6 0-3-1.3-3-3 0-1.7 1.4-3.1 3-3.1 1.7 0 3.1 1.4 3.1 3.1 0 1.7-1.4 3-3.1 3z"
                              />
                              <path
                                id="Layer"
                                className="s0"
                                d="m74.4 138.2c-1.2 0-2.2-1-2.2-2.2 0-1.2 1-2.2 2.2-2.2 1.2 0 2.2 1 2.2 2.2 0 1.2-1 2.2-2.2 2.2z"
                              />
                            </g>
                            <path
                              id="Layer"
                              className="s0"
                              d="m138 135.7c0 2.5-1.9 4.5-4.3 4.5-2.5 0-4.4-2-4.4-4.5 0-2.4 1.9-4.4 4.4-4.4 2.4 0 4.3 2 4.3 4.4z"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
                <div className="">
                  <p className="uppercase text-gray-500 md:mb-6">Links</p>
                  <ul className="list-reset mb-6">
                    <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                      <button className="text-gray-400" disabled>
                        Preguntas Frecuentes
                      </button>
                    </li>
                    <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                      <button className="text-gray-400" disabled>
                        Ayuda
                      </button>
                    </li>
                    <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                      <button className="text-gray-400" disabled>
                        Atencion al cliente
                      </button>
                    </li>
                  </ul>
                </div>
                {/* <div className="flex-1">
                  <p className="uppercase text-gray-500 md:mb-6">Legal</p>
                  <ul className="list-reset mb-6">
                    <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                      <a
                        href="#"
                        className="no-underline hover:underline text-gray-800 hover:text-pink-500"
                      >
                        Terms
                      </a>
                    </li>
                    <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                      <a
                        href="#"
                        className="no-underline hover:underline text-gray-800 hover:text-pink-500"
                      >
                        Privacy
                      </a>
                    </li>
                  </ul>
                </div> */}
                <div className="">
                  <p className="uppercase text-gray-500 md:mb-6">Social</p>
                  <ul className="list-reset mb-6">
                    <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                      <a
                        href="https://www.facebook.com/profile.php?id=61558801250923"
                        target="_blank"
                        className="no-underline hover:underline text-gray-800 hover:text-pink-500"
                      >
                        Facebook
                      </a>
                    </li>
                    <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                      <a
                        href="https://www.instagram.com/odontostark/"
                        target="_blank"
                        className="no-underline hover:underline text-gray-800 hover:text-pink-500"
                      >
                        Instagram
                      </a>
                    </li>
                  </ul>
                </div>
                {/* <div className="flex-1">
                  <p className="uppercase text-gray-500 md:mb-6">Company</p>
                  <ul className="list-reset mb-6">
                    <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                      <a
                        href="#"
                        className="no-underline hover:underline text-gray-800 hover:text-pink-500"
                      >
                        Official Blog
                      </a>
                    </li>
                    <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                      <a
                        href="#"
                        className="no-underline hover:underline text-gray-800 hover:text-pink-500"
                      >
                        About Us
                      </a>
                    </li>
                    <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                      <a
                        href="#"
                        className="no-underline hover:underline text-gray-800 hover:text-pink-500"
                      >
                        Contact
                      </a>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
          </footer>
        </body>
      </main>
    </div>
  );
}
