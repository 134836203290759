import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
} from "firebase/storage";
import { db } from "./firebase";
import { generateUuid } from "./firebase.helpers";
import toast from "react-hot-toast";
import { logError } from "./firebase.errortracker";

const uploadImage = async (file, userId, imgObj, currentImgs, setIsLoading) => {
  try {
    // Initialize Firebase storage
    const storage = getStorage();
    const newId = generateUuid();
    // Create a reference to the storage location where you want to upload the file
    const storageRef = ref(storage, `${userId}/${newId}`);
    // Upload the file
    const snapshot = await uploadBytes(storageRef, file);
    // Get the download URL directly from the snapshot
    const downloadURL = await getDownloadURL(storageRef);
    console.log("Download URL:", downloadURL);
    // Add image to Firestore
    imgObj.id = newId;
    imgObj.imageUrl = downloadURL;
    await setDoc(doc(db, "storage", newId), imgObj);
    toast.success("Imagen subida exitosamente");
    setIsLoading(false);
    return imgObj;
  } catch (error) {
    console.log("Error:", error);
  }
};

const getImgsByUserId = async (userId) => {
  try {
    const imgCollectionRef = query(
      collection(db, "storage"),
      where("userId", "==", userId)
    );
    const querySnapshot = await getDocs(imgCollectionRef);
    if (!querySnapshot.empty) {
      // Process the data
      const imgs = querySnapshot.docs.map((doc) => doc.data());
      return imgs;
    } else {
      return null;
    }
  } catch (error) {
    logError(`Error fetching remote imgs for ${userId} :  ${error}`);
    console.error("Error fetching remote imgs: ", error);
    throw error;
  }
};

const deleteImgHandler = async (imgId, userId) => {
  try {
    const storage = getStorage();
    const imgRef = doc(db, "storage", imgId);
    const storageRef = ref(storage, `${userId}/${imgId}`);
    await deleteObject(storageRef);
    await deleteDoc(imgRef);
    toast.success(`Imagen eliminada exitosamente`);
    return true;
  } catch (error) {
    toast.error(`Upps.. hubo un error al eliminar la imagen`);
    return false;
  }
};

export { uploadImage, getImgsByUserId, deleteImgHandler };
