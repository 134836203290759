import React, { useState } from "react";
import Spinner from "../../../../UI/Spinner";
import EditAndDelete from "../../../../UI/EditAndDelete";
import toast from "react-hot-toast";
import { deleteImgHandler } from "../../../../../firebase/firebase.storage";

const ImgCard = ({
  url,
  title,
  description,
  setEditImg,
  id,
  imgObj,
  setAllImgs,
  allImgs,
}) => {
  const [imgLoaded, setImgLoaded] = useState(false);

  const removeImgHandler = (id) => {
    let updatedImgs = allImgs.filter((i) => i.id !== id);
    setAllImgs(updatedImgs);
  };

  return (
    <div className="w-[45%] sm:w-[22%] md:w-[25%] lg:w-[15%] flex justify-between flex-col h-fit m-2 order rounded-lg shadow bg-gray-800">
      {url ? (
        <>
          {imgLoaded ? null : (
            <div className="h-[23vh] w-full flex justify-center items-center">
              <Spinner />
            </div>
          )}
          <div className="flex flex-col justify-end">
            <a href={url} target="_blank" className="">
              <img
                className="rounded-t-lg object-cover h-[23vh] w-full"
                src={url}
                alt=""
                style={imgLoaded ? {} : { display: "none" }}
                onLoad={() => setImgLoaded(true)}
              />
            </a>
          </div>
          <div className="flex flex-col">
            <div className="p-0.5">
              <EditAndDelete
                editHandler={() => setEditImg(imgObj)}
                deleteHandler={() => {
                  toast.custom(
                    (t) => (
                      <div
                        className={`
                      ${
                        t.visible == undefined
                          ? "animate-enter"
                          : "animate-leave"
                      } 
                      max-w-xs w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
                      >
                        <div className="flex p-4">
                          <div className="flex items-center">
                            <div className="">
                              <p className="text-sm font-medium text-gray-900">
                                Seguro que quieres eliminar esta imagen?
                              </p>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="flex border-l border-gray-200">
                            <button
                              onClick={() => toast.dismiss(t.id)}
                              className="w-full border border-transparent rounded-none rounded-r-lg p-2 flex items-center justify-center text-sm font-medium text-slate-800 hover:text-slate-900"
                            >
                              Cancelar
                            </button>
                          </div>
                          <div className="flex justify-center">
                            <div className="h-[0.2vh] w-3/4 bg-slate-800"></div>
                          </div>
                          <div className="flex border-l border-gray-200">
                            <button
                              onClick={async () => {
                                let hasBeenDeleted = await deleteImgHandler(
                                  imgObj.id,
                                  imgObj.userId
                                );
                                removeImgHandler(
                                  hasBeenDeleted ? imgObj.id : 0
                                );
                                toast.dismiss(t.id);
                              }}
                              className="w-full border border-transparent rounded-none rounded-r-lg p-2 flex items-center justify-center text-sm font-medium text-slate-800 hover:text-red-600"
                            >
                              Eliminar
                            </button>
                          </div>
                        </div>
                      </div>
                    ),
                    {
                      duration: 7000,
                    }
                  );
                }}
                textColor="text-white"
              />
            </div>
            <div className="px-2 py-1 w-full">
              <h5 className="text-sm font-bold text-ellipsis overflow-hidden text-gray-200">
                {title}
              </h5>
              <p className="text-xs text-ellipsis overflow-hidden text-gray-300">
                {description}
              </p>
            </div>
          </div>
        </>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default ImgCard;
