import React, { useState, useEffect } from "react";
import Navbar from "../../navbar/Navbar";
import Dashboard from "../../Dashboard/Dashboard";
import { useNavigate } from "react-router";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { getAllErrors } from "../../../firebase/firebase.admin";
import List from "../List";
import Spinner from "../../UI/Spinner";

const ErrorsAdmin = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedView, setSelectedView] = useState("dashboard");
  const [userId, setUserId] = useState(null);
  let navigate = useNavigate();
  const user = useSelector((state) => state.user?.userDetails);

  useEffect(() => {
    let getErrors = async () => {
      if (userId) {
        let data = await getAllErrors(userId);
        if (data !== undefined) {
          setData(data);
          console.log(data);
          setFilteredData(data);
          setIsLoading(false);
        }
      }
    };
    getErrors();
  }, [userId]);

  useEffect(() => {
    if (user !== null) {
      if (user.role == "admin") {
        setUserId(user.id);
      } else {
        toast.error("No tiene suficientes permisos");
        navigate("/dashboard");
      }
    }
  }, [user]);

  const dataFilter = (e) => {
    if (e) {
      setFilteredData(e);
    }
  };

  return (
    <div>
      <Navbar>
        <div>
          {!isLoading ? (
            <List userData={filteredData} type="error" />
          ) : (
            <div
              className="flex items-center justify-center"
              style={{ height: "50vh" }}
            >
              <Spinner />
            </div>
          )}
        </div>
      </Navbar>
    </div>
  );
};

export default ErrorsAdmin;
