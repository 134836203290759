import React, { useEffect, useState } from "react";

const CalendarModalControllers = ({ setSelectedCategory, currentCategory }) => {
  const [newCurrentCategory, setNewCurrentCategory] = useState(currentCategory);

  useEffect(() => {
    if (currentCategory) {
      setNewCurrentCategory(currentCategory);
    }
  }, [currentCategory]);

  return (
    <div className="flex items-center" style={{ justifyContent: "flex-start" }}>
      <button
        value="patient"
        className={`px-4 py-2 text-sm font-medium text-gray-900 ${
          newCurrentCategory == "patient" ? "bg-gray-100" : "bg-white"
        } border border-gray-200 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 focus:z-10 rounded-sm`}
        onClick={(e) => setSelectedCategory(e.target.value)}
      >
        Pacientes
      </button>

      <button
        value="event"
        onClick={(e) => setSelectedCategory(e.target.value)}
        className={`px-4 py-2 text-sm font-medium text-gray-900 ${
          newCurrentCategory == "event" ? "bg-gray-100" : "bg-white"
        } border border-gray-200 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 focus:z-1 rounded-sm`}
      >
        Eventos
      </button>
    </div>
  );
};

export default CalendarModalControllers;
