import React, { useEffect, useState } from "react";
import { uploadImage } from "../../../../../firebase/firebase.storage";
import { PhotoIcon } from "@heroicons/react/24/solid";
import Spinner from "../../../../UI/Spinner";
import Compressor from "compressorjs";

const ImageUploader = ({
  userId,
  setAllImgs,
  allImgs,
  data,
  user,
  editImg,
}) => {
  const [image, setImage] = useState(null);
  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    console.log(editImg);
    if (editImg !== modalIsOpen) {
      setModalIsOpen(editImg);
      setImage(editImg);
      setTitle(editImg.title);
      setDescription(editImg.description);
    }
  }, [editImg]);

  const handleChange = async (e) => {
    if (e.target.files[0]) {
      console.log(e.target.files[0]);
      new Compressor(e.target.files[0], {
        quality: 0.6,
        success(result) {
          console.log(result);
          setImage(result);
        },
        error(err) {
          console.log(err.message);
        },
      });
    }
  };

  const handleUpload = async () => {
    console.log(image);
    if (image) {
      setIsLoading(true);
      debugger;
      const imgObj = {
        id: null,
        userId: userId,
        imageUrl: null,
        title: title,
        description: description,
        created: new Date(),
        createdBy: user.id,
        createdByName: user.name,
      };
      if (allImgs === null) {
        allImgs = [];
      }
      let newImg = await uploadImage(
        image,
        userId,
        imgObj,
        allImgs,
        setIsLoading
      );
      let newImgGroup = allImgs.length > 0 ? [...allImgs, newImg] : [newImg];
      setAllImgs(newImgGroup);
      setModalIsOpen(false);
      setImage(null);
    }
  };

  return (
    <>
      <div className="px-5 py-2">
        <div className="flex items-center">
          <div className="m-1">
            <button
              className="block px-2 py-1 w-max text-sm text-white border rounded-lg cursor-pointer focus:outline-none bg-gray-700 border-gray-600 placeholder-gray-400"
              onClick={() => setModalIsOpen(true)}
            >
              Subir imagen
            </button>
          </div>
        </div>
      </div>

      {modalIsOpen && (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none"
            style={{ zIndex: "100" }}
          >
            <div className="bg-white p-2 w-[95%] sm:w-[80%] md:w-[60%] lg:w-2/4 rounded-lg p-3">
              <div className="">
                <div className="border-b border-gray-900/10 pb-4">
                  <div className="">
                    <div className="">
                      <label
                        htmlFor="username"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Nombre de la imagen
                      </label>
                      <div className="mt-2">
                        <div className="flex rounded-md w-full shadow-sm ring-1 ring-inset ring-gray-300">
                          <input
                            type="text"
                            className="block p-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="Ortodoncia 2018"
                            value={title}
                            onChange={(e) => {
                              setTitle(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="mt-2">
                      <label
                        htmlFor="about"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Descripción
                      </label>
                      <div className="mt-2">
                        <textarea
                          id="about"
                          name="about"
                          rows={3}
                          onChange={(e) => {
                            setDescription(e.target.value);
                          }}
                          className="block w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300"
                          value={description}
                        />
                      </div>
                    </div>

                    <div className="col-span-full">
                      <label
                        htmlFor="cover-photo"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Imagen
                      </label>
                      <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                        <div className="text-center">
                          <PhotoIcon
                            className="mx-auto h-12 w-12 text-gray-300"
                            aria-hidden="true"
                          />
                          {image === null && (
                            <div className="mt-4 flex text-sm leading-6 text-gray-600">
                              <label
                                htmlFor="file-upload"
                                className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                              >
                                <span>Upload a file</span>
                                <input
                                  id="file-upload"
                                  name="file-upload"
                                  type="file"
                                  className="sr-only"
                                  onChange={handleChange}
                                />
                              </label>
                              <p className="pl-1">or drag and drop</p>
                            </div>
                          )}

                          {
                            <div>
                              {image?.name ? image?.name : image?.title}
                            </div>
                          }
                          <p className="text-xs leading-5 text-gray-600">
                            PNG, JPG, GIF up to 10MB
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-6 flex items-center justify-end gap-x-6">
                <button
                  type="button"
                  className="text-sm font-semibold leading-6 text-gray-900"
                  onClick={() => {
                    setImage(null);
                    setModalIsOpen(false);
                  }}
                >
                  Cancelar
                </button>

                <button
                  className="rounded-md flex bg-slate-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-slate-500"
                  onClick={handleUpload}
                  disabled={isLoading}
                >
                  {isLoading ? "Guardando.." : "Guardar"}
                  {isLoading && <Spinner size="small" />}
                </button>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      )}
    </>
  );
};

export default ImageUploader;
