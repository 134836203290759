// FeatureFlag.js
import React from "react";
import { useSelector } from "react-redux";

const FeatureFlag = ({ feature, children }) => {
  const userCredentials = useSelector((state) => state.praxis?.praxisDetails);

  if (userCredentials) {
    if (
      feature === "premium" &&
      userCredentials?.currentLicenseType !== "premium"
    ) {
      return (
        <div>
          <div className="flex flex-col items-center absolute top-[55%] left-[40%] text-white z-10">
            <div>Funcionalidad Premium</div>
            <div>Para utilzar esta funcionalidad, necesita una licencia.</div>
          </div>
          <div className="blur-md pointer-events-none">{children}</div>
        </div>
      );
    }
  }

  return <>{children}</>;
};

export default FeatureFlag;
