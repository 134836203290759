import React, { useEffect, useState } from "react";
import Spinner from "../../UI/Spinner";
import PatientRow from "./PatientRow";
import AddPatientModal from "../addPatient/AddPatientModal";
import Filter from "../../filter/Filter";
import { useDispatch, useSelector } from "react-redux";
import { setFilteredPatients } from "../../../store/patients/patients.reducer";

function PatientsTable({ userData }) {
  const [mode, setMode] = useState("darkmode");
  const [filteredData, setFilteredData] = useState(userData);
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();
  const filteredPatients = useSelector(
    (state) => state.patients.filteredPatients
  );

  const filterPatients = (e) => {
    if (e) {
      setFilteredData(e);
      dispatch(setFilteredPatients(e));
    }
  };

  useEffect(() => {
    if (userData) {
      setFilteredData(userData);
      // dispatch(setFilteredPatients(userData));
      console.log(filteredPatients);
    }
  }, [userData]);

  useEffect(() => {
    // if (userData) {
    // setFilteredData(userData);
    // dispatch(setFilteredPatients(userData));
    console.log(mode);
    // }
  }, [mode]);

  return (
    <>
      <div
        className={`w-full h-[91vh] rounded-lg shadow ${
          mode !== "darkmode" ? "bg-white" : "bg-slate-600"
        } `}
      >
        <div className="flex w-full">
          <div
            className={`flex mx-2 items-center ${
              mode === "darkmode" ? "justify-between" : "justify-end"
            } w-full`}
          >
            {mode === "darkmode" && (
              <>
                <div className="">
                  <div
                    className="p-1 flex items-center text-white rounded-md w-12 sm:w-full md:w-full lg-w-full justify-center"
                    style={{ backgroundColor: "#253141" }}
                    onClick={() => setOpenModal(true)}
                  >
                    <div className="px-1 font-bold">+</div>
                    <div className="px-1 hidden w-fit sm:flex text-xs md:flex md:text-xs lg:flex lg:text-sm xl:flex xl:text-sm 2xl:flex 2xl:text-sm">
                      Nuevo Paciente
                    </div>
                  </div>
                </div>

                <Filter
                  userData={userData}
                  filterData={filterPatients}
                  originalPatients={userData}
                />
              </>
            )}

            <div className="flex items-center" title="Negatoscopio">
              <div
                className={`m-1 p-1 flex ${
                  mode === "darkmode" ? "bg-yellow-400" : "bg-cyan-950 mt-3"
                } text-white rounded-md`}
              >
                {
                  // ?
                  <div
                    className="flex"
                    onClick={() =>
                      setMode(mode === "lightmode" ? "darkmode" : "lightmode")
                    }
                  >
                    {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 3v2.25m6.364.386-1.591 1.591M21 12h-2.25m-.386 6.364-1.591-1.591M12 18.75V21m-4.773-4.227-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z"
                      />
                    </svg> */}
                    <svg
                      version="1.0"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512.000000 512.000000"
                      className="w-6 h-6 text-white"
                      fill="none"
                      stroke="currentColor"
                    >
                      {/* <!-- Background rectangle --> */}
                      <rect
                        width="100%"
                        height="45%"
                        fill="#FFFFFF"
                        // className="absolute"
                        y={"25%"}
                      />

                      <g
                        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                        fill="#FFFFFF"
                        stroke="none"
                      >
                        <path
                          d="M1601 4785 c-98 -18 -149 -38 -246 -94 -145 -84 -324 -287 -399 -452
      -35 -76 -74 -218 -82 -294 l-7 -64 -188 -3 c-208 -3 -218 -6 -266 -78 l-23
      -33 0 -1047 0 -1047 22 -33 c13 -18 36 -43 51 -54 28 -20 40 -21 432 -24 l404
      -3 5 -32 c2 -18 9 -78 15 -135 29 -276 140 -628 251 -793 61 -90 173 -198 248
      -237 122 -65 221 -53 305 37 103 110 149 261 167 541 12 190 37 345 82 500
      l33 115 155 0 155 0 26 -91 c40 -136 67 -275 79 -409 6 -66 15 -167 20 -225
      17 -191 71 -339 155 -429 86 -92 184 -104 307 -39 75 39 187 147 248 237 111
      165 222 517 251 793 6 57 13 117 15 135 l5 32 404 3 c392 3 404 4 432 24 15
      11 38 36 50 54 l23 33 0 1047 0 1047 -22 33 c-49 72 -59 75 -267 78 l-187 3
      -13 88 c-44 311 -257 613 -526 746 -132 66 -194 79 -365 80 -180 0 -248 -17
      -435 -110 -279 -139 -431 -139 -710 0 -71 35 -164 74 -205 85 -98 28 -295 35
      -399 15z m374 -175 c39 -12 102 -39 141 -61 176 -95 330 -135 484 -126 127 8
      219 35 375 112 183 90 222 100 385 99 119 0 144 -3 207 -26 185 -66 385 -267
      466 -470 25 -63 57 -196 57 -238 l0 -20 -1530 0 -1530 0 0 20 c0 42 32 175 57
      238 32 80 104 190 173 265 117 128 248 204 400 231 63 11 247 -3 315 -24z
      m-1096 -987 c31 -237 126 -516 243 -714 136 -230 132 -216 148 -639 6 -162 13
      -352 17 -422 l6 -128 -371 0 -372 0 0 1000 0 1000 158 0 158 0 13 -97z m3207
      40 c-28 -243 -123 -507 -264 -739 -102 -167 -110 -201 -122 -469 -5 -115 -14
      -328 -21 -472 l-12 -263 -421 0 -421 0 -43 93 c-87 187 -147 257 -222 257 -76
      0 -135 -69 -222 -257 l-43 -93 -421 0 -421 0 -7 143 c-4 78 -13 291 -21 472
      -16 390 -20 408 -126 586 -38 64 -87 155 -109 201 -73 156 -139 382 -156 536
      l-7 62 1533 0 1533 0 -7 -57z m484 -943 l0 -1000 -372 0 -371 0 6 128 c4 70
      11 260 17 422 16 428 10 403 152 647 116 198 208 473 239 706 l12 97 159 0
      158 0 0 -1000z m-1960 -920 l40 -80 -89 0 -90 0 14 33 c23 54 66 127 76 127 5
      0 27 -36 49 -80z m-375 -257 c-44 -114 -91 -379 -104 -594 -12 -189 -35 -294
      -84 -383 -66 -120 -120 -115 -258 23 -72 73 -94 102 -133 181 -87 181 -166
      471 -181 665 -3 44 -8 90 -11 103 l-4 22 391 0 c368 0 391 -1 384 -17z m1421
      -5 c-3 -13 -8 -59 -11 -103 -15 -194 -94 -484 -181 -664 -38 -78 -61 -109
      -132 -181 -66 -67 -97 -91 -131 -101 -59 -17 -85 -2 -128 77 -49 89 -72 194
      -84 383 -13 215 -60 480 -104 594 -7 16 16 17 384 17 l391 0 -4 -22z"
                        />
                      </g>
                    </svg>
                  </div>
                  // : (
                  //   <div className="flex" onClick={() => setMode("darkmode")}>
                  //     <svg
                  //       xmlns="http://www.w3.org/2000/svg"
                  //       fill="none"
                  //       viewBox="0 0 24 24"
                  //       strokeWidth={1.5}
                  //       stroke="currentColor"
                  //       className="w-6 h-6"
                  //     >
                  //       <path
                  //         strokeLinecap="round"
                  //         strokeLinejoin="round"
                  //         d="M21.752 15.002A9.72 9.72 0 0 1 18 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 0 0 3 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 0 0 9.002-5.998Z"
                  //       />
                  //     </svg>
                  //   </div>
                  // )
                }
              </div>
            </div>
          </div>
        </div>

        {mode === "darkmode" && (
          <div className="overflow-x-auto no-scrollbar shadow-md sm:rounded-lg ml-2 h-[80vh]">
            <table className="relative w-full text-sm text-left rtl:text-right text-gray-500 text-gray-400">
              {userData && (
                <thead className="text-xs text-gray-700 sticky top-0 uppercase bg-gray-50 bg-gray-700 text-gray-400">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-white">
                      Nombre
                    </th>
                    <th scope="col" className="px-6 py-3 text-white">
                      Nacimiento
                    </th>
                    <th scope="col" className="px-6 py-3 text-white">
                      Genero
                    </th>
                    <th scope="col" className="px-6 py-3 text-white">
                      Ultima Visita
                    </th>
                    <th scope="col" className="px-6 py-3 text-white">
                      Telefono
                    </th>
                  </tr>
                </thead>
              )}
              {filteredData && filteredData?.length > 0 ? (
                <tbody className="">
                  {filteredData.map((m, i) => (
                    <PatientRow
                      name={m.personalDetails.name}
                      tel={m.personalDetails.tel}
                      birthdate={m.personalDetails.birthdate}
                      gender={m.personalDetails.gender}
                      visit={m.odontologicPast.lastVisit}
                      id={m.id}
                      mode={mode}
                      url={m.id}
                      payments={m.payments}
                      key={i}
                    />
                  ))}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td
                      className="flex items-center justify-center"
                      style={{ height: "70vh", width: "100vw" }}
                    >
                      {filteredData?.length <= 0 ? (
                        <div className="text-white">
                          Agregá un paciente para visualizarlo aquí
                        </div>
                      ) : (
                        <Spinner />
                      )}
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        )}
      </div>

      {openModal && (
        <AddPatientModal modalState={openModal} modalSetter={setOpenModal} />
      )}
    </>
  );
}

export default PatientsTable;
