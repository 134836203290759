import React from "react";

const NameAvatar = ({ name }) => {
  return (
    <div>
      <span className="font-medium text-gray-600 text-white">
        {name && name?.split(" ").length == 1
          ? name[0]
          : name?.split(" ")[0][0] + name?.split(" ")[1][0]}
      </span>
    </div>
  );
};

export default NameAvatar;
