import { Disclosure } from "@headlessui/react";
import { logout } from "../../firebase/firebase.user";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { removeState } from "../../store/user/user.reducer";
import FeedbackModal from "../feedback/FeedbackModal";
import NameAvatar from "../UI/NameAvatar";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

export default function Navbar({ children }) {
  const [isShowing, setIsShowing] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user?.userDetails);

  const logoutHandler = () => {
    logout();
    dispatch(removeState());
    navigate("/");
  };

  const showUserDetails = () => {
    toast.custom(
      (t) => (
        <div
          className={`${
            t.visible ? "animate-enter" : "animate-leave"
          } max-w-xs bg-white shadow-lg rounded-lg pointer-events-auto flex flex-col ring-1 ring-black ring-opacity-5`}
        >
          <div className="flex-1 p-3">
            <div className="flex items-start">
              <div className="ml-1">
                <p className="text-sm font-medium text-gray-900">
                  Nombre: {user?.name}
                </p>
                <p className="mt-1 text-sm text-gray-500">
                  Consultorio: {user?.praxis}
                </p>
                <p className="mt-1 text-sm text-gray-500">
                  Role:
                  {user?.role === "user" && " Usuario/a"}
                  {user?.role === "owner" && " Dueño/a"}
                  {user?.role === "admin" && " Admin"}
                </p>
              </div>
            </div>
          </div>
          <div className="flex border-l border-gray-200">
            <button
              onClick={() => {
                toast.dismiss(t.id);
                setIsShowing(false);
              }}
              className="w-full border border-transparent rounded-none rounded-r-lg p-3 flex items-center justify-center text-sm font-medium text-slate-800 hover:text-slate-700"
            >
              Cerrar
            </button>
          </div>
        </div>
      ),
      {
        position: "botton-left",
        duration: 4000,
      }
    );

    setTimeout(() => {
      setIsShowing(false);
    }, "4000");
  };

  return (
    <Disclosure as="nav" style={{ backgroundColor: "#253141" }}>
      {({ open }) => (
        <div className="overflow-x-auto no-scrollbar h-100vh">
          <div className="px-2">
            <div className="relative flex h-16 items-center justify-between">
              <div className="flex items-center justify-center sm:items-stretch sm:justify-start sm:w-1/3">
                <div className="flex flex-shrink-0 items-center">
                  <div className="lg:mb-0 lg:ml-[4vw]">
                    <svg
                      version="1.2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 670 107"
                      className="h-[4.5vh]"
                    >
                      <g id="Layer">
                        <g id="Layer">
                          <path
                            id="Layer"
                            className="fill-white"
                            d="m385.5 68l5.9-7c5.3 4.6 10.7 7.2 17.5 7.2 6 0 9.9-2.9 9.9-7v-0.1c0-4-2.3-6.2-12.6-8.5-11.8-2.9-18.5-6.4-18.5-16.6v-0.2c0-9.5 7.9-16.1 19-16.1 8.1 0 14.5 2.4 20.2 7l-5.3 7.4c-5-3.8-10-5.8-15.1-5.8-5.7 0-9 3-9 6.6v0.2c0 4.3 2.5 6.2 13.2 8.7 11.8 2.9 17.8 7.1 17.8 16.3v0.2c0 10.4-8.2 16.6-19.8 16.6-8.5 0-16.6-3-23.2-8.9z"
                          />
                          <path
                            id="Layer"
                            className="fill-white"
                            d="m452.5 29.5h-17.6v-9h45.1v9h-17.7v46.6h-9.8z"
                          />
                          <path
                            id="Layer"
                            className="fill-white"
                            d="m578.1 55c7.7-2.2 13.2-7.7 13.2-16.8v-0.2c0-4.8-1.7-9-4.6-12-3.6-3.5-9.1-5.5-16.1-5.5h-24.7v55.6h9.7v-46.7h14.3c7.2 0 11.5 3.2 11.5 9.2v0.1c0 5.7-4.5 9.3-11.5 9.3h-7.9l19.5 28.1h11.6z"
                          />
                          <path
                            id="Layer"
                            className="fill-white"
                            d="m488.4 76.1h-9.9l24.4-56h9l24.5 56h-10.3l-18.9-44.4z"
                          />
                          <path
                            id="Layer"
                            className="fill-white"
                            d="m603.4 20.5h9.7v55.6h-9.7z"
                          />
                          <path
                            id="Layer"
                            className="fill-white"
                            d="m651.5 20.5h-12l-22.6 23.9 23.7 31.6h12l-24.1-31.6z"
                          />
                        </g>
                        <g id="Layer">
                          <g id="Layer">
                            <path
                              id="Layer"
                              className="fill-white"
                              d="m249.6 20.4v38.4l-29.7-38.4h-11.7l2.7 3.5 16.9 21.9 23.3 30.2h8.2v-55.6z"
                            />
                            <path
                              id="Layer"
                              className="fill-white"
                              d="m210.9 36.1v39.9h9.6v-27.4z"
                            />
                          </g>
                          <path
                            id="Layer"
                            fillRule="evenodd"
                            className="fill-white"
                            d="m43.9 76.9q-6.5 0-11.8-2.3-5.3-2.2-9.1-6.1-3.8-3.9-6-9.1-2.1-5.2-2.1-11.1v-0.1q0-5.9 2.1-11.1 2.2-5.2 6.1-9.1 3.9-4 9.2-6.3 5.3-2.3 11.7-2.3 6.5 0 11.8 2.3 5.3 2.2 9.1 6.1 3.8 3.9 6 9.1 2.1 5.2 2.1 11.1v0.2q0 5.8-2.1 11-2.2 5.2-6.1 9.2-3.9 3.9-9.2 6.2-5.3 2.3-11.7 2.3zm0.1-9q4.1 0 7.6-1.5 3.4-1.6 5.9-4.2 2.4-2.7 3.8-6.3 1.4-3.5 1.4-7.6v-0.1q0-4.1-1.4-7.7-1.4-3.6-3.9-6.3-2.5-2.6-5.9-4.2-3.5-1.6-7.6-1.6-4.1 0-7.6 1.5-3.4 1.6-5.9 4.3-2.4 2.6-3.8 6.2-1.4 3.6-1.4 7.6v0.2q0 4 1.4 7.6 1.4 3.6 3.9 6.3 2.5 2.7 5.9 4.2 3.5 1.6 7.6 1.6z"
                          />
                          <path
                            id="Layer"
                            fillRule="evenodd"
                            className="fill-white"
                            d="m171.1 76.9q-6.5 0-11.8-2.3-5.3-2.2-9.1-6.1-3.8-3.9-6-9.1-2.1-5.2-2.1-11.1v-0.1q0-5.9 2.1-11.1 2.2-5.2 6.1-9.1 3.9-4 9.2-6.3 5.3-2.3 11.7-2.3 6.5 0 11.8 2.3 5.3 2.2 9.1 6.1 3.8 3.9 6 9.1 2.1 5.2 2.1 11.1v0.2q0 5.8-2.1 11-2.2 5.2-6.1 9.2-3.8 3.9-9.2 6.2-5.3 2.3-11.7 2.3zm0.1-9q4.2 0 7.6-1.5 3.4-1.6 5.9-4.2 2.4-2.7 3.8-6.3 1.4-3.5 1.4-7.6v-0.1q0-4.1-1.4-7.7-1.4-3.6-3.9-6.3-2.4-2.6-5.9-4.2-3.5-1.6-7.6-1.6-4.1 0-7.6 1.5-3.4 1.6-5.9 4.3-2.4 2.6-3.8 6.2-1.4 3.6-1.4 7.6v0.2q0 4 1.4 7.6 1.4 3.6 3.9 6.3 2.5 2.7 5.9 4.2 3.5 1.6 7.6 1.6z"
                          />
                          <path
                            id="Layer"
                            className="fill-white"
                            d="m286.1 29.4h-17.7v-9h45.1v9h-17.6v46.6h-9.8z"
                          />
                          <path
                            id="Layer"
                            fillRule="evenodd"
                            className="fill-white"
                            d="m349.2 76.9q-6.5 0-11.8-2.3-5.3-2.2-9.1-6.1-3.8-3.9-6-9.1-2.1-5.2-2.1-11.1v-0.1q0-5.9 2.1-11.1 2.2-5.2 6.1-9.1 3.9-4 9.2-6.3 5.3-2.3 11.7-2.3 6.5 0 11.8 2.3 5.3 2.2 9.1 6.1 3.8 3.9 6 9.1 2.1 5.2 2.1 11.1v0.2q0 5.8-2.1 11-2.2 5.2-6.1 9.2-3.8 3.9-9.2 6.2-5.3 2.3-11.7 2.3zm0.1-9q4.2 0 7.6-1.5 3.4-1.6 5.9-4.2 2.4-2.7 3.8-6.3 1.4-3.5 1.4-7.6v-0.1q0-4.1-1.4-7.7-1.4-3.6-3.9-6.3-2.4-2.6-5.9-4.2-3.5-1.6-7.6-1.6-4.1 0-7.6 1.5-3.4 1.6-5.9 4.3-2.4 2.6-3.8 6.2-1.4 3.6-1.4 7.6v0.2q0 4 1.4 7.6 1.4 3.6 3.9 6.3 2.5 2.7 5.9 4.2 3.5 1.6 7.6 1.6z"
                          />
                          <path
                            id="Layer"
                            className="fill-white"
                            d="m131.8 37.1q-2.2-5.1-6.1-8.8-3.9-3.7-9.4-5.8-5.4-2.1-11.9-2.1h-20.7v8.8h9.7 10.9q4.4 0 8 1.4 3.5 1.4 6 4 2.5 2.5 3.9 6 1.4 3.5 1.4 7.5v0.2q0 4.1-1.4 7.5-1.4 3.5-3.9 6-2.5 2.5-6 3.9-3.6 1.4-8 1.4h-10.9v-23.2h-9.7v32h20.7q6.5 0 11.9-2.1 5.5-2.1 9.4-5.9 3.9-3.7 6.1-8.8 2.1-5.1 2.1-11v-0.1q0-5.9-2.1-10.9z"
                          />
                        </g>
                      </g>
                    </svg>
                  </div>
                </div>
              </div>

              <div className="flex">
                {user && user?.role !== "admin" && <FeedbackModal />}

                <div className="flex">
                  <button
                    className="inline-flex items-center justify-center rounded-md p-2 text-white hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={logoutHandler}
                  >
                    Cerrar sesión
                  </button>
                </div>
              </div>
            </div>
          </div>
          {user && user?.role === "admin" ? (
            <header
              className="bg-slate-800 shadow pl-1"
              style={{ backgroundColor: "#253141" }}
            >
              <div className="flex lg:justify-start lg:flex-row flex-col">
                <div className="lg:h-[91vh] max-w-7xl rounded-t-lg">
                  <div className="lg:mt-10 flex lg:flex-col flex-row w-full">
                    <Link className="py-4 px-2" to={"/errors"}>
                      <div className="flex flex-col justify-center items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="white"
                          className="w-6 h-6 lg:flex hidden"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
                          />
                        </svg>

                        <h1 className="lg:text-sm font-bold text-white tracking-tight text-gray-900">
                          Errores
                        </h1>
                      </div>
                    </Link>
                    <Link className="py-4 px-2" to={"/admin"}>
                      <div className="flex flex-col justify-center items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="white"
                          className="w-6 h-6 lg:flex hidden"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z"
                          />
                        </svg>

                        <h1 className="lg:text-sm font-bold text-white tracking-tight text-gray-900">
                          Admin
                        </h1>
                      </div>
                    </Link>
                    <Link className="py-4 px-2" to={"/privateRegister"}>
                      <div className="flex flex-col justify-center items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="white"
                          className="w-6 h-6 lg:flex hidden"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                          />
                        </svg>

                        <h1 className="lg:text-sm font-bold text-white tracking-tight text-gray-900">
                          Registrar
                        </h1>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="w-full overflow-x-auto no-scrollbar">
                  {children}
                </div>
              </div>
            </header>
          ) : (
            <header
              className="bg-slate-800 shadow"
              style={{ backgroundColor: "#253141" }}
            >
              <div className="flex lg:justify-start lg:flex-row flex-col">
                <div className="lg:h-[91vh] max-w-7xl rounded-t-lg lg:w-[4vw] xl:w-[4vw] 2xl:w-[4vw] flex lg:flex-col xl:flex-col 2xl:flex-col justify-between items-center">
                  <div className="flex lg:flex-col flex-row w-full">
                    <Link className="py-4 px-2" to={"/calendario"}>
                      <div className="flex flex-col justify-center items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="white"
                          className="w-8 h-8 lg:flex hidden"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
                          />
                        </svg>

                        <div className="text-xs lg:hidden font-bold text-white tracking-tight text-gray-900">
                          Citas
                        </div>
                      </div>
                    </Link>
                    <Link className="py-4 px-2" to={"/dashboard"}>
                      <div className="flex flex-col justify-center items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="white"
                          className="w-6 h-6 lg:flex hidden"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z"
                          />
                        </svg>

                        <div className="text-xs lg:hidden font-bold text-white tracking-tight text-gray-900">
                          Pacientes
                        </div>
                      </div>
                    </Link>
                    <Link className="py-4 px-2" to={"/finanzas"}>
                      <div className="flex flex-col justify-center items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="white"
                          className="w-6 h-6 lg:flex hidden"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                          />
                        </svg>

                        <div className="text-xs lg:hidden font-bold text-white tracking-tight text-gray-900">
                          Finanzas
                        </div>
                      </div>
                    </Link>
                  </div>
                  <Link
                    className="flex p-4 justify-center"
                    // onMouseEnter={() => {
                    //   setIsShowing(true);
                    //   showUserDetails();
                    // }}
                    // disabled={isShowing}
                    to={"/ajustes"}
                  >
                    <div
                      style={{
                        backgroundColor: "rgb(113 127 144)",
                        opacity: 0.7,
                      }}
                      className="relative inline-flex items-center justify-center w-8 h-8 rounded-full overflow-hidden bg-gray-100 rounded-full bg-gray-600"
                    >
                      {user && (
                        <NameAvatar name={user.name + " " + user.lastname} />
                      )}
                    </div>
                  </Link>
                </div>
                <div className="w-full overflow-x-auto no-scrollbar rounded-lg">
                  {children}
                </div>
              </div>
            </header>
          )}

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pt-2 pb-3"></div>
          </Disclosure.Panel>
        </div>
      )}
    </Disclosure>
  );
}
