import React, { useEffect, useState } from "react";

const Filter = ({ userData, filterData, originalPatients, isImgs }) => {
  const [filter, setFilter] = useState("");

  useEffect(() => {
    if (userData) {
      if (filter !== "" && filter !== null) {
        if (isImgs) {
          filterData(
            userData.filter((e) => {
              if (e?.title !== undefined && e?.title.length > 0)
                return e?.title.toLowerCase().includes(filter.toLowerCase());
            })
          );
        } else {
          filterData(
            userData.filter((e) => {
              if (
                e?.personalDetails?.name !== undefined &&
                e?.personalDetails?.name.length > 0
              )
                return e?.personalDetails?.name
                  .toLowerCase()
                  .includes(filter.toLowerCase());
            })
          );
        }
      } else {
        filterData(originalPatients);
      }
    }
  }, [filter]);

  return (
    <div className="flex items-start px-4 sm:px-0 md:px-0 lg:px-0 w-[62vw] sm:w-[75vw] md:w-[75vw] lg:w-[75vw]">
      <div className="bg-white w-full rounded-lg p-2 m-2 shadow text-gray placeholder-gray-600  m-1 text-base transition duration-500 ease-in-out transform rounded-lg ring-offset-current ring-offset-2 ring-gray-400 w-40vw">
        <input
          onChange={(e) => setFilter(e.target.value)}
          value={filter}
          className="w-full"
          placeholder="Buscar.."
        />
      </div>
    </div>
  );
};

export default Filter;
