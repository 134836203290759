import React, { useState, useRef, useEffect } from "react";
import { Transition } from "@headlessui/react";
import WizardControllers from "./WizardControllers";

function WizardUI(props) {
  const [currentStep, setCurrentStep] = useState(0);
  const [moving, setMoving] = useState("right");

  const [steps, setSteps] = useState([
    { name: "Datos Personales", href: "#", status: "current" },
    { name: "Detalles Medicos", href: "#", status: "upcoming" },
    { name: "Antecedentes Odontologicos", href: "#", status: "upcoming" },
  ]);

  const prevStep = () => {
    setMoving("left");
    setSteps((old) =>
      old.map((v, i) => {
        if (i === currentStep) {
          v.status = "upcoming";
        } else if (i === currentStep - 1) {
          v.status = "current";
        }
        return v;
      })
    );
    setCurrentStep(currentStep - 1);
    return false;
  };

  const nextStep = async () => {
    setMoving("right");

    if (props.canContinue) {
      setSteps((old) =>
        old.map((v, i) => {
          if (i === currentStep) {
            v.status = "complete";
          } else if (i === currentStep + 1) {
            v.status = "current";
          }
          return v;
        })
      );
      setCurrentStep(currentStep + 1);
    } else {
      return false;
    }
  };

  const wrapper = useRef(null);
  const [wrapperWidth, setWrapperWidth] = useState(1);

  useEffect(() => {
    console.log(props);
    function handleResize() {
      if (wrapper.current !== null) {
        setWrapperWidth(wrapper.current.offsetWidth);
      }
    }
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (currentStep + 1 == steps.length) {
      props.saveSetter(true);
    } else {
      props.saveSetter(false);
    }
  }, [currentStep]);

  return (
    <div className="bg-white flex w-full">
      <div className="flex-1 flex flex-col">
        <WizardControllers
          steps={steps}
          prevStep={prevStep}
          nextStep={nextStep}
          currentStep={currentStep}
          canContinue={props.canContinue}
        >
          <div
            className="flex items-start overflow-hidden sm:w-full"
            ref={wrapper}
          >
            <div className="flex flex-nowrap ">
              {props.children &&
                props.children.map((e, i) => {
                  return (
                    <Transition
                      appear={false}
                      unmount={false}
                      show={currentStep === i}
                      enter="transform transition ease-in-out duration-500"
                      enterFrom={
                        moving === "right"
                          ? `translate-x-96 opacity-0`
                          : `-translate-x-96 opacity-0`
                      }
                      enterTo={`translate-x-0 opacity-100`}
                      leave="transform transition ease-in-out duration-500 "
                      leaveFrom={`translate-x-0 opacity-100`}
                      leaveTo={
                        moving === "right"
                          ? `-translate-x-96 opacity-0`
                          : `translate-x-96 opacity-0`
                      }
                      className="bg-blue-200 w-0 overflow-visible"
                      as="div"
                    >
                      <div style={{ width: `${wrapperWidth}px` }}>
                        {e.props.children}
                      </div>
                    </Transition>
                  );
                })}
            </div>
          </div>
        </WizardControllers>
      </div>
    </div>
  );
}

export default WizardUI;
