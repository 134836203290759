import React, { useEffect, useState } from "react";
import { calculateAge } from "../../../../utils/calculateAge";
import NameAvatar from "../../../UI/NameAvatar";
import { useSelector } from "react-redux";
import { getPraxisUsers } from "../../../../firebase/firebase.praxis";
import { formatUserRole } from "../../../../utils/formatUserRole";

const PraxisUsers = ({ stats, praxis }) => {
  const [showMore, setShowMore] = useState(true);
  const [praxisDetails, setPraxisDetails] = useState(null);
  const [praxisUsers, setPraxisUsers] = useState(null);
  //   let users = getPraxisUsers(praxis?.users);

  const getUsers = async () => {
    let users = await getPraxisUsers(praxis?.users);
    setPraxisUsers(users);
    return users;
  };

  useEffect(() => {
    if (praxis) {
      getUsers();
    }
  }, [praxis]);

  useEffect(() => {
    if (praxis) {
      setPraxisDetails(praxis);
    }
  }, [praxis]);

  return (
    <div className="mx-auto m-2">
      <div className="col-span-5 xl:col-span-2">
        <div className="rounded-md border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
          <div className="border-b border-stroke py-4 px-7 dark:border-strokedark">
            <h3 className="font-medium text-black">Usuarios</h3>
          </div>
          <div className="p-7 overflow-scroll h-20vh">
            {praxisUsers &&
              praxisUsers.map((e) => {
                let name = `${e?.name} ${e?.lastname}`;
                return (
                  <div className="flex justify-between">
                    <div className="mb-4 flex items-center gap-3 w-full">
                      <div className="rounded-full">
                        <div
                          className="w-7 h-7 bg-gray-600 rounded-full flex items-center justify-center relative overflow-hidden"
                          style={{
                            backgroundColor: "rgb(37, 49, 65)",
                            opacity: 0.7,
                          }}
                        >
                          <NameAvatar name={name} />
                        </div>
                      </div>
                      <div className="flex justify-between w-full">
                        <span className="mb-1.5 text-black">{name}</span>
                        <span className="mb-1.5 text-black">
                          {formatUserRole(e.role)}
                        </span>
                        {/* <span className="flex gap-2.5">
                    <button className="text-sm hover:text-primary">
                      Eliminar
                    </button>
                    <button className="text-sm hover:text-primary">
                      Actualizar
                    </button>
                  </span> */}
                      </div>
                    </div>
                    <div>
                      {/* {showMore && (
                  <div className="flex md:flex-row space-y-2 md:space-y-0 w-full md:w-auto">
                    <div className="flex flex-col space-y-2 w-full border-t md:border-none pt-4 md:pt-0">
                      <div className="flex items-center text-sm text-gray-800">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-4 h-4 mr-2"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                          />
                        </svg>
                        <span className="font-semibold">Licencia:</span>
                        <span className="ml-2 text-deep-purple-accent-400 text-nowrap">
                          {praxisDetails?.currentLicenseType?.toUpperCase()}
                        </span>
                      </div>
                      <div className="flex items-center text-sm text-gray-800">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-4 h-4 mr-2"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M10.5 1.5H8.25A2.25 2.25 0 0 0 6 3.75v16.5a2.25 2.25 0 0 0 2.25 2.25h7.5A2.25 2.25 0 0 0 18 20.25V3.75a2.25 2.25 0 0 0-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"
                          />
                        </svg>
                        <span className="font-semibold">Telefono:</span>
                        <span className="ml-2 text-deep-purple-accent-400">
                          {stats?.personalDetails?.tel}
                        </span>
                      </div>
                      <div className="flex items-center text-sm text-gray-800">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-4 h-4 mr-2"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M2.25 13.5h3.86a2.25 2.25 0 0 1 2.012 1.244l.256.512a2.25 2.25 0 0 0 2.013 1.244h3.218a2.25 2.25 0 0 0 2.013-1.244l.256-.512a2.25 2.25 0 0 1 2.013-1.244h3.859m-19.5.338V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 0 0-2.15-1.588H6.911a2.25 2.25 0 0 0-2.15 1.588L2.35 13.177a2.25 2.25 0 0 0-.1.661Z"
                          />
                        </svg>
                        <span className="font-semibold">Email:</span>
                        <span className="ml-2 text-deep-purple-accent-400 truncate w-[70%]">
                          {stats?.personalDetails?.email}
                        </span>
                      </div>
                    </div>
                  </div>
                )} */}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PraxisUsers;
