import {
  add,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  getDay,
  isSameMonth,
  isToday,
  isSameDay,
  parse,
  startOfToday,
  startOfWeek,
} from "date-fns";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { useState } from "react";

function Calendar({ calendarRef }) {
  const today = startOfToday();
  const days = ["D", "L", "M", "M", "J", "V", "S"];
  const colStartClasses = [
    "",
    "col-start-2",
    "col-start-3",
    "col-start-4",
    "col-start-5",
    "col-start-6",
    "col-start-7",
  ];

  const capitalizeFirstLetter = (query) => {
    return query.charAt(0).toUpperCase() + query.substring(1);
  };

  const [currMonth, setCurrMonth] = useState(() => format(today, "MMM-yyyy"));
  let firstDayOfMonth = parse(currMonth, "MMM-yyyy", new Date());

  const daysInMonth = eachDayOfInterval({
    start: startOfWeek(firstDayOfMonth),
    end: endOfWeek(endOfMonth(firstDayOfMonth)),
  });

  const getPrevMonth = (event) => {
    event.preventDefault();
    const firstDayOfPrevMonth = add(firstDayOfMonth, { months: -1 });
    setCurrMonth(format(firstDayOfPrevMonth, "MMM-yyyy"));
    calendarRef?.current?.scheduler?.handleGotoDay(firstDayOfPrevMonth);
    calendarRef?.current?.scheduler?.handleState("month", "view");
  };

  const getNextMonth = (event) => {
    event.preventDefault();
    const firstDayOfNextMonth = add(firstDayOfMonth, { months: 1 });
    setCurrMonth(format(firstDayOfNextMonth, "MMM-yyyy"));
    calendarRef?.current?.scheduler?.handleGotoDay(firstDayOfNextMonth);
    calendarRef?.current?.scheduler?.handleState("month", "view");
  };

  return (
    <div className="hidden sm:hidden md:flex lg:flex">
      <div className="">
        <div className="p-2">
          <div
            className="flex items-center justify-evenly gap-6 sm:gap-12 p-3 rounded-t-lg"
            style={{ backgroundColor: "#253141" }}
          >
            <ChevronLeftIcon
              className="w-6 h-6 cursor-pointer text-white"
              onClick={getPrevMonth}
            />
            <p className="font-semibold w-max flex text-sm text-white">
              {format(firstDayOfMonth, "MMMM yyyy")}
            </p>
            <ChevronRightIcon
              className="w-6 h-6 cursor-pointer text-white"
              onClick={getNextMonth}
            />
          </div>
        </div>
        {/* <div className="border border-gray-200"> */}
        {/* <hr className="my-6" /> */}
        <div className="grid grid-cols-7 place-items-center">
          {days.map((day, idx) => {
            return (
              <div key={idx} className="font-semibold">
                {capitalizeFirstLetter(day)}
              </div>
            );
          })}
        </div>
        <div className="grid grid-cols-7 mt-4 place-items-center">
          {daysInMonth.map((day, idx) => {
            return (
              <div
                key={idx}
                className={colStartClasses[getDay(day)]}
                onClick={() =>
                  calendarRef?.current?.scheduler?.handleGotoDay(day)
                }
              >
                <p
                  className={`cursor-pointer flex items-center justify-center h-[3.5vh] w-[2vw] rounded-full  hover:text-white ${
                    isSameMonth(day, today) ? "text-gray-900" : "text-gray-400"
                  } ${!isToday(day) && "hover:bg-blue-500"} ${
                    isToday(day) &&
                    "underline underline-offset-4 text-red-500 hover:bg-red-500"
                  } ${
                    isSameDay(
                      day,
                      calendarRef?.current?.scheduler?.selectedDate
                    ) && "bg-blue-500 text-white"
                  }`}
                >
                  {format(day, "d")}
                </p>
              </div>
            );
          })}
        </div>
        {/* </div> */}
      </div>
    </div>
  );
}

export default Calendar;
