import React, { useEffect, useState } from "react";
import Navbar from "../navbar/Navbar";
import Spinner from "../UI/Spinner";
import { getClientById } from "../../firebase/firebase.admin";
import { useParams } from "react-router-dom";
import { setClientById } from "../../store/clients/clients.reducer";
import { useDispatch, useSelector } from "react-redux";

const UserProfile = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [clientData, setClientData] = useState(null);
  const [clientId, setClientId] = useState(null);
  const params = useParams();
  const dispatch = useDispatch();
  const allClients = useSelector((state) => state.clients?.allClients);
  const clientDetails = useSelector((state) => state.clients?.clientDetails);

  useEffect(() => {
    setIsLoading(true);
    if (params.id !== clientId) {
      setClientId(params.id);
      if (allClients) {
        let selectedUser = allClients.find((e) => e.id == params.id);
        setClientData(selectedUser);
        setIsLoading(false);
      }

      if (!allClients && !clientDetails) {
        let getUser = async (params) => {
          let selectedUser = await getClientById(params);
          setClientData(selectedUser);
          dispatch(setClientById(selectedUser));
          setIsLoading(false);
        };
        getUser(params.id);
      }
    }
  }, []);

  return (
    <div>
      <Navbar>
        <main className="bg-slate-600">
          <div className="mx-auto max-w-7xl py-6 sm:px-6 md:px-6 lg:px-6">
            {/* Replace with your content */}
            <div className="px-4 sm:px-0">
              <div
                className="bg-white rounded-lg shadow"
                style={{ height: "84vh", overflow: "scroll" }}
              >
                {!isLoading && clientData ? (
                  <div className="flex w-90vw">
                    <div className="p-6 bg-white w-90vw h-60vh flex justify-around z-40">
                      <div className="w-1/2">
                        <h2 className="font-bold text-2xl">User</h2>
                        <div>
                          <div>
                            <h5 className="mb-2 tracking-tight text-gray-900">
                              {clientData.name} {clientData.lastname}
                            </h5>
                          </div>
                          <p className="mb-3 font-normal text-gray-700">
                            Tel: {clientData.phone}
                          </p>
                          <p className="mb-3 font-normal text-gray-700">
                            Role: {clientData.role}
                          </p>
                          {clientData?.praxis && (
                            <>
                              <p className="mb-3 font-normal text-gray-700">
                                Pagado:{" "}
                                {clientData?.praxis?.payment.paid ? "Si" : "No"}
                              </p>
                              <p className="mb-3 font-normal text-gray-700">
                                Inicio de Membrecia:{" "}
                                {clientData?.praxis?.payment
                                  ?.membershipStart !== null
                                  ? Date(
                                      clientData?.praxis?.payment
                                        ?.membershipStart
                                    ).split("T")[0]
                                  : "DEMO"}
                              </p>{" "}
                            </>
                          )}
                        </div>
                      </div>
                      <div className="w-1/2 flex justify-center">
                        {clientData?.praxis && (
                          <div>
                            <h2 className="font-bold text-2xl">Praxis</h2>
                            <div>
                              <h5 className="mb-2 tracking-tight text-gray-900">
                                {clientData?.praxis?.praxisName}
                              </h5>
                            </div>
                            <p className="mb-3 font-normal text-gray-700">
                              Inicio:{" "}
                              {
                                new Date(clientData?.praxis?.createdAt)
                                  .toISOString()
                                  .split("T")[0]
                              }
                            </p>
                            <p className="mb-3 font-normal text-gray-700">
                              Telefono: {clientData?.praxis?.praxisPhone}
                            </p>
                            <p className="mb-3 font-normal text-gray-700">
                              Pagado:{" "}
                              {clientData?.praxis?.payment.paid ? "Si" : "No"}
                            </p>
                            {clientData?.praxis?.payment.paid && (
                              <p className="mb-3 font-normal text-gray-700">
                                Costo de Membresia:{" "}
                                {clientData?.praxis?.payment.amount}
                              </p>
                            )}
                          </div>
                        )}

                        {/* {user?.praxis?.payment?.paid && (
                <p className="mb-3 font-normal text-gray-700">
                  Inicio de Licensia: {user?.praxis?.payment?.membershipStart}
                </p>
              )} */}
                        {/* <ReminderButton tel={tel} name={name} type="recordatorio" /> */}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="flex items-center justify-center"
                    style={{ height: "50vh" }}
                  >
                    <Spinner />
                  </div>
                )}
              </div>
              {/* /End replace */}
            </div>
          </div>
        </main>
      </Navbar>
    </div>
  );
};

export default UserProfile;
