import { configureStore } from "@reduxjs/toolkit";
import user from "./user/user.reducer";
import clients from "./clients/clients.reducer";
import patients from "./patients/patients.reducer";
import praxis from "./praxis/praxis.reducer";

export default configureStore({
  reducer: {
    user: user,
    clients: clients,
    patients: patients,
    praxis: praxis,
  },
});
