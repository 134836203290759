import React, { useState, useEffect } from "react";
import { getData } from "../../firebase/firebase.patient";
import { useParams } from "react-router";
import Calendar from "./Calendar";
import { useSelector } from "react-redux";
import Navbar from "../navbar/Navbar";

const CalendarHome = () => {
  const [data, setData] = useState(null);
  const [praxisId, setPraxisId] = useState(null);
  let params = useParams();
  const localPraxis = useSelector((state) => state.user?.userDetails);

  useEffect(() => {
    let getNewData = async () => {
      if (praxisId) {
        let data = await getData(praxisId);
        if (data !== undefined) {
          setData(data);
        }
      }
    };
    getNewData();
  }, [praxisId]);

  useEffect(() => {
    if (localPraxis && praxisId == null) setPraxisId(localPraxis.praxisId);
  }, [localPraxis]);

  return (
    <>
      <Navbar>
        <Calendar userData={data} />
      </Navbar>
    </>
  );
};

export default CalendarHome;
