import React, { useState } from "react";
import Dropdown from "../UI/Dropdown";

export default function CalendarModal({ state, stateSetter, data }) {
  const [name, setName] = useState("");
  const [itRepits, setItRepits] = useState(true);
  const [howOften, setHowOfter] = useState("Nunca");

  //   {
  //     "event_id": null,
  //     "title": "ABC",
  //     "start": "2023-11-21T12:00:00.000Z",
  //     "end": "2023-11-21T13:00:00.000Z",
  //     "user_id": ""
  // }
  let formStyle = `text-black placeholder-gray-300 w-full px-4 py-2.5 mt-2 text-base transition duration-500 ease-in-out transform border-transparent rounded-lg bg-gray-100 ring-offset-current ring-offset-2 ring-gray-400`;
  return (
    <>
      {state ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none"
            style={{ zIndex: "10000" }}
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-3xl font-semibold">
                    Restringir Horarios
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => stateSetter(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                <div className="px-5 pb-5">
                  <div>
                    <label>Nombre del Evento</label>
                    <input
                      onChange={(e) => setName(e.target.value)}
                      defaultValue={name}
                      className={formStyle}
                      //   disabled={!editModeState}
                    />
                  </div>

                  <div>
                    <Dropdown
                      data={[
                        // { title: "Todos los dias", id: 1 },
                        { title: "Lunes a Viernes", id: 2 },
                        { title: "Sabados y Domingos", id: 3 },
                        { title: "Todos los lunes", id: 4 },
                        { title: "Todos los Martes", id: 5 },
                        { title: "Todos los Miercoles", id: 6 },
                        { title: "Todos los Jueves", id: 7 },
                        { title: "Todos los Viernes", id: 8 },
                        { title: "Todos los Sabados", id: 9 },
                        { title: "Nunca", id: 10 },
                      ]}
                      title="Se repite?"
                      currentValueSetter={setHowOfter}
                      //   disabled={!editModeState}
                      defaultValue={howOften}
                      expectedValue="id"
                    />
                  </div>
                </div>

                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => stateSetter(false)}
                  >
                    Close
                  </button>
                  <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => stateSetter(false)}
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
