import { createSlice } from "@reduxjs/toolkit";

export const praxis = createSlice({
  name: "praxis",
  initialState: {
    praxisDetails: null,
  },
  reducers: {
    setPraxisDetails: (state, action) => {
      state.praxisDetails = action.payload;
    },
  },
});

export const { setPraxisDetails } = praxis.actions;

export default praxis.reducer;
