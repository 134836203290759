const countries = [
  {
    value: "AW",
    label: "🇦🇼 Aruba",
  },
  {
    value: "AF",
    label: "🇦🇫 Afganistán",
  },
  {
    value: "AO",
    label: "🇦🇴 Angola",
  },
  {
    value: "AI",
    label: "🇦🇮 Anguila",
  },
  {
    value: "AX",
    label: "🇦🇽 Islas Åland",
  },
  {
    value: "AL",
    label: "🇦🇱 Albania",
  },
  {
    value: "AD",
    label: "🇦🇩 Andorra",
  },
  {
    value: "AE",
    label: "🇦🇪 Emiratos Árabes Unidos",
  },
  {
    value: "AR",
    label: "🇦🇷 Argentina",
  },
  {
    value: "AM",
    label: "🇦🇲 Armenia",
  },
  {
    value: "AS",
    label: "🇦🇸 Samoa Americana",
  },
  {
    value: "AQ",
    label: "🇦🇶 Antártida",
  },
  {
    value: "TF",
    label: "🇹🇫 Tierras Australes y Antárticas Francesas",
  },
  {
    value: "AG",
    label: "🇦🇬 Antigua y Barbuda",
  },
  {
    value: "AU",
    label: "🇦🇺 Australia",
  },
  {
    value: "AT",
    label: "🇦🇹 Austria",
  },
  {
    value: "AZ",
    label: "🇦🇿 Azerbaiyán",
  },
  {
    value: "BI",
    label: "🇧🇮 Burundi",
  },
  {
    value: "BE",
    label: "🇧🇪 Bélgica",
  },
  {
    value: "BJ",
    label: "🇧🇯 Benín",
  },
  {
    value: "BF",
    label: "🇧🇫 Burkina Faso",
  },
  {
    value: "BD",
    label: "🇧🇩 Bangladesh",
  },
  {
    value: "BG",
    label: "🇧🇬 Bulgaria",
  },
  {
    value: "BH",
    label: "🇧🇭 Baréin",
  },
  {
    value: "BS",
    label: "🇧🇸 Bahamas",
  },
  {
    value: "BA",
    label: "🇧🇦 Bosnia y Herzegovina",
  },
  {
    value: "BL",
    label: "🇧🇱 San Bartolomé",
  },
  {
    value: "SH",
    label: "🇸🇭 Santa Elena, Ascensión y Tristán de Acuña",
  },
  {
    value: "BY",
    label: "🇧🇾 Bielorrusia",
  },
  {
    value: "BZ",
    label: "🇧🇿 Belice",
  },
  {
    value: "BM",
    label: "🇧🇲 Bermudas",
  },
  {
    value: "BO",
    label: "🇧🇴 Bolivia",
  },
  {
    value: "BQ",
    label: "Caribe neerlandés",
  },
  {
    value: "BR",
    label: "🇧🇷 Brasil",
  },
  {
    value: "BB",
    label: "🇧🇧 Barbados",
  },
  {
    value: "BN",
    label: "🇧🇳 Brunéi",
  },
  {
    value: "BT",
    label: "🇧🇹 Bután",
  },
  {
    value: "BV",
    label: "🇧🇻 Isla Bouvet",
  },
  {
    value: "BW",
    label: "🇧🇼 Botsuana",
  },
  {
    value: "CF",
    label: "🇨🇫 República Centroafricana",
  },
  {
    value: "CA",
    label: "🇨🇦 Canadá",
  },
  {
    value: "CC",
    label: "🇨🇨 Islas Cocos (Keeling)",
  },
  {
    value: "CH",
    label: "🇨🇭 Suiza",
  },
  {
    value: "CL",
    label: "🇨🇱 Chile",
  },
  {
    value: "CN",
    label: "🇨🇳 China",
  },
  {
    value: "CI",
    label: "🇨🇮 Costa de Marfil",
  },
  {
    value: "CM",
    label: "🇨🇲 Camerún",
  },
  {
    value: "CD",
    label: "🇨🇩 República Democrática del Congo",
  },
  {
    value: "CG",
    label: "🇨🇬 Congo",
  },
  {
    value: "CK",
    label: "🇨🇰 Islas Cook",
  },
  {
    value: "CO",
    label: "🇨🇴 Colombia",
  },
  {
    value: "KM",
    label: "🇰🇲 Comoras",
  },
  {
    value: "CV",
    label: "🇨🇻 Cabo Verde",
  },
  {
    value: "CR",
    label: "🇨🇷 Costa Rica",
  },
  {
    value: "CU",
    label: "🇨🇺 Cuba",
  },
  {
    value: "CW",
    label: "🇨🇼 Curazao",
  },
  {
    value: "CX",
    label: "🇨🇽 Isla de Navidad",
  },
  {
    value: "KY",
    label: "🇰🇾 Islas Caimán",
  },
  {
    value: "CY",
    label: "🇨🇾 Chipre",
  },
  {
    value: "CZ",
    label: "🇨🇿 República Checa",
  },
  {
    value: "DE",
    label: "🇩🇪 Alemania",
  },
  {
    value: "DJ",
    label: "🇩🇯 Yibuti",
  },
  {
    value: "DM",
    label: "🇩🇲 Dominica",
  },
  {
    value: "DK",
    label: "🇩🇰 Dinamarca",
  },
  {
    value: "DO",
    label: "🇩🇴 República Dominicana",
  },
  {
    value: "DZ",
    label: "🇩🇿 Argelia",
  },
  {
    value: "EC",
    label: "🇪🇨 Ecuador",
  },
  {
    value: "EG",
    label: "🇪🇬 Egipto",
  },
  {
    value: "ER",
    label: "🇪🇷 Eritrea",
  },
  {
    value: "EH",
    label: "🇪🇭 Sáhara Occidental",
  },
  {
    value: "ES",
    label: "🇪🇸 España",
  },
  {
    value: "EE",
    label: "🇪🇪 Estonia",
  },
  {
    value: "ET",
    label: "🇪🇹 Etiopía",
  },
  {
    value: "FI",
    label: "🇫🇮 Finlandia",
  },
  {
    value: "FJ",
    label: "🇫🇯 Fiyi",
  },
  {
    value: "FK",
    label: "🇫🇰 Islas Malvinas",
  },
  {
    value: "FR",
    label: "🇫🇷 Francia",
  },
  {
    value: "FO",
    label: "🇫🇴 Islas Feroe",
  },
  {
    value: "FM",
    label: "🇫🇲 Micronesia",
  },
  {
    value: "GA",
    label: "🇬🇦 Gabón",
  },
  {
    value: "GB",
    label: "🇬🇧 Reino Unido",
  },
  {
    value: "GE",
    label: "🇬🇪 Georgia",
  },
  {
    value: "GG",
    label: "🇬🇬 Guernsey",
  },
  {
    value: "GH",
    label: "🇬🇭 Ghana",
  },
  {
    value: "GI",
    label: "🇬🇮 Gibraltar",
  },
  {
    value: "GN",
    label: "🇬🇳 Guinea",
  },
  {
    value: "GP",
    label: "🇬🇵 Guadalupe",
  },
  {
    value: "GM",
    label: "🇬🇲 Gambia",
  },
  {
    value: "GW",
    label: "🇬🇼 Guinea-Bisáu",
  },
  {
    value: "GQ",
    label: "🇬🇶 Guinea Ecuatorial",
  },
  {
    value: "GR",
    label: "🇬🇷 Grecia",
  },
  {
    value: "GD",
    label: "🇬🇩 Granada",
  },
  {
    value: "GL",
    label: "🇬🇱 Groenlandia",
  },
  {
    value: "GT",
    label: "🇬🇹 Guatemala",
  },
  {
    value: "GF",
    label: "🇬🇫 Guayana Francesa",
  },
  {
    value: "GU",
    label: "🇬🇺 Guam",
  },
  {
    value: "GY",
    label: "🇬🇾 Guyana",
  },
  {
    value: "HK",
    label: "🇭🇰 Hong Kong",
  },
  {
    value: "HM",
    label: "🇭🇲 Islas Heard y McDonald",
  },
  {
    value: "HN",
    label: "🇭🇳 Honduras",
  },
  {
    value: "HR",
    label: "🇭🇷 Croacia",
  },
  {
    value: "HT",
    label: "🇭🇹 Haití",
  },
  {
    value: "HU",
    label: "🇭🇺 Hungría",
  },
  {
    value: "ID",
    label: "🇮🇩 Indonesia",
  },
  {
    value: "IM",
    label: "🇮🇲 Isla de Man",
  },
  {
    value: "IN",
    label: "🇮🇳 India",
  },
  {
    value: "IO",
    label: "🇮🇴 Territorio Británico del Océano Índico",
  },
  {
    value: "IE",
    label: "🇮🇪 Irlanda",
  },
  {
    value: "IR",
    label: "🇮🇷 Irán",
  },
  {
    value: "IQ",
    label: "🇮🇶 Irak",
  },
  {
    value: "IS",
    label: "🇮🇸 Islandia",
  },
  {
    value: "IL",
    label: "🇮🇱 Israel",
  },
  {
    value: "IT",
    label: "🇮🇹 Italia",
  },
  {
    value: "JM",
    label: "🇯🇲 Jamaica",
  },
  {
    value: "JE",
    label: "🇯🇪 Jersey",
  },
  {
    value: "JO",
    label: "🇯🇴 Jordania",
  },
  {
    value: "JP",
    label: "🇯🇵 Japón",
  },
  {
    value: "KZ",
    label: "🇰🇿 Kazajistán",
  },
  {
    value: "KE",
    label: "🇰🇪 Kenia",
  },
  {
    value: "KG",
    label: "🇰🇬 Kirguistán",
  },
  {
    value: "KH",
    label: "🇰🇭 Camboya",
  },
  {
    value: "KI",
    label: "🇰🇮 Kiribati",
  },
  {
    value: "KN",
    label: "🇰🇳 San Cristóbal y Nieves",
  },
  {
    value: "KR",
    label: "🇰🇷 Corea del Sur",
  },
  {
    value: "KW",
    label: "🇰🇼 Kuwait",
  },
  {
    value: "KY",
    label: "🇰🇾 Islas Caimán",
  },
  {
    value: "KZ",
    label: "🇰🇿 Kazajistán",
  },
  {
    value: "LA",
    label: "🇱🇦 Laos",
  },
  {
    value: "LB",
    label: "🇱🇧 Líbano",
  },
  {
    value: "LC",
    label: "🇱🇨 Santa Lucía",
  },
  {
    value: "LI",
    label: "🇱🇮 Liechtenstein",
  },
  {
    value: "LK",
    label: "🇱🇰 Sri Lanka",
  },
  {
    value: "LR",
    label: "🇱🇷 Liberia",
  },
  {
    value: "LS",
    label: "🇱🇸 Lesoto",
  },
  {
    value: "LT",
    label: "🇱🇹 Lituania",
  },
  {
    value: "LU",
    label: "🇱🇺 Luxemburgo",
  },
  {
    value: "LV",
    label: "🇱🇻 Letonia",
  },
  {
    value: "LY",
    label: "🇱🇾 Libia",
  },
  {
    value: "MA",
    label: "🇲🇦 Marruecos",
  },
  {
    value: "MC",
    label: "🇲🇨 Mónaco",
  },
  {
    value: "MD",
    label: "🇲🇩 Moldavia",
  },
  {
    value: "MG",
    label: "🇲🇬 Madagascar",
  },
  {
    value: "MV",
    label: "🇲🇻 Maldivas",
  },
  {
    value: "MX",
    label: "🇲🇽 México",
  },
  {
    value: "MH",
    label: "🇲🇭 Islas Marshall",
  },
  {
    value: "MK",
    label: "🇲🇰 Macedonia del Norte",
  },
  {
    value: "ML",
    label: "🇲🇱 Mali",
  },
  {
    value: "MT",
    label: "🇲🇹 Malta",
  },
  {
    value: "MM",
    label: "🇲🇲 Birmania",
  },
  {
    value: "ME",
    label: "🇲🇪 Montenegro",
  },
  {
    value: "MN",
    label: "🇲🇳 Mongolia",
  },
  {
    value: "MP",
    label: "🇲🇵 Islas Marianas del Norte",
  },
  {
    value: "MZ",
    label: "🇲🇿 Mozambique",
  },
  {
    value: "MR",
    label: "🇲🇷 Mauritania",
  },
  {
    value: "MS",
    label: "🇲🇸 Montserrat",
  },
  {
    value: "MQ",
    label: "🇲🇶 Martinica",
  },
  {
    value: "MU",
    label: "🇲🇺 Mauricio",
  },
  {
    value: "MW",
    label: "🇲🇼 Malaui",
  },
  {
    value: "MY",
    label: "🇲🇾 Malasia",
  },
  {
    value: "YT",
    label: "🇾🇹 Mayotte",
  },
  {
    value: "NA",
    label: "🇳🇦 Namibia",
  },
  {
    value: "NC",
    label: "🇳🇨 Nueva Caledonia",
  },
  {
    value: "NE",
    label: "🇳🇪 Níger",
  },
  {
    value: "NF",
    label: "🇳🇫 Isla Norfolk",
  },
  {
    value: "NG",
    label: "🇳🇬 Nigeria",
  },
  {
    value: "NI",
    label: "🇳🇮 Nicaragua",
  },
  {
    value: "NU",
    label: "🇳🇺 Niue",
  },
  {
    value: "NL",
    label: "🇳🇱 Países Bajos",
  },
  {
    value: "NO",
    label: "🇳🇴 Noruega",
  },
  {
    value: "NP",
    label: "🇳🇵 Nepal",
  },
  {
    value: "NR",
    label: "🇳🇷 Nauru",
  },
  {
    value: "NZ",
    label: "🇳🇿 Nueva Zelanda",
  },
  {
    value: "OM",
    label: "🇴🇲 Omán",
  },
  {
    value: "PK",
    label: "🇵🇰 Pakistán",
  },
  {
    value: "PA",
    label: "🇵🇦 Panamá",
  },
  {
    value: "PN",
    label: "🇵🇳 Pitcairn",
  },
  {
    value: "PE",
    label: "🇵🇪 Perú",
  },
  {
    value: "PH",
    label: "🇵🇭 Filipinas",
  },
  {
    value: "PW",
    label: "🇵🇼 Palaos",
  },
  {
    value: "PG",
    label: "🇵🇬 Papúa Nueva Guinea",
  },
  {
    value: "PL",
    label: "🇵🇱 Polonia",
  },
  {
    value: "PR",
    label: "🇵🇷 Puerto Rico",
  },
  {
    value: "KP",
    label: "🇰🇵 Corea del Norte",
  },
  {
    value: "PT",
    label: "🇵🇹 Portugal",
  },
  {
    value: "PY",
    label: "🇵🇾 Paraguay",
  },
  {
    value: "PS",
    label: "🇵🇸 Palestina",
  },
  {
    value: "PF",
    label: "🇵🇫 Polinesia Francesa",
  },
  {
    value: "QA",
    label: "🇶🇦 Catar",
  },
  {
    value: "RE",
    label: "🇷🇪 Reunión",
  },
  {
    value: "RO",
    label: "🇷🇴 Rumanía",
  },
  {
    value: "RU",
    label: "🇷🇺 Rusia",
  },
  {
    value: "RW",
    label: "🇷🇼 Ruanda",
  },
  {
    value: "SA",
    label: "🇸🇦 Arabia Saudita",
  },
  {
    value: "SD",
    label: "🇸🇩 Sudán",
  },
  {
    value: "SN",
    label: "🇸🇳 Senegal",
  },
  {
    value: "SG",
    label: "🇸🇬 Singapur",
  },
  {
    value: "GS",
    label: "🇬🇸 Georgia del Sur e Islas Sandwich del Sur",
  },
  {
    value: "SJ",
    label: "🇸🇯 Svalbard y Jan Mayen",
  },
  {
    value: "SB",
    label: "🇸🇧 Islas Salomón",
  },
  {
    value: "SL",
    label: "🇸🇱 Sierra Leona",
  },
  {
    value: "SV",
    label: "🇸🇻 El Salvador",
  },
  {
    value: "SM",
    label: "🇸🇲 San Marino",
  },
  {
    value: "SO",
    label: "🇸🇴 Somalia",
  },
  {
    value: "PM",
    label: "🇵🇲 San Pedro y Miquelón",
  },
  {
    value: "RS",
    label: "🇷🇸 Serbia",
  },
  {
    value: "SS",
    label: "🇸🇸 Sudán del Sur",
  },
  {
    value: "ST",
    label: "🇸🇹 Santo Tomé y Príncipe",
  },
  {
    value: "SR",
    label: "🇸🇷 Surinam",
  },
  {
    value: "SK",
    label: "🇸🇰 Eslovaquia",
  },
  {
    value: "SI",
    label: "🇸🇮 Eslovenia",
  },
  {
    value: "SE",
    label: "🇸🇪 Suecia",
  },
  {
    value: "SZ",
    label: "🇸🇿 Suazilandia",
  },
  {
    value: "SX",
    label: "🇸🇽 Sint Maarten",
  },
  {
    value: "SC",
    label: "🇸🇨 Seychelles",
  },
  {
    value: "SY",
    label: "🇸🇾 Siria",
  },
  {
    value: "TC",
    label: "🇹🇨 Islas Turcas y Caicos",
  },
  {
    value: "TD",
    label: "🇹🇩 Chad",
  },
  {
    value: "TG",
    label: "🇹🇬 Togo",
  },
  {
    value: "TH",
    label: "🇹🇭 Tailandia",
  },
  {
    value: "TJ",
    label: "🇹🇯 Tayikistán",
  },
  {
    value: "TK",
    label: "🇹🇰 Tokelau",
  },
  {
    value: "TM",
    label: "🇹🇲 Turkmenistán",
  },
  {
    value: "TL",
    label: "🇹🇱 Timor Oriental",
  },
  {
    value: "TO",
    label: "🇹🇴 Tonga",
  },
  {
    value: "TT",
    label: "🇹🇹 Trinidad y Tobago",
  },
  {
    value: "TN",
    label: "🇹🇳 Túnez",
  },
  {
    value: "TR",
    label: "🇹🇷 Turquía",
  },
  {
    value: "TV",
    label: "🇹🇻 Tuvalu",
  },
  {
    value: "TW",
    label: "🇹🇼 Taiwán",
  },
  {
    value: "TZ",
    label: "🇹🇿 Tanzania",
  },
  {
    value: "UG",
    label: "🇺🇬 Uganda",
  },
  {
    value: "UA",
    label: "🇺🇦 Ucrania",
  },
  {
    value: "UM",
    label: "Islas menores alejadas de los Estados Unidos",
  },
  {
    value: "UY",
    label: "🇺🇾 Uruguay",
  },
  {
    value: "US",
    label: "🇺🇸 Estados Unidos",
  },
  {
    value: "UZ",
    label: "🇺🇿 Uzbekistán",
  },
  {
    value: "VA",
    label: "🇻🇦 Ciudad del Vaticano",
  },
  {
    value: "VC",
    label: "🇻🇨 San Vicente y las Granadinas",
  },
  {
    value: "VE",
    label: "🇻🇪 Venezuela",
  },
  {
    value: "VG",
    label: "🇻🇬 Islas Vírgenes Británicas",
  },
  {
    value: "VI",
    label: "🇻🇮 Islas Vírgenes de los Estados Unidos",
  },
  {
    value: "VN",
    label: "🇻🇳 Vietnam",
  },
  {
    value: "VU",
    label: "🇻🇺 Vanuatu",
  },
  {
    value: "WF",
    label: "🇼🇫 Wallis y Futuna",
  },
  {
    value: "WS",
    label: "🇼🇸 Samoa",
  },
  {
    value: "YE",
    label: "🇾🇪 Yemen",
  },
  {
    value: "YT",
    label: "🇾🇹 Mayotte",
  },
  {
    value: "ZA",
    label: "🇿🇦 Sudáfrica",
  },
  {
    value: "ZM",
    label: "🇿🇲 Zambia",
  },
  {
    value: "ZW",
    label: "🇿🇼 Zimbabue",
  },
];

export default countries;
