import React, { useEffect, useState } from "react";
// PDF handler
import { Margin, usePDF } from "react-to-pdf";
// E-Sign handler
import SignaturePad from "react-signature-canvas";
import styles from "./styles.module.css";
import { saveClientLegals } from "../../../../../firebase/firebase.patient";

const Legals = ({ headerTitle, data, editMode }) => {
  const [percentInNumbers, setPercentInNumbers] = useState(0);
  const [percentInLetters, setPercentInLetters] = useState("");
  const [multaInNumbers, setMultaInNumbers] = useState(0);
  const [multaInLetters, setMultaInLetters] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [CI, setCI] = useState(0);
  const [exportToExcel, setExportToExcel] = useState(false);

  const [sigPad, setSigPad] = useState({});
  const [sigPad2, setSigPad2] = useState({});
  const [sigPad3, setSigPad3] = useState({});

  const { toPDF, targetRef } = usePDF({
    filename: `${headerTitle}-autorizacion.pdf`,
    page: { margin: Margin.MEDIUM },
  });

  const exportToPDF = () => {
    setExportToExcel(true);
    toPDF();
    setExportToExcel(false);
  };

  const saveDocument = () => {
    let mapEsignToPointGroup = sigPad.toData();
    let mapEsign2ToPointGroup = sigPad2.toData();
    let mapEsign3ToPointGroup = sigPad3.toData();
    console.table(
      mapEsignToPointGroup,
      mapEsign2ToPointGroup,
      mapEsign3ToPointGroup
    );

    const legalDoc = {
      legalPercentInNumber: percentInNumbers,
      legalPercentInLetters: percentInLetters,
      legalFineInNumber: multaInNumbers,
      legalFineInLetters: multaInLetters,
      additionalInfo: additionalInfo,
      signOfProfessional: JSON.stringify(mapEsignToPointGroup),
      patientOrTutorSign: JSON.stringify(mapEsign2ToPointGroup),
      patientSignCarification: JSON.stringify(mapEsign3ToPointGroup),
      CI: CI,
    };

    saveClientLegals(data.id, legalDoc);
  };

  useEffect(() => {
    if (data.legals) {
      if (
        sigPad.props !== undefined &&
        sigPad2.props !== undefined &&
        sigPad3.props !== undefined
      ) {
        sigPad.fromData(JSON.parse(data.legals?.signOfProfessional));
        sigPad2.fromData(JSON.parse(data.legals?.patientOrTutorSign));
        sigPad3.fromData(JSON.parse(data.legals?.patientSignCarification));
        setPercentInLetters(data.legals?.legalPercentInLetters);
        setPercentInNumbers(data.legals?.legalPercentInNumber);
        setMultaInLetters(data.legals?.legalFineInLetters);
        setMultaInNumbers(data.legals?.legalFineInNumber);
        setAdditionalInfo(data.legals?.additionalInfo);
        setCI(data.legals?.CI);
      }
    }
  }, [data.legals, sigPad]);

  return (
    <div
      className="flex flex-wrap -mx-3 pb-5 h-[56vh] overflow-scroll"
      style={{ justifyContent: "center" }}
    >
      <div className="w-full max-w-full px-3 mt-3">
        <div className="flex p-2" style={{ justifyContent: "flex-end" }}>
          <button
            className="bg-gray-300 hover:bg-gray-400 text-gray-800 mx-2 font-semibold py-2 px-4 rounded inline-flex items-center"
            onClick={exportToPDF}
          >
            <svg
              className="fill-current w-4 h-4 mr-2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
            </svg>
            <span>Download</span>
          </button>
          <button
            className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded inline-flex items-center"
            onClick={saveDocument}
          >
            {/* <svg className="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/></svg> */}
            <span>Guardar</span>
          </button>
        </div>
        <div ref={targetRef}>
          <div className="flex" style={{ justifyContent: "center" }}>
            <div className="p-6 mx-6 my-2 bg-white border border-gray-200 rounded-lg shadow w-90vw">
              <div className="flex mb-5" style={{ justifyContent: "center" }}>
                <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
                  {"AUTORIZACIÓN PARA TRATAMIENTOS Y COMPROMISOS DE PAGO:"}
                </h5>
              </div>
              <p className="mb-3 font-normal text-gray-700 leading-8">
                1 - Autorizo al Dr/a Cecilia Sosa Martínez y/o asociados o
                ayudantes a realizar el tratamiento informado en el presente.
              </p>
              <p className="mb-3 font-normal text-gray-700 leading-8">
                2 - He conversado con el profesional sobre la naturaleza y
                propósito del tratamiento, sobre la posibilidad de
                complicaciones, y los posibles riesgos.
              </p>
              <p className="mb-3 font-normal text-gray-700 leading-8">
                3 - Autorizo a proveer los servicios o tratamientos adicionales
                que considere razonables incluyendo la administración de
                anestesia local, radiografías, análisis de sangre y otros
                métodos de diagnósticos.
              </p>
              <p className="mb-3 font-normal text-gray-700 leading-8">
                4 - En caso de incumplimiento con pagos, o mora en el pago,
                devengara un interés de
                <input
                  onChange={(e) => setPercentInNumbers(e.target.value)}
                  type="number"
                  className="w-5vw ml-2"
                  value={percentInNumbers}
                />
                %(
                <input
                  onChange={(e) => setPercentInLetters(e.target.value)}
                  className="w-15vw ml-2"
                  type="string"
                  value={percentInLetters}
                />{" "}
                por ciento) mensual, mas multa de
                <input
                  onChange={(e) => setMultaInNumbers(e.target.value)}
                  className="w-5-vw ml-2"
                  type="number"
                  value={multaInNumbers}
                />
                %(
                <input
                  onChange={(e) => setMultaInLetters(e.target.value)}
                  className="w-15vw  ml-2"
                  type="string"
                  value={multaInLetters}
                />{" "}
                por ciento) mensual, desde la fecha de su vencimiento hasta el
                día del pago efectivo.
              </p>
              <p className="mb-3 font-normal text-gray-700 leading-8">
                Obs: En caso de que el paciente sea menos de edad, la firma y
                Nro. de C.I. policial será del responsable o tutor.
              </p>
              <p className="p-2 w-full">
                <textarea
                  onChange={(e) => setAdditionalInfo(e.target.value)}
                  className="w-full  ml-2"
                  type="string"
                  value={additionalInfo}
                />
              </p>

              <div
                className="flex flex-col"
                style={{ justifyContent: "center" }}
              >
                <div className="flex flex-col m-3 my-6">
                  <h6>Firma del Profesional:</h6>
                  <div className="mb-3">Firma</div>
                  <SignaturePad
                    canvasProps={{ className: styles.sigPad }}
                    ref={(ref) => {
                      setSigPad(ref);
                    }}
                    clearOnResize={false}
                  />
                  {!exportToExcel && (
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <button onClick={() => sigPad.clear()}>
                        Borrar Firma
                      </button>
                    </div>
                  )}
                </div>

                <div className="flex flex-col m-3 my-6">
                  <h6>Firma del Paciente/ tutor o encargado</h6>
                  <div className="mb-3">Firma</div>
                  <SignaturePad
                    canvasProps={{ className: styles.sigPad }}
                    ref={(ref2) => {
                      setSigPad2(ref2);
                    }}
                    clearOnResize={false}
                  />
                  {!exportToExcel && (
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <button onClick={() => sigPad2.clear()}>
                        Borrar Firma
                      </button>
                    </div>
                  )}
                </div>

                <div className="flex flex-col m-3 my-6">
                  <h6>Aclaración de Firma del Paciente</h6>
                  <div className="mb-3">Firma</div>
                  <SignaturePad
                    canvasProps={{ className: styles.sigPad }}
                    ref={(ref3) => {
                      setSigPad3(ref3);
                    }}
                    clearOnResize={false}
                  />
                  {!exportToExcel && (
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <button onClick={() => sigPad3.clear()}>
                        Borrar Firma
                      </button>
                    </div>
                  )}
                </div>

                <div
                  className="flex m-3 my-6"
                  style={{ justifyContent: "flex-start" }}
                >
                  <h6>C.I. del paciente</h6>
                  <input
                    className="rounded ml-2"
                    style={{ backgroundColor: "rgb(239, 239, 239)" }}
                    value={CI}
                    onChange={(e) => setCI(e.target.value)}
                  ></input>
                </div>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default Legals;
