import React from "react";
import Filter from "../filter/Filter";
import { Link } from "react-router-dom";
import CalendarHandler from "./CalendarHandler";

const Calendar = ({ userData }) => {
  return (
    <div>
      <main className="">
        <div className="">
          <div className="">
            <div
              className="bg-white rounded-lg shadow"
              style={{ height: "90vh", overflow: "scroll" }}
            >
              <CalendarHandler patientsData={userData} />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Calendar;
