import { useEffect, useState } from "react";
import { Link, useRouteError } from "react-router-dom";
import { logError } from "../../firebase/firebase.errortracker";
import Kroty from "../../resources/Kroty.png";
import { useSelector } from "react-redux";

export default function ErrorPage() {
  const [message, setMessage] = useState(null);
  const error = useRouteError();
  const user = useSelector((state) => state.user?.userDetails);

  useEffect(() => {
    if (message === null) {
      setMessage(
        `There was an ClientSide error: ${error.statusText || error.message}`
      );
    } else {
      logError(message, user);
    }
  }, [message]);

  return (
    <section className="bg-gray-800 relative z-10 py-[120px] h-screen">
      <div className="container mx-auto">
        <div className="-mx-4 flex">
          <div className="w-full px-4">
            <div className="mx-auto text-center">
              <h2 className="mb-2 text-[50px] font-bold leading-none text-white sm:text-[80px] md:text-[100px]">
                404
              </h2>
              <div className="mb-8 text-lg text-white mt-9">
                <div className="flex justify-center">
                  <div className="">
                    <h6>Lo sentimos, hubo un error.</h6>
                    <br />
                    Si esta viendo este mensaje,
                    <br />
                    el error ha sido reportado.
                    <br />
                    <br />
                    Para casos de urgencia, <br /> porfavor comuniquese al{" "}
                    <br /> 0994-880-781
                  </div>

                  <div>
                    <img src={Kroty} className="h-30vh" />
                  </div>
                </div>
              </div>
              <Link
                to="/dashboard"
                className="hover:text-black inline-block rounded-lg border border-white px-8 py-3 text-center text-base font-semibold text-white transition hover:bg-white"
              >
                Go To Home
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute top-0 left-0 -z-10 flex h-full w-full items-center justify-between space-x-5 md:space-x-8 lg:space-x-14">
        <div className="h-full w-1/3 bg-gradient-to-t from-[#FFFFFF14] to-[#C4C4C400]"></div>
        <div className="flex h-full w-1/3">
          <div className="h-full w-1/2 bg-gradient-to-b from-[#FFFFFF14] to-[#C4C4C400]"></div>
          <div className="h-full w-1/2 bg-gradient-to-t from-[#FFFFFF14] to-[#C4C4C400]"></div>
        </div>
        <div className="h-full w-1/3 bg-gradient-to-b from-[#FFFFFF14] to-[#C4C4C400]"></div>
      </div>
    </section>
  );
}
