import { doc, setDoc } from "firebase/firestore";
import { db } from "./firebase";
import { generateUuid } from "./firebase.helpers";
import { toast } from "react-hot-toast";

const sendFeedback = async (message, userData, modalSetter) => {
  debugger;
  try {
    message.id = generateUuid();
    message.user = {
      name: userData?.name,
      lastname: userData?.lastname,
      email: userData?.email,
      phone: userData?.phone,
      praxisId: userData?.praxisId,
    };

    const newFeedback = await setDoc(doc(db, "feedback", message.id), message);
    modalSetter(false);
    toast.success("Feedback enviado correctamente ❤️");
    return newFeedback;
  } catch (error) {
    console.error("Error handling confirm action: ", error);
    toast.error("Error al enviar feedback, favor intente nuevamente");
    throw error;
  }
};

export { sendFeedback };
