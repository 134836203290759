import React from "react";
import { useNavigate } from "react-router-dom";

const PatientRow = ({ name, tel, visit, birthdate, gender, mode, url }) => {
  const history = useNavigate();
  const onUserHandler = (e) => {
    history(`/${url}`);
  };
  return (
    <tr
      onClick={onUserHandler}
      className={`${
        mode === "darkmode"
          ? `odd:bg-gray-900 even:bg-gray-800 border-gray-700`
          : "odd:bg-white even:bg-gray-50 border-b text-gray-700"
      }`}
    >
      <th
        scope="row"
        className={`${
          mode === "darkmode" ? "text-white" : "text-sky-500"
        } ' px-6 py-4 font-medium whitespace-nowrap`}
      >
        {name}
      </th>
      <td className="px-6 py-4">{birthdate}</td>
      <td className="px-6 py-4">{gender}</td>
      <td className="px-6 py-4">
        {visit && new Date(visit).toISOString().split("T")[0]}
      </td>
      <td className="px-6 py-4">{tel}</td>
    </tr>
  );
};

export default PatientRow;
