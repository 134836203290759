import React, { useState, useEffect } from "react";
import Pill from "../UI/Pill";
import EditAndDelete from "../UI/EditAndDelete";
import { deletePayment, deleteService } from "../../firebase/firebase.payment";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

const FinancesRow = ({
  reason,
  date,
  description,
  hasBeenPaid,
  mode,
  url,
  balance,
  price,
  patient,
  payments,
  useCase,
  editModalSetter,
  rowData,
  allPaymentsTrigger,
  patientId,
}) => {
  const [showMore, setShowMore] = useState(false);

  const toggleMore = () => setShowMore(!showMore);

  const editPayment = (e) => {
    e.parentId = rowData.id;
    editModalSetter(e);
  };
  return (
    <>
      <div
        className={`grid grid-cols-12 gap-0 ${
          mode === "darkmode"
            ? `odd:bg-gray-900 even:bg-gray-800 border-gray-700`
            : "odd:bg-white even:bg-gray-800 border-b text-gray-700"
        }`}
      >
        <div
          className="px-2 flex items-center py-4 font-medium whitespace-pre-wrap col-span-2"
          onClick={toggleMore}
        >
          {!showMore ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5 mr-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m19.5 8.25-7.5 7.5-7.5-7.5"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5 mr-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m4.5 15.75 7.5-7.5 7.5 7.5"
              />
            </svg>
          )}

          <div
            className={`flex items-center ${
              mode === "darkmode" ? "text-white" : "text-sky-500"
            } '`}
          >
            <div>{reason}</div>
          </div>
        </div>
        <div
          onClick={toggleMore}
          className={`${
            useCase === "payments" ? "col-span-5" : "col-span-4"
          } flex items-center ${
            mode === "darkmode" ? "text-white" : "text-sky-500"
          } ' px-6 py-4 font-medium whitespace-pre-wrap`}
        >
          <div>{description}</div>
        </div>
        <div
          className="py-4 flex items-center justify-center col-span-1 whitespace-pre-wrap"
          onClick={toggleMore}
        >
          <div>
            {date && date?.seconds !== undefined
              ? new Date(date.seconds * 1000).toISOString().split("T")[0]
              : date.split("T")[0]}
          </div>
        </div>
        <div
          className="px-6 flex items-center justify-center py-4 col-span-1"
          onClick={toggleMore}
        >
          <div>
            {price
              ? price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : ""}
          </div>
        </div>
        <div
          className="px-5 py-2 flex justify-center items-center col-span-1.5"
          onClick={toggleMore}
        >
          <div>
            {hasBeenPaid ? (
              <Pill
                text="Pagado"
                type="progress"
                balance={balance}
                price={price}
              />
            ) : (
              <Pill
                text="Pendiente"
                type="progress"
                balance={balance}
                price={price}
              />
            )}
          </div>
        </div>
        <div
          className="px-3 py-2 flex items-center col-span-1"
          onClick={toggleMore}
        >
          <div>
            {balance
              ? balance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : "-"}
          </div>
        </div>
        {useCase !== "payments" && (
          <div
            className="px-6 py-4 flex items-center col-span-1"
            onClick={toggleMore}
          >
            <Link to={`/${patientId}`}>{patient}</Link>
          </div>
        )}

        <div className="px-6 py-4 flex items-center col-auto">
          <div>
            <EditAndDelete
              editHandler={() => editModalSetter(rowData)}
              deleteHandler={async () => {
                toast.custom(
                  (t) => (
                    <div
                      className={`${
                        t.visible ? "animate-enter" : "animate-leave"
                      } max-w-xs w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
                    >
                      <div className="flex p-4">
                        <div className="flex items-center">
                          <div className="">
                            <p className="text-sm font-medium text-gray-900">
                              Seguro que quieres eliminar este Servicio?
                            </p>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="flex border-l border-gray-200">
                          <button
                            onClick={() => toast.dismiss(t.id)}
                            className="w-full border border-transparent rounded-none rounded-r-lg p-2 flex items-center justify-center text-sm font-medium text-slate-800 hover:text-slate-900"
                          >
                            Cancelar
                          </button>
                        </div>
                        <div className="flex justify-center">
                          <div className="h-[0.2vh] w-3/4 bg-slate-800"></div>
                        </div>
                        <div className="flex border-l border-gray-200">
                          <button
                            onClick={async () => {
                              await deleteService(rowData.id);
                              allPaymentsTrigger(false);
                              toast.dismiss(t.id);
                            }}
                            className="w-full border border-transparent rounded-none rounded-r-lg p-2 flex items-center justify-center text-sm font-medium text-slate-800 hover:text-red-600"
                          >
                            Eliminar
                          </button>
                        </div>
                      </div>
                    </div>
                  ),
                  {
                    duration: 7000,
                  }
                );
              }}
            />
          </div>
        </div>
      </div>

      {showMore && payments && (
        <>
          <div className="bg-gray-200 text-gray-700 text-xs text-gray-700 sticky top-0 uppercase bg-gray-50 bg-green-700 text-gray-200">
            {payments.length > 0 ? (
              <div className="grid grid-cols-12 gap-0">
                <div className="p-3 col-span-2 flex justify-center border-b border-gray-300">
                  {/* Pagos --{reason} */}
                </div>
                <div className="p-3 italic col-span-5 text-white flex justify-center border-b border-gray-300">
                  Descripción del Pago
                </div>
                <div className="p-3 italic col-span-2 text-white flex justify-center border-b border-gray-300">
                  Fecha
                </div>
                <div className="p-3 italic col-span-1 text-white flex justify-center border-b border-gray-300">
                  Pago
                </div>
                <div className="p-3 italic col-span-1 text-white flex justify-center border-b border-gray-300">
                  Factura
                </div>
                <div className="p-3 col-span-1 flex justify-center border-b border-gray-300"></div>
              </div>
            ) : (
              <div className="grid grid-cols-12 gap-0">
                <div className="p-3 col-span-12 text-white normal-case flex justify-center border-b border-gray-300">
                  Agregá un pago en "Agregar Acción"
                </div>
              </div>
            )}
          </div>
          <div>
            {payments &&
              payments.map((e) => {
                return (
                  <div
                    className={`grid grid-cols-12 gap-0 odd:bg-green-800 even:bg-green-900 border-gray-700`}
                  >
                    <div
                      className={`${
                        mode === "darkmode" ? "text-white" : "text-sky-500"
                      } ' px-6 py-4 font-medium whitespace-pre-wrap col-span-2 flex items-center`}
                    >
                      <div>📈 {reason}</div>
                    </div>
                    <div
                      className={`col-span-5 ${
                        mode === "darkmode" ? "text-white" : "text-sky-500"
                      } ' px-6 py-4 font-medium whitespace-pre-wrap flex items-center text-white`}
                    >
                      <div>{e.description}</div>
                    </div>
                    <div className="px-2 py-4 col-span-2 whitespace-pre-wrap flex items-center justify-center text-white">
                      <div>
                        {e && e.date?.seconds !== undefined
                          ? new Date(e.date.seconds * 1000)
                              .toISOString()
                              .split("T")[0]
                          : date.split("T")[0]}
                      </div>
                    </div>
                    <div className="px-6 py-4 col-span-1 flex items-center justify-center text-white">
                      <div>
                        {e && e.amount
                          ? e.amount
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          : ""}
                      </div>
                    </div>
                    <div className="px-6 py-4 col-span-1 flex items-center justify-center text-white">
                      <div>{e.invoice}</div>
                    </div>
                    <div className="px-6 py-4 col-span-1 flex items-center">
                      <div>
                        <EditAndDelete
                          editHandler={() => editPayment(e)}
                          deleteHandler={() => {
                            toast.custom(
                              (t) => (
                                <div
                                  className={`${
                                    t.visible
                                      ? "animate-enter"
                                      : "animate-leave"
                                  } max-w-xs w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
                                >
                                  <div className="flex p-4">
                                    <div className="flex items-center">
                                      <div className="">
                                        <p className="text-sm font-medium text-gray-900">
                                          Seguro que quieres eliminar este Pago?
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <div className="flex border-l border-gray-200">
                                      <button
                                        onClick={() => toast.dismiss(t.id)}
                                        className="w-full border border-transparent rounded-none rounded-r-lg p-2 flex items-center justify-center text-sm font-medium text-slate-800 hover:text-slate-900"
                                      >
                                        Cancelar
                                      </button>
                                    </div>
                                    <div className="flex justify-center">
                                      <div className="h-[0.2vh] w-3/4 bg-slate-800"></div>
                                    </div>
                                    <div className="flex border-l border-gray-200">
                                      <button
                                        onClick={async () => {
                                          debugger;
                                          await deletePayment(e, rowData.id);
                                          allPaymentsTrigger(false);
                                          toast.dismiss(t.id);
                                        }}
                                        className="w-full border border-transparent rounded-none rounded-r-lg p-2 flex items-center justify-center text-sm font-medium text-slate-800 hover:text-red-600"
                                      >
                                        Eliminar
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              ),
                              {
                                duration: 7000,
                              }
                            );
                          }}
                          textColor="text-white"
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </>
      )}
    </>
  );
};

export default FinancesRow;
