export const odontogramModel = {
  11: {
    center: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    top: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    bottom: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    left: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    right: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
  },
  12: {
    center: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    top: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    bottom: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    left: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    right: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
  },
  13: {
    center: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    top: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    bottom: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    left: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    right: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
  },
  14: {
    center: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    top: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    bottom: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    left: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    right: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
  },
  15: {
    center: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    top: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    bottom: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    left: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    right: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
  },
  16: {
    center: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    top: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    bottom: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    left: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    right: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
  },
  17: {
    center: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    top: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    bottom: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    left: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    right: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
  },
  18: {
    center: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    top: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    bottom: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    left: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    right: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
  },
  21: {
    center: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    top: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    bottom: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    left: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    right: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
  },
  22: {
    center: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    top: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    bottom: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    left: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    right: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
  },
  23: {
    center: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    top: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    bottom: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    left: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    right: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
  },
  24: {
    center: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    top: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    bottom: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    left: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    right: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
  },
  25: {
    center: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    top: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    bottom: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    left: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    right: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
  },
  26: {
    center: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    top: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    bottom: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    left: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    right: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
  },
  27: {
    center: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    top: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    bottom: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    left: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    right: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
  },
  28: {
    center: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    top: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    bottom: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    left: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    right: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
  },
  31: {
    center: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    top: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    bottom: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    left: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    right: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
  },
  32: {
    center: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    top: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    bottom: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    left: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    right: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
  },
  33: {
    center: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    top: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    bottom: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    left: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    right: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
  },
  34: {
    center: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    top: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    bottom: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    left: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    right: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
  },
  35: {
    center: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    top: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    bottom: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    left: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    right: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
  },
  36: {
    center: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    top: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    bottom: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    left: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    right: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
  },
  37: {
    center: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    top: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    bottom: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    left: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    right: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
  },
  38: {
    center: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    top: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    bottom: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    left: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    right: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
  },
  41: {
    center: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    top: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    bottom: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    left: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    right: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
  },
  42: {
    center: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    top: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    bottom: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    left: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    right: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
  },
  43: {
    center: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    top: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    bottom: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    left: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    right: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
  },
  44: {
    center: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    top: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    bottom: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    left: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    right: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
  },
  45: {
    center: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    top: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    bottom: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    left: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    right: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
  },
  46: {
    center: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    top: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    bottom: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    left: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    right: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
  },
  47: {
    center: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    top: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    bottom: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    left: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    right: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
  },
  48: {
    center: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    top: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    bottom: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    left: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    right: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
  },
  51: {
    center: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    top: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    bottom: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    left: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    right: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
  },
  52: {
    center: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    top: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    bottom: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    left: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    right: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
  },
  53: {
    center: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    top: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    bottom: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    left: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    right: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
  },
  54: {
    center: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    top: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    bottom: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    left: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    right: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
  },
  55: {
    center: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    top: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    bottom: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    left: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    right: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
  },
  61: {
    center: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    top: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    bottom: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    left: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    right: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
  },
  62: {
    center: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    top: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    bottom: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    left: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    right: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
  },
  63: {
    center: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    top: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    bottom: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    left: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    right: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
  },
  64: {
    center: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    top: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    bottom: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    left: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    right: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
  },
  65: {
    center: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    top: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    bottom: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    left: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    right: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
  },
  71: {
    center: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    top: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    bottom: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    left: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    right: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
  },
  72: {
    center: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    top: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    bottom: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    left: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    right: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
  },
  73: {
    center: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    top: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    bottom: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    left: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    right: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
  },
  74: {
    center: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    top: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    bottom: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    left: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    right: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
  },
  75: {
    center: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    top: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    bottom: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    left: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    right: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
  },
  81: {
    center: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    top: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    bottom: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    left: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    right: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
  },
  82: {
    center: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    top: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    bottom: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    left: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    right: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
  },
  83: {
    center: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    top: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    bottom: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    left: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    right: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
  },
  84: {
    center: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    top: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    bottom: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    left: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    right: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
  },
  85: {
    center: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    top: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    bottom: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    left: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    right: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
  },
};

export default odontogramModel;
