import React from "react";

const SaveButton = ({ saveClientHandler, disabled }) => {
  return (
    <div className="flex flex-row-reverse p-1">
      <div className="flex-initial pl-1">
        <button
          onClick={saveClientHandler}
          disabled={disabled}
          type="button"
          className="flex items-center px-0.5 py-1 font-medium tracking-wide text-white capitalize bg-black rounded-md hover:bg-gray-800 focus:outline-none focus:bg-gray-900 transition duration-300 transform active:scale-95 ease-in-out"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="#FFFFFF"
            className="w-4 h-5 m-1"
          >
            <path d="M0 0h24v24H0V0z" fill="none"></path>
            <path
              d="M5 5v14h14V7.83L16.17 5H5zm7 13c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-8H6V6h9v4z"
              opacity=".3"
            ></path>
            <path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm2 16H5V5h11.17L19 7.83V19zm-7-7c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3zM6 6h9v4H6z"></path>
          </svg>
          {/* <span className="mx-1 text-sm">Guardar</span> */}
        </button>
      </div>
    </div>
  );
};

export default SaveButton;
