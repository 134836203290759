import { createSlice } from "@reduxjs/toolkit";

export const patients = createSlice({
  name: "patients",
  initialState: {
    allPatients: null,
    filteredPatients: [],
  },
  reducers: {
    setAllPatients: (state, action) => {
      state.allPatients = action.payload;
    },
    setFilteredPatients: (state, action) => {
      state.filteredPatients = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAllPatients, setFilteredPatients } = patients.actions;

export default patients.reducer;
