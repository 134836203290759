import React, { useState, useEffect } from "react";
import PaymentModal from "../../../../UI/PaymentModal";
import DeleteButton from "../../../../UI/DeleteButton";
import {
  deletePaymentItem,
  allPaymentsByUIdAndPId,
} from "../../../../../firebase/firebase.payment";
import { useSelector } from "react-redux";
import FinancesTable from "../../../../finances/FinancesTable";

const Payments = ({ data }) => {
  const [openModal, setOpenModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [selectedDate, setSelectedDate] = useState(undefined);
  const [allPayments, setAllPayments] = useState(false);
  const [defaultAllPayments, setDefaultAllPayments] = useState(false);
  const localPraxis = useSelector((state) => state.user?.userDetails);

  useEffect(() => {
    if (!defaultAllPayments) {
      const getAllPayments = async () => {
        const payments = await allPaymentsByUIdAndPId(
          localPraxis.praxisId,
          data.id
        );
        setAllPayments(payments);
        setDefaultAllPayments(payments);
        setSelectedCategory("all");
        setSelectedDate(undefined);
      };
      getAllPayments();
    }
  }, [defaultAllPayments]);

  useEffect(() => {
    if (defaultAllPayments && selectedCategory) {
      switch (selectedCategory) {
        case "pending": {
          if (selectedDate) {
            var activePayments = defaultAllPayments.filter(
              (payment) =>
                payment.hasBeenPaid === false &&
                new Date(payment?.date)?.getMonth() ===
                  new Date(selectedDate)?.getMonth()
            );
            return setAllPayments(activePayments);
          } else {
            var activePayments = defaultAllPayments.filter(
              (payment) => payment.hasBeenPaid === false
            );
            return setAllPayments(activePayments);
          }
        }

        case "all": {
          if (selectedDate) {
            var allInRange = defaultAllPayments.filter(
              (payment) =>
                new Date(payment?.date)?.getMonth() ===
                new Date(selectedDate)?.getMonth()
            );
            return setAllPayments(allInRange);
          } else {
            return setAllPayments(defaultAllPayments);
          }
        }

        case "paid": {
          if (selectedDate) {
            var activePayments = defaultAllPayments.filter(
              (payment) =>
                payment.hasBeenPaid === true &&
                new Date(payment?.date)?.getMonth() ===
                  new Date(selectedDate)?.getMonth()
            );
            return setAllPayments(activePayments);
          } else {
            var activePayments = defaultAllPayments.filter(
              (payment) => payment.hasBeenPaid === true
            );
            return setAllPayments(activePayments);
          }
        }

        case "month": {
          var activePayments = defaultAllPayments.filter(
            (payment) =>
              new Date(payment?.date)?.getMonth() ===
              new Date(selectedDate)?.getMonth()
          );
          return setAllPayments(activePayments);
        }
      }
    }
  }, [selectedCategory, selectedDate]);

  return (
    <>
      <FinancesTable
        userData={allPayments}
        setOpenModal={setOpenModal}
        setSelectedCategory={setSelectedCategory}
        selectedCategory={selectedCategory}
        useCase="payments"
        editModalSetter={setEditModal}
        allPaymentsTrigger={setDefaultAllPayments}
        setSelectedDate={setSelectedDate}
        selectedDate={selectedDate}
      />

      {openModal && (
        <PaymentModal
          data={data}
          defaultValue={defaultAllPayments[0]}
          modalState={openModal}
          modalSetter={setOpenModal}
          allPaymentsSetter={setDefaultAllPayments}
          allPayments={defaultAllPayments}
        />
      )}

      {editModal && (
        <PaymentModal
          data={editModal}
          defaultValue={defaultAllPayments[0]}
          modalState={openModal}
          modalSetter={setEditModal}
          allPaymentsSetter={setDefaultAllPayments}
          allPayments={defaultAllPayments}
          useCase={"edit"}
          editModal={editModal}
        />
      )}
    </>
  );
};

export default Payments;
