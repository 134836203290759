import React, { useEffect, useState } from "react";
import { calculateAge } from "../../../../utils/calculateAge";
import NameAvatar from "../../../UI/NameAvatar";
import { useSelector } from "react-redux";
import { getPraxisUsers } from "../../../../firebase/firebase.praxis";
import { formatUserRole } from "../../../../utils/formatUserRole";

const Referrals = ({ stats, praxis }) => {
  return (
    <div className="mx-auto m-2">
      <div className="col-span-5 xl:col-span-2">
        <div className="rounded-md border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
          <div className="border-b border-stroke py-4 px-7 dark:border-strokedark">
            <h3 className="font-medium text-black">Link de Referencia</h3>
          </div>
          <div className="p-7 overflow-scroll h-20vh">
            <div className="flex justify-between">
              <div className="mb-4 flex items-center gap-3 w-full">
                <div className="flex justify-between w-full">
                  <span className="mb-1.5 text-black">{`odontostark.com/registrarse/${praxis?.id}`}</span>
                </div>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Referrals;
