import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  logInWithEmailAndPassword,
  sendPasswordReset,
} from "../../firebase/firebase.user";
import { auth } from "../../firebase/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import logo from "../../resources/logo-webstark.png";
import {
  setUserCredetials,
  setUserDetails,
} from "../../store/user/user.reducer";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../UI/Spinner";
import usePumble from "../../pumble/usePumble";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const userCredentials = useSelector((state) => state.user?.userCredentials);
  const userDetails = useSelector((state) => state.user?.userDetails);
  const { loading, error, response, sendLogin } = usePumble();
  const dispatch = useDispatch();

  useEffect(() => {
    if (user && userDetails) {
      navigate("/dashboard");
    }
  }, [user, userDetails]);

  useEffect(() => {
    if (user && userCredentials === null) {
      let deserializedUser = JSON.parse(JSON.stringify(user));
      dispatch(setUserCredetials(deserializedUser));
    }
  }, [user]);

  const logIn = async () => {
    setIsLoading(true);
    const loginUserData = await logInWithEmailAndPassword(email, password);
    if (loginUserData) {
      dispatch(setUserDetails(loginUserData));
      setIsLoading(false);
      debugger;
      sendLogin(`Inicio de Sesion de ${loginUserData?.email}`);
    }
    setIsLoading(false);
  };

  const passwordReset = async () => {
    await sendPasswordReset(email);
  };

  return (
    <div className="flex min-h-[100vh] items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="w-full max-w-md space-y-8 mt-14">
        <div className="flex flex-col">
          <svg
            version="1.2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 689 287"
            className="h-[20vh]"
          >
            <style>
              {`.s0 { fill: #253141 } 
		.s1 { fill: #0ea5e9 } 
		.s2 { fill: none;stroke: #0ea5e9;stroke-linecap: round;stroke-linejoin: round;stroke-width: 1.9 } `}
            </style>
            <g id="Layer">
              <g id="Layer">
                <g id="Layer">
                  <g id="Layer">
                    <path
                      id="Layer"
                      className="s0"
                      d="m398.5 229l5.9-7c5.3 4.6 10.7 7.2 17.5 7.2 6 0 9.9-2.9 9.9-7v-0.1c0-4-2.3-6.2-12.6-8.5-11.8-2.9-18.5-6.4-18.5-16.6v-0.2c0-9.5 7.9-16.1 19-16.1 8.1 0 14.5 2.4 20.2 7l-5.3 7.4c-5-3.8-10-5.8-15.1-5.8-5.7 0-9 3-9 6.6v0.2c0 4.3 2.5 6.2 13.2 8.7 11.8 2.9 17.8 7.1 17.8 16.3v0.2c0 10.4-8.2 16.6-19.8 16.6-8.5 0-16.6-3-23.2-8.9z"
                    />
                    <path
                      id="Layer"
                      className="s0"
                      d="m465.5 190.5h-17.6v-9h45.1v9h-17.7v46.6h-9.8z"
                    />
                    <path
                      id="Layer"
                      className="s0"
                      d="m591.1 216c7.7-2.2 13.2-7.7 13.2-16.8v-0.2c0-4.8-1.7-9-4.6-12-3.6-3.5-9.1-5.5-16.1-5.5h-24.7v55.6h9.7v-46.7h14.3c7.2 0 11.5 3.2 11.5 9.2v0.1c0 5.7-4.5 9.3-11.5 9.3h-7.9l19.5 28.1h11.6z"
                    />
                    <path
                      id="Layer"
                      className="s0"
                      d="m501.4 237.1h-9.9l24.4-56h9l24.5 56h-10.3l-18.9-44.4z"
                    />
                    <path
                      id="Layer"
                      className="s0"
                      d="m616.4 181.5h9.7v55.6h-9.7z"
                    />
                    <path
                      id="Layer"
                      className="s0"
                      d="m664.5 181.5h-12l-22.6 23.9 23.7 31.6h12l-24.1-31.6z"
                    />
                  </g>
                  <g id="Layer">
                    <g id="Layer">
                      <path
                        id="Layer"
                        className="s1"
                        d="m262.6 181.4v38.4l-29.7-38.4h-11.7l2.7 3.5 16.9 21.8v0.1l23.3 30.2h8.2v-55.6z"
                      />
                      <path
                        id="Layer"
                        className="s1"
                        d="m223.9 197.1v39.9h9.6v-27.4z"
                      />
                    </g>
                    <path
                      id="Layer"
                      fill-rule="evenodd"
                      className="s1"
                      d="m56.9 237.9q-6.5 0-11.8-2.3-5.3-2.2-9.1-6.1-3.8-3.9-6-9.1-2.1-5.2-2.1-11.1v-0.1q0-5.9 2.1-11.1 2.2-5.2 6.1-9.1 3.9-4 9.2-6.3 5.3-2.3 11.7-2.3 6.5 0 11.8 2.3 5.3 2.2 9.1 6.1 3.8 3.9 6 9.1 2.1 5.2 2.1 11.1v0.2q0 5.8-2.1 11-2.2 5.2-6.1 9.2-3.9 3.9-9.2 6.2-5.3 2.3-11.7 2.3zm0.1-9q4.1 0 7.6-1.5 3.4-1.6 5.9-4.2 2.4-2.7 3.8-6.3 1.4-3.5 1.4-7.6v-0.1q0-4.1-1.4-7.7-1.4-3.6-3.9-6.3-2.5-2.6-5.9-4.2-3.5-1.6-7.6-1.6-4.1 0-7.6 1.5-3.4 1.6-5.9 4.3-2.4 2.6-3.8 6.2-1.4 3.6-1.4 7.6v0.2q0 4 1.4 7.6 1.4 3.6 3.9 6.3 2.5 2.7 5.9 4.2 3.5 1.6 7.6 1.6z"
                    />
                    <path
                      id="Layer"
                      fill-rule="evenodd"
                      className="s1"
                      d="m184.1 237.9q-6.5 0-11.8-2.3-5.3-2.2-9.1-6.1-3.8-3.9-6-9.1-2.1-5.2-2.1-11.1v-0.1q0-5.9 2.1-11.1 2.2-5.2 6.1-9.1 3.9-4 9.2-6.3 5.3-2.3 11.7-2.3 6.5 0 11.8 2.3 5.3 2.2 9.1 6.1 3.8 3.9 6 9.1 2.1 5.2 2.1 11.1v0.2q0 5.8-2.1 11-2.2 5.2-6.1 9.2-3.8 3.9-9.2 6.2-5.3 2.3-11.7 2.3zm0.1-9q4.2 0 7.6-1.5 3.4-1.6 5.9-4.2 2.4-2.7 3.8-6.3 1.4-3.5 1.4-7.6v-0.1q0-4.1-1.4-7.7-1.4-3.6-3.9-6.3-2.4-2.6-5.9-4.2-3.5-1.6-7.6-1.6-4.1 0-7.6 1.5-3.4 1.6-5.9 4.3-2.4 2.6-3.8 6.2-1.4 3.6-1.4 7.6v0.2q0 4 1.4 7.6 1.4 3.6 3.9 6.3 2.5 2.7 5.9 4.2 3.5 1.6 7.6 1.6z"
                    />
                    <path
                      id="Layer"
                      className="s1"
                      d="m299.1 190.4h-17.7v-9h45.1v9h-17.6v46.6h-9.8z"
                    />
                    <path
                      id="Layer"
                      fill-rule="evenodd"
                      className="s1"
                      d="m362.2 237.9q-6.5 0-11.8-2.3-5.3-2.2-9.1-6.1-3.8-3.9-6-9.1-2.1-5.2-2.1-11.1v-0.1q0-5.9 2.1-11.1 2.2-5.2 6.1-9.1 3.9-4 9.2-6.3 5.3-2.3 11.7-2.3 6.5 0 11.8 2.3 5.3 2.2 9.1 6.1 3.8 3.9 6 9.1 2.1 5.2 2.1 11.1v0.2q0 5.8-2.1 11-2.2 5.2-6.1 9.2-3.8 3.9-9.2 6.2-5.3 2.3-11.7 2.3zm0.1-9q4.2 0 7.6-1.5 3.4-1.6 5.9-4.2 2.4-2.7 3.8-6.3 1.4-3.5 1.4-7.6v-0.1q0-4.1-1.4-7.7-1.4-3.6-3.9-6.3-2.4-2.6-5.9-4.2-3.5-1.6-7.6-1.6-4.1 0-7.6 1.5-3.4 1.6-5.9 4.3-2.4 2.6-3.8 6.2-1.4 3.6-1.4 7.6v0.2q0 4 1.4 7.6 1.4 3.6 3.9 6.3 2.5 2.7 5.9 4.2 3.5 1.6 7.6 1.6z"
                    />
                    <path
                      id="Layer"
                      className="s1"
                      d="m144.8 198.1q-2.2-5.1-6.1-8.8-3.9-3.7-9.4-5.8-5.4-2.1-11.9-2.1h-20.7v8.8h9.7 10.9q4.4 0 8 1.4 3.5 1.4 6 4 2.5 2.5 3.9 6 1.4 3.5 1.4 7.5v0.2q0 4.1-1.4 7.5-1.4 3.5-3.9 6-2.5 2.5-6 3.9-3.6 1.4-8 1.4h-10.9v-23.2h-9.7v32h20.7q6.5 0 11.9-2.1 5.5-2.1 9.4-5.9 3.9-3.7 6.1-8.8 2.1-5.1 2.1-11v-0.1q0-5.9-2.1-10.9z"
                    />
                  </g>
                </g>
                <g id="Layer">
                  <g id="Layer">
                    <path
                      id="Layer"
                      className="s2"
                      d="m304.9 88.5l-6-14.1-3.1-20.3 2.2-16.4 7.6-10.4 11.7-3.9 19.2 5.9 11.5 1.4 25-6.3 13.2 2.2 10.6 9.9 0.7 18.6-2.1 18.8-5.9 17.6-1.3 9.6-0.4 19.5-1.8 14.5-13.7 14.5-10.5-2.4 2.9-24.2-7.8-18.5-8.7-3.6-15.7 9.9-4.8 11.1-0.8 13 2 12.7-10.5 1.8-12.5-16.7-2.8-17.8z"
                    />
                    <path
                      id="Layer"
                      className="s2"
                      d="m305.6 27.3l12.7 12.8 19.2 4.6 31.8 11.8 26.1 17.4-18.1 3.7-25.9 9.7-18.9 8.5-18.5-0.3-8.6-5.7"
                    />
                    <path
                      id="Layer"
                      className="s2"
                      d="m317.3 23.4l1 16.7-10.2 18.7-9.2 15.6"
                    />
                    <path
                      id="Layer"
                      className="s2"
                      d="m298.2 36.5l19.6 2.7 18.7-9.9 1.5 18 5.1 15.2 9.1 24.2 5 18.5"
                    />
                    <path
                      id="Layer"
                      className="s2"
                      d="m297 75.5l24.3-6.2 21.8-6.8 27.9-5.7 26.6-2.9"
                    />
                    <path
                      id="Layer"
                      className="s2"
                      d="m369.9 57.5l-21.9-26.8-10.5 15-28.8 12.2-12.9-3.8 22-14.9"
                    />
                    <path
                      id="Layer"
                      className="s2"
                      d="m348 30.7l34.1 7.4 14.7-1.6"
                    />
                    <path
                      id="Layer"
                      className="s2"
                      d="m386.5 26.1l-4.4 12-9.1-13.7"
                    />
                    <path
                      id="Layer"
                      className="s2"
                      d="m373.7 24.6l-4.4 31.9 8 21.1-34.2-15.1-34.4-4.6 11.8 11.9 30.9 17.5 17.9-30.8 12.8-18.4"
                    />
                    <path
                      id="Layer"
                      className="s2"
                      d="m337.5 45.7l-16.2 23.6-16.5 19.2"
                    />
                    <path
                      id="Layer"
                      className="s2"
                      d="m320.5 69.8l-6.5 25.7-10.7 19.2 24 7.2-21.3 10.5 20.9 2.3-8.7 14.6"
                    />
                    <path
                      id="Layer"
                      className="s2"
                      d="m331.7 96l1 15.3-18.7-15.8 13.3 26.4"
                    />
                    <path
                      id="Layer"
                      className="s2"
                      d="m377.3 77.6l12.2 13.9-37.3-3.2 36 12.8-31.3 3.4"
                    />
                    <path
                      id="Layer"
                      className="s2"
                      d="m320.6 70.6l11.9 25.2 15.4 5.5 4.3-13"
                    />
                    <path
                      id="Layer"
                      className="s2"
                      d="m388 119.9l-23.5 3.2 21.7 11.3 1.8-14.5-30.8-14.7"
                    />
                    <path id="Layer" className="s2" d="m364.5 123.1l7.4 26.2" />
                  </g>
                  <g id="Layer">
                    <path
                      id="Layer"
                      className="s0"
                      d="m382.1 41.8c-2.1 0-3.7-1.7-3.7-3.7 0-2.1 1.6-3.8 3.7-3.8 2.1 0 3.7 1.7 3.7 3.8 0 2-1.6 3.7-3.7 3.7z"
                    />
                    <path
                      id="Layer"
                      className="s0"
                      d="m373.7 26.8c-1.3 0-2.2-1-2.2-2.2 0-1.3 0.9-2.3 2.2-2.3 1.2 0 2.2 1 2.2 2.3 0 1.2-1 2.2-2.2 2.2z"
                    />
                    <path
                      id="Layer"
                      className="s0"
                      d="m386.5 29.2c-1.7 0-3-1.4-3-3.1 0-1.7 1.3-3.1 3-3.1 1.7 0 3 1.4 3 3.1 0 1.7-1.3 3.1-3 3.1z"
                    />
                    <path
                      id="Layer"
                      className="s0"
                      d="m396.8 40.3c-2.1 0-3.7-1.7-3.7-3.8 0-2.1 1.6-3.7 3.7-3.7 2 0 3.7 1.6 3.7 3.7 0 2.1-1.7 3.8-3.7 3.8z"
                    />
                    <path
                      id="Layer"
                      className="s0"
                      d="m395.4 76.2c-1.2 0-2.2-1-2.2-2.3 0-1.2 1-2.2 2.2-2.2 1.3 0 2.2 1 2.2 2.2 0 1.3-0.9 2.3-2.2 2.3z"
                    />
                    <path
                      id="Layer"
                      className="s0"
                      d="m397.6 57c-1.6 0-3-1.4-3-3.1 0-1.7 1.4-3 3-3 1.7 0 3.1 1.3 3.1 3 0 1.7-1.4 3.1-3.1 3.1z"
                    />
                    <path
                      id="Layer"
                      className="s0"
                      d="m377.3 81.4c-2.1 0-3.7-1.7-3.7-3.8 0-2.1 1.6-3.8 3.7-3.8 2 0 3.7 1.7 3.7 3.8 0 2.1-1.7 3.8-3.7 3.8z"
                    />
                    <path
                      id="Layer"
                      className="s0"
                      d="m389.7 94.4c-2.1 0-3.7-1.6-3.7-3.7 0-2.1 1.6-3.8 3.7-3.8 2.1 0 3.7 1.7 3.7 3.8 0 2.1-1.6 3.7-3.7 3.7z"
                    />
                    <path
                      id="Layer"
                      className="s0"
                      d="m388.2 103.3c-1.2 0-2.2-1-2.2-2.2 0-1.2 1-2.2 2.2-2.2 1.2 0 2.2 1 2.2 2.2 0 1.2-1 2.2-2.2 2.2z"
                    />
                    <path
                      id="Layer"
                      className="s0"
                      d="m388 123c-1.7 0-3-1.4-3-3.1 0-1.7 1.3-3.1 3-3.1 1.7 0 3.1 1.4 3.1 3.1 0 1.7-1.4 3.1-3.1 3.1z"
                    />
                    <g id="Layer">
                      <path
                        id="Layer"
                        className="s0"
                        d="m369.3 60.9c-2.5 0-4.4-1.9-4.4-4.4 0-2.5 1.9-4.4 4.4-4.4 2.4 0 4.4 1.9 4.4 4.4 0 2.5-2 4.4-4.4 4.4z"
                      />
                      <path
                        id="Layer"
                        className="s0"
                        d="m343.1 66.2c-2.1 0-3.7-1.6-3.7-3.7 0-2.1 1.6-3.8 3.7-3.8 2.1 0 3.7 1.7 3.7 3.8 0 2.1-1.6 3.7-3.7 3.7z"
                      />
                      <path
                        id="Layer"
                        className="s0"
                        d="m337.5 50.2c-2.4 0-4.4-2-4.4-4.5 0-2.4 2-4.4 4.4-4.4 2.5 0 4.4 2 4.4 4.4 0 2.5-1.9 4.5-4.4 4.5z"
                      />
                      <path
                        id="Layer"
                        className="s0"
                        d="m348 33.8c-1.7 0-3-1.4-3-3.1 0-1.7 1.3-3.1 3-3.1 1.7 0 3 1.4 3 3.1 0 1.7-1.3 3.1-3 3.1z"
                      />
                      <path
                        id="Layer"
                        className="s0"
                        d="m336.5 31.6c-1.2 0-2.2-1-2.2-2.3 0-1.2 1-2.2 2.2-2.2 1.2 0 2.2 1 2.2 2.2 0 1.3-1 2.3-2.2 2.3z"
                      />
                      <path
                        id="Layer"
                        className="s0"
                        d="m332.5 99.6c-2.1 0-3.8-1.7-3.8-3.8 0-2.1 1.7-3.8 3.8-3.8 2 0 3.7 1.7 3.7 3.8 0 2.1-1.7 3.8-3.7 3.8z"
                      />
                      <path
                        id="Layer"
                        className="s0"
                        d="m352 90.3c-1.7 0-3-1.3-3-3 0-1.7 1.3-3.1 3-3.1 1.7 0 3.1 1.4 3.1 3.1 0 1.7-1.4 3-3.1 3z"
                      />
                      <path
                        id="Layer"
                        className="s0"
                        d="m314 97.7c-1.2 0-2.2-1-2.2-2.2 0-1.3 1-2.3 2.2-2.3 1.2 0 2.2 1 2.2 2.3 0 1.2-1 2.2-2.2 2.2z"
                      />
                      <path
                        id="Layer"
                        className="s0"
                        d="m347.9 103.6c-1.2 0-2.2-1-2.2-2.3 0-1.2 1-2.2 2.2-2.2 1.2 0 2.2 1 2.2 2.2 0 1.3-1 2.3-2.2 2.3z"
                      />
                      <path
                        id="Layer"
                        className="s0"
                        d="m360.2 105.2c0 1.7-1.3 3.1-3 3.1-1.7 0-3-1.4-3-3.1 0-1.7 1.3-3.1 3-3.1 1.7 0 3 1.4 3 3.1z"
                      />
                      <path
                        id="Layer"
                        className="s0"
                        d="m332.7 113.6c-1.2 0-2.2-1-2.2-2.3 0-1.2 1-2.2 2.2-2.2 1.2 0 2.2 1 2.2 2.2 0 1.3-1 2.3-2.2 2.3z"
                      />
                      <path
                        id="Layer"
                        className="s0"
                        d="m327.3 124.9c-1.7 0-3-1.3-3-3 0-1.7 1.3-3.1 3-3.1 1.7 0 3 1.4 3 3.1 0 1.7-1.3 3-3 3z"
                      />
                      <g id="Layer">
                        <path
                          id="Layer"
                          className="s0"
                          d="m298.2 41c-2.4 0-4.4-2-4.4-4.5 0-2.4 2-4.4 4.4-4.4 2.4 0 4.4 2 4.4 4.4 0 2.5-2 4.5-4.4 4.5z"
                        />
                        <path
                          id="Layer"
                          className="s0"
                          d="m317.8 43c-2.1 0-3.7-1.7-3.7-3.8 0-2 1.6-3.7 3.7-3.7 2 0 3.7 1.7 3.7 3.7 0 2.1-1.7 3.8-3.7 3.8z"
                        />
                        <path
                          id="Layer"
                          className="s0"
                          d="m317.3 27.1c-2.1 0-3.8-1.7-3.8-3.7 0-2.1 1.7-3.8 3.8-3.8 2 0 3.7 1.7 3.7 3.8 0 2-1.7 3.7-3.7 3.7z"
                        />
                        <path
                          id="Layer"
                          className="s0"
                          d="m305.6 29.5c-1.2 0-2.2-1-2.2-2.2 0-1.2 1-2.2 2.2-2.2 1.2 0 2.2 1 2.2 2.2 0 1.2-1 2.2-2.2 2.2z"
                        />
                        <path
                          id="Layer"
                          className="s0"
                          d="m295.8 56.3c-1.2 0-2.2-1-2.2-2.2 0-1.2 1-2.2 2.2-2.2 1.2 0 2.2 1 2.2 2.2 0 1.2-1 2.2-2.2 2.2z"
                        />
                        <path
                          id="Layer"
                          className="s0"
                          d="m298.9 78.9c-2.5 0-4.4-2-4.4-4.5 0-2.4 1.9-4.4 4.4-4.4 2.4 0 4.3 2 4.3 4.4 0 2.5-1.9 4.5-4.3 4.5z"
                        />
                        <path
                          id="Layer"
                          className="s0"
                          d="m308.7 61.6c-2.1 0-3.8-1.7-3.8-3.7 0-2.1 1.7-3.8 3.8-3.8 2 0 3.7 1.7 3.7 3.8 0 2-1.7 3.7-3.7 3.7z"
                        />
                        <path
                          id="Layer"
                          className="s0"
                          d="m320.5 72.9c-1.7 0-3.1-1.4-3.1-3.1 0-1.7 1.4-3 3.1-3 1.7 0 3 1.3 3 3 0 1.7-1.3 3.1-3 3.1z"
                        />
                        <path
                          id="Layer"
                          className="s0"
                          d="m304.8 91.6c-1.7 0-3.1-1.4-3.1-3.1 0-1.7 1.4-3 3.1-3 1.6 0 3 1.3 3 3 0 1.7-1.4 3.1-3 3.1z"
                        />
                        <path
                          id="Layer"
                          className="s0"
                          d="m303.3 119.1c-2.4 0-4.4-2-4.4-4.4 0-2.5 2-4.5 4.4-4.5 2.5 0 4.4 2 4.4 4.5 0 2.4-1.9 4.4-4.4 4.4z"
                        />
                        <path
                          id="Layer"
                          className="s0"
                          d="m318.2 152.3c-1.7 0-3.1-1.3-3.1-3 0-1.7 1.4-3.1 3.1-3.1 1.7 0 3 1.4 3 3.1 0 1.7-1.3 3-3 3z"
                        />
                        <path
                          id="Layer"
                          className="s0"
                          d="m328.9 150.2c-1.2 0-2.2-1-2.2-2.2 0-1.2 1-2.2 2.2-2.2 1.2 0 2.2 1 2.2 2.2 0 1.2-1 2.2-2.2 2.2z"
                        />
                        <path
                          id="Layer"
                          className="s0"
                          d="m309.1 132.4c0 1.7-1.4 3-3.1 3-1.6 0-3-1.3-3-3 0-1.7 1.4-3.1 3-3.1 1.7 0 3.1 1.4 3.1 3.1z"
                        />
                      </g>
                      <path
                        id="Layer"
                        className="s0"
                        d="m366.2 146.7c0 2.5-1.9 4.5-4.4 4.5-2.4 0-4.4-2-4.4-4.5 0-2.4 2-4.4 4.4-4.4 2.5 0 4.4 2 4.4 4.4z"
                      />
                      <path
                        id="Layer"
                        className="s0"
                        d="m371.9 152.3c-1.7 0-3.1-1.3-3.1-3 0-1.7 1.4-3.1 3.1-3.1 1.7 0 3 1.4 3 3.1 0 1.7-1.3 3-3 3z"
                      />
                      <path
                        id="Layer"
                        className="s0"
                        d="m364.5 126.2c-1.6 0-3-1.4-3-3.1 0-1.7 1.4-3 3-3 1.7 0 3.1 1.3 3.1 3 0 1.7-1.4 3.1-3.1 3.1z"
                      />
                      <path
                        id="Layer"
                        className="s0"
                        d="m326.9 136.9c-1.2 0-2.2-1-2.2-2.2 0-1.3 1-2.3 2.2-2.3 1.2 0 2.2 1 2.2 2.3 0 1.2-1 2.2-2.2 2.2z"
                      />
                    </g>
                    <path
                      id="Layer"
                      className="s0"
                      d="m390.5 134.4c0 2.5-1.9 4.5-4.3 4.5-2.5 0-4.4-2-4.4-4.5 0-2.4 1.9-4.4 4.4-4.4 2.4 0 4.3 2 4.3 4.4z"
                    />
                  </g>
                </g>
              </g>
            </g>
          </svg>
          <p className="mt-2 text-center text-sm text-gray-600">
            Tus Pacientes en un solo lugar
          </p>
        </div>
        <div className="mt-4">
          <div className="-space-y-px rounded-md shadow-sm">
            <div>
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                onChange={(e) => setEmail(e.target.value)}
                className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                placeholder="Email address"
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>

          <div className="mt-10">
            <button
              onClick={logIn}
              className="group relative flex w-full justify-center rounded-md border border-transparent bg-sky-500 py-2 px-4 text-sm font-medium text-white hover:bg-sky-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Iniciar Sesion
              {isLoading && <Spinner size="small" />}
            </button>
          </div>

          <div className="flex flex-col items-center justify-center mt-3">
            <div className="text-sm">
              <a
                onClick={passwordReset}
                className="font-medium text-sky-600 hover:text-sky-500"
              >
                Reestablecer contraseña
              </a>
            </div>
            <div className="text-sm">
              <Link
                to="/registrarse"
                className="font-medium text-sky-600 hover:text-sky-500"
              >
                Crear cuenta
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
