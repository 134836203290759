import React from "react";
import ImgCard from "./ImgCard";
import Spinner from "../../../../UI/Spinner";

const AllImgs = ({ allImgs, setEditImg, setAllImgs }) => {
  return (
    <div className="mx-5 h-full bg-white rounded-lg">
      <div className="overflow-scroll">
        <div className="flex w-full h-[56vh] flex-wrap">
          {allImgs ? (
            allImgs.map((e, i) => {
              return (
                <ImgCard
                  key={i}
                  url={e.imageUrl}
                  id={e.id}
                  title={e.title}
                  description={e.description}
                  setEditImg={setEditImg}
                  imgObj={e}
                  setAllImgs={setAllImgs}
                  allImgs={allImgs}
                />
              );
            })
          ) : (
            <div
              className="flex items-center justify-center"
              style={{ height: "50vh", width: "100vw" }}
            >
              <Spinner />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AllImgs;
