import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { RocketLaunchIcon, HeartIcon } from "@heroicons/react/24/outline";
import { useSelector } from "react-redux";
import { sendFeedback } from "../../firebase/firebase.feedback";

const FeedbackModal = () => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [topic, setTopic] = useState("");
  const [message, setMessage] = useState("");
  const cancelButtonRef = useRef(null);
  const user = useSelector((state) => state.user?.userDetails);

  const sendFeedbackHandler = async () => {
    let feedbackSent = await sendFeedback(
      { name: name, topic: topic, message: message, date: new Date() },
      user,
      setOpen
    );
  };

  return (
    <>
      <div onClick={() => setOpen(true)}>
        <RocketLaunchIcon
          className="w-5 h-10 mr-5"
          aria-hidden="true"
          color="white"
        />
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div
              className="flex min-h-full items-end p-4 text-center sm:items-center sm:p-0"
              style={{ justifyContent: "center" }}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div
                        className="mx-auto flex h-12 w-12 flex-shrink-0 items-center rounded-full bg-red-400 sm:mx-0 sm:h-10 sm:w-10"
                        style={{ justifyContent: "center" }}
                      >
                        <HeartIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          {`Nos encanta escucharte 🎉`}
                        </Dialog.Title>
                        <p className="text-sm mt-2">
                          Sea feedback, ideas, o sugerencias, estamos encantados
                          con tus comentarios.
                        </p>
                        <div className="mt-2">
                          <div>
                            <div className="mt-10 mr-2 flex flex-col">
                              <div className="sm:col-span-2">
                                <label
                                  htmlFor="name"
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                  Nombre
                                </label>
                                <div className="mt-2">
                                  <input
                                    required
                                    type="text"
                                    name="NombreDeLaPraxis"
                                    id="name"
                                    autoComplete="name"
                                    onChange={(e) => setName(e.target.value)}
                                    value={name}
                                    className="block w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>

                              <div className="sm:col-span-2">
                                <label
                                  htmlFor="topic"
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                  Asunto
                                </label>
                                <div className="mt-2">
                                  <input
                                    required
                                    type="text"
                                    name="topic"
                                    id="topic"
                                    autoComplete="tel"
                                    value={topic}
                                    onChange={(e) => setTopic(e.target.value)}
                                    className="block w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>

                              <div className="sm:col-span-2">
                                <label
                                  htmlFor="praxisAddresss"
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                  Mensaje
                                </label>
                                <textarea
                                  id="about"
                                  name="about"
                                  rows={3}
                                  onChange={(e) => setMessage(e.target.value)}
                                  value={message}
                                  placeholder="Sin pelos en la lengua 🤓"
                                  className="block w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 mr-2 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-700 sm:ml-3 sm:w-auto"
                      onClick={sendFeedbackHandler}
                    >
                      Enviar
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Cancelar
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default FeedbackModal;
