import { Fragment, useEffect, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";
import AddPatientModal from "../patient/addPatient/AddPatientModal";

export default function PatientsDropdown({
  data,
  currentValueSetter,
  defaultValue,
}) {
  const [selected, setSelected] = useState(defaultValue);
  const [query, setQuery] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [filteredPeople, setFilteredPeople] = useState(data);

  useEffect(() => {
    if (JSON.stringify(selected) !== JSON.stringify(defaultValue)) {
      currentValueSetter(selected);
    }
  }, [selected]);

  useEffect(() => {
    if (data) {
      const newData =
        query === ""
          ? data
          : data.filter((person) =>
              person.name
                .toLowerCase()
                .replace(/\s+/g, "")
                .includes(query.toLowerCase().replace(/\s+/g, ""))
            );

      setFilteredPeople(newData);
    }
  }, [data, query]);

  return (
    <>
      <Combobox value={selected} onChange={setSelected}>
        <div className="relative mt-1">
          <div className="flex">
            <div className="relative p-2 flex w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
              <Combobox.Input
                className="w-full flex border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                displayValue={(person) => (selected ? person.name : "")}
                onChange={(event) => setQuery(event.target.value)}
              />
              {/* <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Combobox.Button> */}
              <div
                className="m-1 p-1 flex w-4/12 text-white rounded-md justify-center items-center"
                style={{ backgroundColor: "#253141" }}
                onClick={() => setOpenModal(true)}
              >
                <div className="pr-1 font-bold">+</div>
                <div className="px-1 hidden w-fit sm:flex md:flex lg:flex xl:flex 2xl:flex text-xs">
                  Nuevo Paciente
                </div>
              </div>
            </div>
          </div>

          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery("")}
          >
            <Combobox.Options className="z-10 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
              {filteredPeople.length === 0 && query !== "" ? (
                <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                  No hay resultados
                </div>
              ) : (
                filteredPeople.map((person) => (
                  <Combobox.Option
                    key={person.id}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active ? "bg-teal-600 text-white" : "text-gray-900"
                      }`
                    }
                    value={person}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex">
                          <span
                            className={`block truncate m-1 ${
                              selected ? "font-medium" : "font-normal"
                            }`}
                          >
                            {person.name}
                          </span>
                          <span
                            className={`block truncate m-1 ${
                              selected ? "font-medium" : "font-normal"
                            }`}
                          >
                            {person?.nationality
                              ? `- ${person?.nationality} `
                              : ""}
                            {person?.birthdate ? `- ${person?.birthdate} ` : ""}
                          </span>
                        </div>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? "text-white" : "text-teal-600"
                            }`}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
      {openModal && (
        <AddPatientModal modalState={openModal} modalSetter={setOpenModal} />
      )}
    </>
  );
}
