import React, { useEffect, useState } from "react";

const PatientQuestionaire = ({ questionaire, questionaireSetter }) => {
  const [newQuestionaire, setNewQuestionaire] = useState();
  const [
    isCurrentlyUnderMedicalTreatment,
    setIsCurrentlyUnderMedicalTreatment,
  ] = useState({ isMedicalTreatment: false, treatments: "" });
  const [isUsingMedicine, setUsingMedicine] = useState({
    isUsingMedicine: false,
    medicines: "",
  });
  const [needsBloodTransf, setNeedsBloodTransf] = useState({
    needsBloodTransf: false,
    reasons: "",
  });
  const [hadSurgery, setHadSurgery] = useState({
    hadSurgery: false,
    which: "",
  });
  const [alergyToMedicine, setAlergyToMedicine] = useState({
    alergyToMedicine: false,
    which: "",
  });
  const [smokes, setSmokes] = useState({
    smokes: false,
    howLong: "",
    howMany: "",
  });
  const [drinks, setDrinks] = useState({
    drinks: false,
    howLong: "",
    howMany: "",
  });
  const [pregnant, setPregnant] = useState({ pregnant: false, weeks: 0 });
  const [canUseAnestesia, setCanUseAnestesia] = useState(false);
  //   const [praxtisId, setPraxisId] = useState(null);

  const newQuestionaireObj = {
    isCurrentlyUnderMedicalTreatment: isCurrentlyUnderMedicalTreatment,
    isUsingMedicine: isUsingMedicine,
    needsBloodTransf: needsBloodTransf,
    hadSurgery: hadSurgery,
    alergyToMedicine: alergyToMedicine,
    smokes: smokes,
    drinks: drinks,
    pregnant: pregnant,
    canUseAnestesia: canUseAnestesia,
  };

  useEffect(() => {
    questionaireSetter(newQuestionaireObj);
  }, [
    isCurrentlyUnderMedicalTreatment,
    isUsingMedicine,
    needsBloodTransf,
    hadSurgery,
    alergyToMedicine,
    smokes,
    drinks,
    pregnant,
    canUseAnestesia,
  ]);

  return (
    <div className="px-5 pb-5">
      <div className="flex items-center pt-3">
        <div className="flex flex-col">
          <div className="flex">
            <input
              type="checkbox"
              id="medicalTreatment"
              className="w-4 h-4 text-black bg-gray-300 border-none rounded-md focus:ring-transparent"
              checked={isCurrentlyUnderMedicalTreatment.isMedicalTreatment}
              onChange={(e) =>
                setIsCurrentlyUnderMedicalTreatment({
                  isMedicalTreatment: e.target.checked,
                  treatments: [],
                })
              }
              //disabled={!editModeState}
            />
            <label
              htmlFor="medicalTreatment"
              className="block ml-2 text-sm text-gray-900"
            >
              ¿Está siendo sometido a algún tratamiento médico actualmente?
            </label>
          </div>
          <div className="">
            {isCurrentlyUnderMedicalTreatment.isMedicalTreatment &&
              isCurrentlyUnderMedicalTreatment.isMedicalTreatment.length > 0 &&
              isCurrentlyUnderMedicalTreatment.medicines.map((e) => (
                <input
                  checked={e}
                  key={e} //disabled={!editModeState}
                />
              ))}
          </div>
        </div>
      </div>
      <div className="flex items-center pt-3">
        <div className="flex flex-col">
          <div className="flex">
            <input
              type="checkbox"
              id="useOfMedicine"
              className="w-4 h-4 text-black bg-gray-300 border-none rounded-md focus:ring-transparent"
              checked={isUsingMedicine.isUsingMedicine}
              onChange={(e) =>
                setUsingMedicine({
                  isUsingMedicine: e.target.checked,
                  medicines: [],
                })
              }
              //disabled={!editModeState}
            />
            <label
              htmlFor="useOfMedicine"
              className="block ml-2 text-sm text-gray-900"
            >
              ¿Está haciendo uso de algún medicamento?
            </label>
          </div>
          <div className="flex flex-col">
            {isUsingMedicine.isUsingMedicine && (
              <>
                <input
                  placeholder="¿Cuáles?"
                  onChange={(e) => {
                    setUsingMedicine({
                      isUsingMedicine: isUsingMedicine.isUsingMedicine,
                      medicines: e.target.value,
                    });
                  }}
                  className="mt-3 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  //disabled={!editModeState}
                  defaultValue={isUsingMedicine.medicines}
                />
              </>
            )}
          </div>
        </div>
      </div>
      <div className="flex items-center pt-3">
        <div className="flex flex-col">
          <div className="flex">
            <input
              type="checkbox"
              id="bloodTreatment"
              className="w-4 h-4 text-black bg-gray-300 border-none rounded-md focus:ring-transparent"
              checked={needsBloodTransf.needsBloodTransf}
              onChange={(e) =>
                setNeedsBloodTransf({
                  needsBloodTransf: e.target.checked,
                  reasons: [],
                })
              }
              //disabled={!editModeState}
            />
            <label
              htmlFor="bloodTreatment"
              className="block ml-2 text-sm text-gray-900"
            >
              ¿Necesita o necesitó periódicamente de transfusión sanguínea o
              derivados?
            </label>
          </div>
          <div className="">
            {needsBloodTransf.needsBloodTransf && (
              <>
                <input
                  placeholder="¿Motivo?"
                  onChange={(e) =>
                    setNeedsBloodTransf({
                      needsBloodTransf: needsBloodTransf.needsBloodTransf,
                      reasons: e.target.value,
                    })
                  }
                  className="mt-3 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  //disabled={!editModeState}
                  defaultValue={needsBloodTransf.reasons}
                />
              </>
            )}
          </div>
        </div>
      </div>
      <div className="flex items-center pt-3">
        <div className="flex flex-col">
          <div className="flex">
            <input
              type="checkbox"
              id="surgery"
              className="w-4 h-4 text-black bg-gray-300 border-none rounded-md focus:ring-transparent"
              checked={hadSurgery.hadSurgery}
              onChange={(e) =>
                setHadSurgery({
                  hadSurgery: e.target.checked,
                  which: "",
                })
              }
              //disabled={!editModeState}
            />
            <label
              htmlFor="surgery"
              className="block ml-2 text-sm text-gray-900"
            >
              ¿Fue sometido a alguna cirugía?
            </label>
          </div>
          <div className="">
            {hadSurgery.hadSurgery && (
              <>
                <input
                  placeholder="¿Cuál?"
                  onChange={(e) =>
                    setHadSurgery({
                      hadSurgery: hadSurgery.hadSurgery,
                      which: e.target.value,
                    })
                  }
                  className="mt-3 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  //disabled={!editModeState}
                  defaultValue={hadSurgery.which}
                />
              </>
            )}
          </div>
        </div>
      </div>

      <div className="flex items-center pt-3">
        <div className="flex flex-col">
          <div className="flex">
            <input
              type="checkbox"
              className="w-4 h-4 text-black bg-gray-300 border-none rounded-md focus:ring-transparent"
              checked={alergyToMedicine.alergyToMedicine}
              onChange={(e) =>
                setAlergyToMedicine({
                  alergyToMedicine: e.target.checked,
                  which: "",
                })
              }
              //disabled={!editModeState}
            />
            <label
              htmlFor="safeAdress"
              className="block ml-2 text-sm text-gray-900"
            >
              ¿Presenta alergia a algún alimento o medicamento?
            </label>
          </div>
          <div className="">
            {alergyToMedicine.alergyToMedicine && (
              <>
                <input
                  placeholder="¿Cuál?"
                  onChange={(e) =>
                    setAlergyToMedicine({
                      alergyToMedicine: alergyToMedicine.alergyToMedicine,
                      which: e.target.value,
                    })
                  }
                  className="mt-3 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  //disabled={!editModeState}
                  defaultValue={alergyToMedicine.which}
                />
              </>
            )}
          </div>
        </div>
      </div>

      <div className="flex items-center pt-3">
        <div className="flex flex-col">
          <div className="flex">
            <input
              id="smoke"
              type="checkbox"
              className="w-4 h-4 text-black bg-gray-300 border-none rounded-md focus:ring-transparent"
              checked={smokes.smokes}
              onChange={(e) =>
                setSmokes({
                  smokes: e.target.checked,
                  howLong: 0,
                  howMany: 0,
                })
              }
              //disabled={!editModeState}
            />
            <label htmlFor="smoke" className="block ml-2 text-sm text-gray-900">
              ¿Fuma?
            </label>
          </div>
          <div className="">
            {smokes.smokes && (
              <>
                <div className="flex flex-col items-baseline m-2">
                  <label
                    htmlFor="howLong"
                    className="block ml-2 text-sm text-gray-900 mr-3"
                  >
                    ¿Hace cuanto tiempo? (En Meses)
                  </label>
                  <input
                    id="howLong"
                    className="w-24 h-8 shadow appearance-none border rounded text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    defaultValue={smokes.howLong}
                    onChange={(e) =>
                      setSmokes({
                        smokes: smokes.smokes,
                        howLong: e.target.value,
                        howMany: smokes.howMany,
                      })
                    }
                    //disabled={!editModeState}
                  />
                </div>
                <div className="flex flex-col items-baseline m-2">
                  <label
                    htmlFor="howMany"
                    className="block ml-2 text-sm text-gray-900 mr-3"
                  >
                    Cantidad por día
                  </label>
                  <input
                    id="howMany"
                    className="w-24 h-8 shadow appearance-none border rounded text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    defaultValue={smokes.howMany}
                    onChange={(e) =>
                      setSmokes({
                        smokes: smokes.smokes,
                        howLong: smokes.howLong,
                        howMany: e.target.value,
                      })
                    }
                    //disabled={!editModeState}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="flex items-center pt-3">
        <div className="flex flex-col">
          <div className="flex">
            <input
              type="checkbox"
              className="w-4 h-4 text-black bg-gray-300 border-none rounded-md focus:ring-transparent"
              checked={drinks.drinks}
              onChange={(e) =>
                setDrinks({
                  drinks: e.target.checked,
                  howLong: 0,
                  howMany: 0,
                })
              }
              //disabled={!editModeState}
            />
            <label
              htmlFor="safeAdress"
              className="block ml-2 text-sm text-gray-900"
            >
              ¿Toma bebidas alcohólicas?
            </label>
          </div>
          <div className="">
            {drinks.drinks && (
              <>
                <div className="flex m-2 flex-col items-baseline ">
                  <label
                    htmlFor="howLong"
                    className="block ml-2 text-sm text-gray-900 mr-3"
                  >
                    ¿Hace cuánto tiempo? (En Meses)
                  </label>
                  <input
                    id="howLong"
                    className="w-24 h-8 shadow appearance-none border rounded text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    defaultValue={drinks.howLong}
                    onChange={(e) =>
                      setDrinks({
                        drinks: drinks.drinks,
                        howLong: e.target.value,
                        howMany: 0,
                      })
                    }
                    //disabled={!editModeState}
                  />
                </div>
                <div className="flex flex-col items-baseline m-2">
                  <label
                    htmlFor="howMany"
                    className="block ml-2 text-sm text-gray-900 mr-3"
                  >
                    Cantidad por día
                  </label>
                  <input
                    id="howMany"
                    className="w-24 h-8 shadow appearance-none border rounded text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    defaultValue={drinks.howMany}
                    onChange={(e) =>
                      setDrinks({
                        drinks: drinks.drinks,
                        howLong: drinks.howLong,
                        howMany: e.target.value,
                      })
                    }
                    //disabled={!editModeState}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="flex items-center pt-3">
        <div className="flex flex-col">
          <div className="flex">
            <input
              type="checkbox"
              className="w-4 h-4 text-black bg-gray-300 border-none rounded-md focus:ring-transparent"
              checked={pregnant.pregnant}
              onChange={(e) =>
                setPregnant({
                  pregnant: e.target.checked,
                  weeks: 0,
                })
              }
              //disabled={!editModeState}
            />
            <label
              htmlFor="safeAdress"
              className="block ml-2 text-sm text-gray-900"
            >
              ¿Declara estar embarazada?
            </label>
          </div>
          <div className="">
            {pregnant.pregnant && (
              <div className="flex flex-col items-baseline m-2">
                <label
                  htmlFor="howLong"
                  className="block ml-2 text-sm text-gray-900 mr-3"
                >
                  ¿Cuántas semanas?
                </label>
                <input
                  defaultValue={pregnant.weeks}
                  id="howLong"
                  className="w-24 h-8 shadow appearance-none border rounded text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  onChange={(e) =>
                    setPregnant({
                      pregnant: pregnant.pregnant,
                      weeks: JSON.parse(e.target.defaultValue),
                    })
                  }
                  //disabled={!editModeState}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="flex items-center pt-3">
        <div className="flex flex-col">
          <div className="flex">
            <input
              type="checkbox"
              className="w-4 h-4 text-black bg-gray-300 border-none rounded-md focus:ring-transparent"
              checked={canUseAnestesia}
              onChange={(e) => setCanUseAnestesia(e.target.checked)}
              //disabled={!editModeState}
            />
            <label
              htmlFor="safeAdress"
              className="block ml-2 text-sm text-gray-900"
            >
              ¿Tolera la anestesia de uso odontológico?
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientQuestionaire;
