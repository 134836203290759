import React, { useState, useEffect } from "react";
import {
  deleteErrorLog,
  toggleActiveness,
} from "../../../firebase/firebase.errortracker";
import { XMarkIcon } from "@heroicons/react/24/outline";

const ErrorCard = ({ data, listSetter, list }) => {
  const [currentState, setCurrentState] = useState(null);
  const [currentLog, setCurrentLog] = useState(null);

  useEffect(() => {
    if (currentState !== currentLog?.isActive) {
      setCurrentState(currentLog?.isActive);
    }
  }, [currentLog]);

  useEffect(() => {
    if (currentLog !== data) {
      setCurrentLog(data);
    }
  }, [data]);

  const toggleActive = () => {
    let newData = currentLog;
    newData.isActive = !newData?.isActive;
    toggleActiveness(newData);
    setCurrentState(newData?.isActive);
  };

  const deleteError = () => {
    deleteErrorLog(currentLog.id);
    let newList = list.filter((e) => e.id !== currentLog.id);
    listSetter(newList);
  };

  return (
    <>
      {currentLog && (
        <div className="p-6 bg-white border border-gray-200 rounded-lg shadow flex">
          <div className="w-11/12">
            <div>
              <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900">
                {currentLog?.currentURL.split("3000")[0] ===
                "http://localhost:" ? (
                  <div className="text-slate-600">
                    {currentLog?.currentURL.split("3000")[1]}
                  </div>
                ) : (
                  <div className="text-slate-600 break-all">
                    {currentLog?.currentURL.split("https://")[1]}
                  </div>
                )}
              </h5>
            </div>
            <div className="flex mb-2">
              <p className="m-1 font-normal text-gray-700 text-xs">
                {" 🗓️ " +
                  currentLog?.timestamp.toDate()?.toISOString()?.split("T")[0]}
              </p>
              <p className="m-1 font-normal text-gray-700 text-xs">
                {" 🕓 " +
                  currentLog?.timestamp
                    .toDate()
                    ?.toISOString()
                    ?.split("T")[1]
                    .split(".")[0]}
              </p>
            </div>
            <div
              className="mb-3 font-normal text-gray-700 flex"
              onClick={toggleActive}
            >
              <div className="mr-2">State:</div>
              {currentState ? (
                <div className="text-green-600">Active</div>
              ) : (
                <div className="text-red-600">Inactive</div>
              )}
            </div>
            <p className="mb-3 font-normal text-gray-700 flex">
              <div className="mr-2">Praxis:</div>
              <div className="flex flex-col">
                <div className="text-slate-600 break-all">
                  {currentLog?.user?.praxis}
                </div>
                <div className="text-slate-600 break-all text-xs">
                  {currentLog?.user?.praxisId}
                </div>
              </div>
            </p>
            <p className="mb-3 font-normal text-gray-700 flex">
              <div className="mr-2">User:</div>
              {currentLog?.user && (
                <div className="flex flex-col">
                  <div className="text-slate-600 break-all">
                    {currentLog?.user?.name !== undefined
                      ? currentLog?.user?.name
                      : currentLog?.user}
                  </div>
                  <div className="text-slate-600 break-all text-xs">
                    {currentLog?.user?.id}
                  </div>
                </div>
              )}
            </p>
            <p className="mb-3 font-normal text-gray-700 flex">
              <div className="mr-2">Error:</div>
              {currentLog?.errorDetails == null ? (
                <div className="text-slate-600">Admin/Dev error</div>
              ) : (
                <div className="text-slate-600 text-ellipsis overflow-hidden w-30vw">
                  {currentLog?.errorDetails.split("")}
                </div>
              )}
            </p>
          </div>
          <div className="h-5 flex justify-center items-center w-1/12">
            <XMarkIcon
              className="h-6 w-6 -mr-8 -mt-5"
              aria-hidden="true"
              onClick={deleteError}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ErrorCard;
