import React from "react";

const WizardControllers = ({
  steps,
  prevStep,
  nextStep,
  currentStep,
  children,
  canContinue,
}) => {
  return (
    <div className={`mt-2`}>
      <div className="w-full flex justify-center my-5">
        <ol className="mx-8 flex items-center space-x-5">
          {steps.map((step, i) => (
            <li key={`step_${i}`}>
              {step.status === "complete" ? (
                <a
                  href={step.href}
                  className="block w-2.5 h-2.5 bg-indigo-600 rounded-full hover:bg-indigo-900"
                >
                  <span className="sr-only"></span>
                </a>
              ) : step.status === "current" ? (
                <a
                  href={step.href}
                  className="relative flex items-center justify-center"
                  aria-current="step"
                >
                  <span
                    className="absolute w-5 h-5 p-px flex"
                    aria-hidden="true"
                  >
                    <span className="w-full h-full rounded-full bg-indigo-200" />
                  </span>
                  <span
                    className="relative block w-2.5 h-2.5 bg-indigo-600 rounded-full"
                    aria-hidden="true"
                  />
                  <span className="sr-only"></span>
                </a>
              ) : (
                <a
                  href={step.href}
                  className="block w-2.5 h-2.5 bg-gray-200 rounded-full hover:bg-gray-400"
                >
                  <span className="sr-only"></span>
                </a>
              )}
            </li>
          ))}
        </ol>
      </div>
      <div className="h-60vh overflow-y-scroll">{children}</div>
      {canContinue && (
        <nav
          className="flex items-center justify-evenly w-full"
          aria-label="Progress"
        >
          <button
            type="button"
            disabled={currentStep === 0}
            onClick={() => prevStep()}
            className="p-1 m-1 bg-gray-400 w-1/12 border rounded rounded-md text-white flex justify-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
              />
            </svg>
          </button>

          <button
            type="button"
            disabled={!(currentStep < steps?.length - 1)}
            onClick={() => nextStep()}
            className={`p-1 m-1 ${
              currentStep < steps?.length - 1 ? "bg-gray-400" : "bg-gray-300"
            } w-1/12 border rounded rounded-md text-white flex justify-center`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
              />
            </svg>
          </button>
        </nav>
      )}
    </div>
  );
};

export default WizardControllers;
