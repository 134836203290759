import { createSlice } from "@reduxjs/toolkit";

export const clients = createSlice({
  name: "clients",
  initialState: {
    allClients: null,
    clientDetails: null,
  },
  reducers: {
    setAllClients: (state, action) => {
      state.allClients = action.payload;
    },
    setClientById: (state, action) => {
      state.clientDetails = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAllClients, setClientById } = clients.actions;

export default clients.reducer;
