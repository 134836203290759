import { useState } from "react";

const usePumble = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);

  const sendRegister = async (message) => {
    setLoading(true);
    setError(null);
    debugger;
    try {
      const response = await fetch(
        "https://api.pumble.com/workspaces/6692fe50e213cc172af89387/incomingWebhooks/postMessage/VZSsRN8w7NpBVcFNyGdmHJj8",
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
            "Api-Key": "d81ed712ddd04bf8caaa121dc46a175b",
          },
          body: JSON.stringify({
            text: message,
          }),
        }
      );
      debugger;
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      debugger;
      setResponse(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const sendLogin = async (message) => {
    setLoading(true);
    setError(null);
    debugger;
    try {
      const response = await fetch(
        "https://api.pumble.com/workspaces/6692fe50e213cc172af89387/incomingWebhooks/postMessage/kB2yHW55GUGzCWMYswGPTfxg",
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
            "Api-Key": "d81ed712ddd04bf8caaa121dc46a175b",
          },
          body: JSON.stringify({
            text: message,
          }),
        }
      );
      debugger;
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      debugger;
      setResponse(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return { loading, error, response, sendRegister, sendLogin };
};

export default usePumble;
