function calculateAge(birthDate) {
  const birthYear = birthDate.getFullYear();
  const birthMonth = birthDate.getMonth();
  const birthDay = birthDate.getDate();
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const currentDay = new Date().getDate();

  let ageYears = currentYear - birthYear;
  let ageMonths = currentMonth - birthMonth;
  let ageDays = currentDay - birthDay;

  // Adjust age if the birth month is in the future relative to the current month
  if (ageMonths < 0 || (ageMonths === 0 && ageDays < 0)) {
    ageYears--;
    ageMonths = 12 + ageMonths;

    // Calculate the correct number of days
    const daysInLastMonth = new Date(currentYear, currentMonth, 0).getDate();
    ageDays = daysInLastMonth + ageDays;
  }

  return {
    years: ageYears,
    months: ageMonths,
    days: ageDays,
  };
}

function getDaysTillAppointment(appointment, type) {
  const appointmentMonth = appointment.getMonth();
  const appointmentDay = appointment.getDate();
  const currentMonth = new Date().getMonth();
  const currentDay = new Date().getDate();
  const currentYear = new Date().getFullYear();

  let daysLeft;

  if (appointmentMonth > currentMonth) {
    // If appointment is in future month in the same year
    const currentDate = new Date();
    const daysInCurrentMonth = new Date(
      currentYear,
      currentMonth + 1,
      0
    ).getDate();
    const daysInNextMonth = new Date(
      currentYear,
      appointmentMonth + 1,
      0
    ).getDate();
    daysLeft = daysInCurrentMonth - currentDay + appointmentDay;
  } else if (appointmentMonth === currentMonth) {
    // If appointment is in the same month
    if (appointmentDay > currentDay) {
      daysLeft = appointmentDay - currentDay;
    } else {
      daysLeft = 0; // Appointment is today or has passed
    }
  } else {
    // If appointment is in a future month in the next year
    const daysInCurrentMonth = new Date(
      currentYear,
      currentMonth + 1,
      0
    ).getDate();
    const daysInNextMonth = new Date(
      currentYear,
      appointmentMonth + 1,
      0
    ).getDate();
    daysLeft = daysInCurrentMonth - currentDay + appointmentDay;
  }

  let appointmentDate = appointment.toLocaleDateString().split(",")[0];

  if (type !== undefined) {
    return daysLeft;
  }

  switch (daysLeft) {
    case 0:
      return `hoy ${appointmentDate}`;
    case 1:
      return `mañana ${appointmentDate}`;
    default:
      return `${daysLeft} días hasta ${appointmentDate}`;
  }
}

export { calculateAge, getDaysTillAppointment };
