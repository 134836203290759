import React, { useEffect, useState } from "react";
import Modal from "../../../../UI/Modal";
import DeleteModal from "../../../../UI/DeleteModal";
import { useSelector } from "react-redux";
import EditAndDelete from "../../../../UI/EditAndDelete";
import { getRecordsByUserId } from "../../../../../firebase/firebase.patientHistory";
import Spinner from "../../../../UI/Spinner";

const History = ({ data, rawHistory, patientId, praxisName }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [history, setHistory] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [historyToUpdate, setHistoryToUpdate] = useState(null);
  const [historyToDelete, setHistoryToDelete] = useState(null);
  const user = useSelector((state) => state.user?.userDetails);

  useEffect(() => {
    const getAllRecords = async () => {
      try {
        setIsLoading(true);
        const records = await getRecordsByUserId(patientId);
        setHistory(records);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };
    getAllRecords();
  }, [patientId]);

  const updateHistory = (selectedHistory) => {
    setOpenModal(true);
    setModalTitle("Editar");
    setHistoryToUpdate({
      userId: data.id,
      selectedHistory: selectedHistory,
      historyStateSetter: setHistory,
      practorId: user.id,
      practorName: `${user.name} ${user.lastname}`,
      praxisId: user.praxisId,
      praxisName: praxisName,
      allRecords: history,
    });
  };

  const addNewHistory = () => {
    setOpenModal(true);
    setModalTitle("Agregar");
    setHistoryToUpdate({
      userId: data.id,
      selectedHistory: 0,
      historyStateSetter: setHistory,
      modalState: setOpenModal,
      practorId: user.id,
      practorName: `${user.name} ${user.lastname}`,
      praxisId: user.praxisId,
      praxisName: praxisName,
    });
  };

  const deleteHistory = (selectedHistory) => {
    setOpenDeleteModal(true);
    setHistoryToDelete({
      userId: data.id,
      selectedHistory: selectedHistory,
      historyStateSetter: setHistory,
    });
  };

  return (
    <div
      className="flex flex-wrap mx-3 pb-5"
      style={{ justifyContent: "center" }}
    >
      <div className="w-full max-w-full px-3">
        <div className="px-4 pb-0 mb-0 border-b-0 rounded-t-2xl flex align-between justify-between items-center">
          <h6 className="mb-0 text-black">Historial de Paciente</h6>
          <button
            type="button"
            className="text-white bg-gray-600 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center mr-2"
            onClick={addNewHistory}
          >
            <svg
              className="w-4 h-4"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                classnamstrokelinecape="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 7h12m-6-6v12"
              />
            </svg>
            <span className="sr-only">Agregar Historial</span>
          </button>
        </div>
        <div className="flex-auto p-4 pt-6">
          <ul className="flex flex-col pl-0 mb-0 rounded-lg h-[50vh] overflow-scroll">
            {history !== null ? (
              history && history.length > 0 ? (
                history.map((e) => {
                  return (
                    <li
                      className="relative flex justify-between p-6 mb-2 border-0 rounded-t-inherit rounded-xl bg-gray-100 bg-slate-850"
                      key={e?.id}
                    >
                      <div className="flex flex-col w-full">
                        <div className="flex flex-col">
                          <div>
                            <div className="flex justify-end">
                              <EditAndDelete
                                deleteHandler={() => deleteHistory(e)}
                                editHandler={() => updateHistory(e)}
                              />
                            </div>
                            <span className="mb-1 text-xs flex items-center justify-between leading-tight text-black/80">
                              <div className="m-1">
                                <span className="font-light text-xs bg-slate-700 text-white p-1 rounded-full">
                                  {e?.title}
                                </span>
                              </div>
                              <div className="flex flex-col-reverse">
                                <span className="font-semibold text-slate-400 mx-2">
                                  {typeof e?.date == "string"
                                    ? e?.date
                                    : new Date(e?.date?.seconds * 1000)
                                        .toLocaleDateString()
                                        .replaceAll("/", "-")}
                                </span>
                              </div>
                            </span>
                            <span className="text-xs flex flex-col sm:flex md:flex lg:flex leading-tight text-black/80 ml-2">
                              <div className="flex">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="w-3 h-4"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                                  />
                                </svg>

                                <div className="text-xs ml-1">
                                  {`${e.practorName} - ${e.praxisName}`}
                                </div>
                              </div>
                            </span>
                          </div>
                        </div>

                        <div className="mt-2 text-xs leading-tight text-black/80 w-full">
                          <div className="font-semibold whitespace-pre-wrap text-slate-700 text-black sm:ml-2 flex flex-wrap text-clip overflow-scroll">
                            {e?.subtitle}
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })
              ) : (
                <div className="flex justify-center w-full">
                  <div className="w-3/4 text-center bg-white border border-gray-200 rounded-lg shadow p-3">
                    <div>Agrega un historial del Paciente aquí</div>
                  </div>
                </div>
              )
            ) : (
              <div
                className="flex items-center justify-center"
                style={{ height: "70vh", width: "80vw" }}
              >
                <Spinner />
              </div>
            )}
            {openModal && (
              <Modal
                allRecords={history}
                modalState={openModal}
                modalSetter={setOpenModal}
                historyToUpdate={historyToUpdate}
                modalTitle={modalTitle}
                modalTitleSetter={setModalTitle}
              />
            )}
            {openDeleteModal && (
              <DeleteModal
                allRecords={history}
                modalState={openDeleteModal}
                modalSetter={setOpenDeleteModal}
                historyToDelete={historyToDelete}
              />
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default History;
