import React, { useEffect, useState } from "react";
import Spinner from "../UI/Spinner";
import Usercard from "./UserCard";
import ErrorCard from "./errors/ErrorCard";

function List({ userData, type }) {
  const [data, setData] = useState();

  useEffect(() => {
    if (data !== userData) {
      // Sort errors by date. From newer to older.
      type === "error" &&
        userData
          .sort((a, b) => a.timestamp.toDate() - b.timestamp.toDate())
          .reverse();

      setData(userData);
    }
  }, [userData]);

  return (
    <>
      <div
        className={`
        ${!data && "flex justify-center"}
        ${
          data && data.length > 3
            ? "lg:justify-center md:justify-center"
            : "flex justify-left"
        }`}
      >
        <div className="flex flex-wrap">
          {data ? (
            data.map((item, i) => (
              <div
                className={`p-3 h-1/12 ${
                  data && data.length > 3
                    ? "md:p-1 sm:h-1/12 lg:w-1/3 md:w-1/3 w-full"
                    : ""
                }`}
                key={item.id}
              >
                {type === "admin" && (
                  <Usercard
                    name={item.name}
                    tel={item.phone}
                    role={item.role}
                    user={item}
                    id={item.id}
                  />
                )}
                {type === "error" && (
                  <ErrorCard data={item} list={data} listSetter={setData} />
                )}
              </div>
            ))
          ) : (
            <div
              className="flex items-center justify-center"
              style={{ height: "50vh" }}
            >
              <Spinner />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default List;
