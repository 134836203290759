const generateUuid = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

let currentRawDate = new Date();

// Dangerous - Create users in firebase based on an array

// const dangerDoNotUser = async (userId, praxis) => {
//   // const data
//   try {
//     data.map(async (patient) => {
//       // await setDoc(doc(db, 'patient', id), data);
//       console.log(patient);
//       // debugger;
//       patient.praxisId = "c15ab6df-96f7-4ac4-9428-6b4eaca290bd";
//       await setDoc(doc(db, "patients", patient.id), patient);
//     });
//   } catch (error) {
//     console.log(error);
//   }
// };

export { generateUuid, currentRawDate };
